import FooterComponent from './footer';
import SidebarComponent from './sidebar';
import { propsMainLayoutType } from 'utils/proptypes';
import Header from 'components/header';
import SelectOrganization from 'components/selectOrg';


const MainLayout = (props: propsMainLayoutType) => {
  const { children, type, permissions, isDashboard } = props;

  return (
    <>
      <div className='flex flex-col h-screen'>
        <Header type={type} />
        <div className="w-full flex flex-row grow">
          <SidebarComponent type={type} permissions={permissions} isDashboard={isDashboard} />
          <div className="mt-content-mb flex flex-col overflow-y w-full bg-white main-layout-content">
            <div className="selector-organization-mobile" >
              <SelectOrganization type={type} />
            </div>
            <div className='grow'>
              {children}
            </div>
            {/* <div className='footer'>
              <FooterComponent />
            </div> */}
          </div>
        </div>
      </div></>
  );
};
export default MainLayout;
