import { HiOutlineEye } from 'react-icons/hi';
import PaginateTable from 'components/table/paginate';
import { assignmentStatus, pageCount, sortByTypeType } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import ActionTable from 'components/table/actionTable';
import Actions from 'components/actions';
import moment from 'moment';
import SpinnerComponent from 'components/spinner';
import Table from 'components/table/table';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import PlayButton from 'components/button/playButton';
import ReviewModal from 'components/modal/reviewModal';
import { deleteAssignment, getAssigments } from 'api/assignmentApi';
import LabelUser from 'components/labelUser';
import { isEmpty, size } from 'lodash';
import { toast } from 'react-toastify';
import ReviewsOfAssignmentModal from 'components/modal/reviewsOfAssignmentModal';
import AssignmentsEmpty from 'components/empty/assignmentsEmpty';
import { Spinner } from 'flowbite-react';
const keywordInit: any = null;
const orderInit: any = null;

const AssignmentListPage = (props: any) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openListReviewModal, setOpenListReviewModal] = useState(false);
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [targetAssignment, setTargetAssignment] = useState();
  const [userReviewId, setUserReviewId] = useState();
  const [keyword, setKeyword] = useState(keywordInit);
  const [order, setOrder] = useState(orderInit);
  const { organizationId } = useSelector(orgSelector);
  const [totalAssignments, setTotalAssignments] = useState(0)

  const { isLoading, data, refetch } = useQuery(
    ['getAssigments', currentPage, searchValue, organizationId],
    () => getAssigments({ page: currentPage, limit: pageCount, searchQuery: searchValue, slpUserId: userInfo.id }),
    { staleTime: Infinity },
  );
  const mutDelAssignment = useMutation('delAssignment', {
    mutationFn: deleteAssignment,
    onSuccess: () => {
      const message = t('assignment.deletedAssignmentSuccess');
      toast.success(message);
      refetch();
      queryClient.invalidateQueries('getAssigments');
      queryClient.invalidateQueries('getPatients');
    },
  });

  const getDataAssignmets: any = data?.data?.entities || [];


  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const colorStatus = (status: any) => {
    const { COMPLETED, READY_FOR_REVIEW } = assignmentStatus;
    return status === COMPLETED ? 'green-500' : status === READY_FOR_REVIEW ? 'primary' : 'outline';
  };

  const clickOpenReview = (item: any, userReviewId: any) => {
    setUserReviewId(userReviewId);
    setTargetAssignment(item);
    setOpenReviewModal(true);
  };

  const clickOpenReviewListModal = (item: any) => {
    setTargetAssignment(item);
    setOpenListReviewModal(true);
  };

  const clickEditAssignment = (item: any) => {
    setTargetAssignment(item);
    setOpenAssignmentModal(true);
  };

  const handelSort = (newKeyword: any) => {
    const { ASC, DESC } = sortByTypeType;
    setOrder(!order || keyword !== newKeyword ? ASC : order === ASC ? DESC : null);
    setKeyword(newKeyword);
  };

  useEffect(() => {
    if (data !== undefined) {
      const totalEntities = data.data.totalEntities
      setTotalAssignments(totalEntities)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!isLoading && isEmpty(getDataAssignmets) && totalAssignments === 0) {
    return (
      <div className="w-full slp-patient sm:p-8 p-4">
        <AssignmentsEmpty title={t('titleSlpAssignmentEmpty')} note={t('noteSlpAssignmentEmpty')} />
      </div>
    );
  }

  return (
    <>
      <div className="w-full slp-patient sm:px-8 px-4">
        <div>
          <ActionTable placeholderSearch={t('search')} buttonName={t('prompt.addPrompt')} handleSearch={handleSearch} />
        </div>
        {isLoading && totalAssignments === 0 && <SpinnerComponent />}
        {!isLoading && isEmpty(getDataAssignmets) && totalAssignments > 0 && <NoResult />}
        {totalAssignments > 0 && (
          <>
            <div className="overflow-x-auto mb-2 list-table">
              <Table>
                <Table.Head>
                  <Table.HeadCell className="" action={() => handelSort('prompt')} order={keyword === 'prompt' && order}>
                    {t('assignment.prompt')}
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile" action={() => handelSort('skill')} order={keyword === 'skill' && order}>
                    {t('assignment.associatedSkills')}
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile-tablet hidden-column" action={() => handelSort('dueDate')} order={keyword === 'dueDate' && order}>
                    {t('assignment.dueDate')}
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile-tablet" action={() => handelSort('status')} order={keyword === 'status' && order}>
                    {t('assignment.status')}
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile-tablet hidden-column" action={() => handelSort('patient')} order={keyword === 'patient' && order}>
                    {t('assignment.patient')}
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile" action={() => handelSort('reviews')} order={keyword === 'reviews' && order}>
                    {t('assignment.reviews')}
                  </Table.HeadCell>
                  <Table.HeadCell className="w-6">{''}</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {getDataAssignmets.map((item: any) => {
                    return (
                      <Table.Row key={`row-${item.id}`} className="bg-white text-base hover:border-b text-on-primary-container">
                        <Table.Cell>
                          <div className="flex items-center">
                            <PlayButton className="p-0.5" isPlay={item.recording} action={() => clickOpenReview(item, userInfo.id)} />
                            <div className="ml-2 font-semibold">{item.prompt.text}</div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="hidden-mobile text-gray-900">
                          <div className="flex flex-wrap gap-y-1">
                            {item.prompt.skills.map((skill: any) => (
                              <p key={`skill-${skill.id}`} className="bg-hover-20 py-1.5 px-3 text-on-primary-container mr-3 rounded text-xs">
                                {skill.name}
                              </p>
                            ))}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="hidden-mobile-tablet w-32 hidden-column">{item.date ? moment(item.date).format('MMM DD, YYYY') : ''}</Table.Cell>
                        <Table.Cell className="hidden-mobile-tablet">
                          <div className="flex items-center">
                            <span className={`w-3 min-w-3 h-3 bg-${colorStatus(item.status)} rounded-lg mr-2 `} />
                            <div className={`text-${colorStatus(item.status)}`}>{item.status}</div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="hidden-mobile-tablet hidden-column">
                          <LabelUser item={item.user} />
                        </Table.Cell>
                        <Table.Cell className="hidden-mobile">
                          <div className="flex items-center">
                            <button onClick={() => clickOpenReviewListModal(item)} className="mr-1.5">
                              <HiOutlineEye className="w-5 h-5" />
                            </button>
                            <span>{size(item?.reviewers)}</span>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <Actions>
                            {item.recording && <Actions.Item action={() => clickOpenReview(item, userInfo.id)} label="View Assignment" />}
                            {!item.recording && <Actions.Item action={() => clickEditAssignment(item)} label={t('edit')} />}
                            {!item.recording && <Actions.Item action={() => mutDelAssignment.mutate(item.id)} label={t('delete')} />}
                          </Actions>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {isLoading && <SpinnerComponent />}
            </div>
            <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalAssignments} />
          </>
        )}
      </div>
      <ReviewsOfAssignmentModal openModal={openListReviewModal} setOpenModal={setOpenListReviewModal} targetData={targetAssignment} />
      <ReviewModal userReviewId={userReviewId} openModal={openReviewModal} setOpenModal={setOpenReviewModal} targetData={targetAssignment} />
    </>
  );
};
export default AssignmentListPage;
