import { searchMaxLength } from 'utils/constants';
import { HiOutlineSearch } from 'react-icons/hi';
import './actionTable.scss';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
const ActionTable = (props: any) => {
  const { placeholderSearch, buttonName, handleAddClick, exportHandler, handleSearch } = props;
  const [t] = useTranslation();

  const handleoOnKeyUp = (e: any) => {
    const { key, target } = e;
    if (key === 'Enter') handleSearch(target.value);
  };

  const handleoOnChange = (e: any) => {
    if (isEmpty(e.target.value)) handleSearch(e.target.value);
  };

  return (
    <>
      <div className="flex py-5 justify-between action-table">
        <div className="relative text-search">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2 h-34">
            <HiOutlineSearch strokeWidth={3} className="w-4 h-4" />
          </span>
          <input
            className="w-72 pl-8 text-sm text-search border-1 border-gray-300 bg-white h-34 rounded-lg focus:outline-none mr-5 action-search rounded-sm"
            type="search"
            name="search"
            maxLength={searchMaxLength}
            placeholder={placeholderSearch}
            onChange={e => handleoOnChange(e)}
            onKeyUp={e => handleoOnKeyUp(e)}
          />
        </div>
        <div className="flex justify-end action-button">
          {handleAddClick && (
            <div className="w-full h-full w-auto flex-row gap-2 action-add">
              <button
                onClick={() => handleAddClick()}
                className="w-auto px-4 h-34 text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-sm"
              >
                {buttonName}
              </button>
            </div>
          )}
          {exportHandler && (
            <div className="w-auto flex-row gap-2 ml-3 bg-gray-400 flex items-center action-export rounded-sm">
              <button
                onClick={() => exportHandler()}
                className="w-auto px-4 h-34 text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10"
              >
                {t('export')}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ActionTable;
