import { deleteRole, getPermissions, getRoles } from 'api/rolesApi';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { exportFileName, pageCount, permissionKeys } from 'utils/constants';
import { roleType } from 'utils/proptypes';
import { checkPermission, exportToFile, handleHeaderSort } from 'utils/utils';
import RoleModal from 'components/modal/roleModal/roleModal';
import PaginateTable from 'components/table/paginate';
import { Badge } from 'flowbite-react';
import './roleManagement.scss';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import Table from 'components/table/table';
import Actions from 'components/actions';
import { toast } from 'react-toastify';
import { size } from 'lodash';
import Sort from 'components/commonComponent/sort';

const initRole = {
  id: '',
  displayName: '',
  type: '',
  permissions: [],
};

const RoleManagementPage = (props: any) => {
  const { WRITE_ROLE } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [roles, setRoles] = useState([]);
  const [targetRole, setTargetRole] = useState(initRole);
  const [openModal, setOpenModal] = useState(false);
  const [permissions, setPermission] = useState([]);
  const queryClient = useQueryClient();
  const isWrite = checkPermission(userInfo, props.type, [WRITE_ROLE], organizationId);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const getAllPermission = useMutation('getAllPermission', {
    mutationFn: getPermissions,
    onSuccess: ({ data }) => setPermission(data),
    onError: () => setPermission([]),
  });

  const { data, isLoading } = useQuery(
    ['getRoles', currentPage, searchValue, sortByType, sortBy],
    () => getRoles({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortByType, sortBy, }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data?.data.totalEntities);
      },
      onError: () => setRoles([]),
      staleTime: Infinity,
    },
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getAllPermission.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      setRoles(data?.data?.entities);
      setTotalEntities(data?.data.totalEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleClickEdit = (item: any) => {
    setTargetRole(item);
    setOpenModal(true);
  };

  const handleClickAddRole = () => {
    setTargetRole(initRole);
    setOpenModal(true);
  };

  const handleCloseModal = (isCloseModal: any) => {
    setOpenModal(isCloseModal);
    if (!isCloseModal) {
      setTargetRole(initRole);
    }
  };

  const renderPermissions = (item: any) => {
    const { permissions } = item;
    return permissions.map((itemPermission: string) => {
      return (
        <Badge color="info" key={`${itemPermission}-permission-item`} className="inline-flex permission-text">
          {itemPermission}
        </Badge>
      );
    });
  };
  const handleOnClickExport = () => {
    const exportedData = roles.map((item: any) => ({
      Name: item.displayName,
      Type: item.type,
      Permissions: item?.permissions
        ?.map((itemPremission: any) => {
          return itemPremission;
        })
        .join(', '),
    }));
    exportToFile(exportedData, exportFileName.ROLE);
  };

  const handleClickDelete = async (id: string) => {
    try {
      const response = await deleteRole(id);
      if (response.status === 200) {
        queryClient.invalidateQueries('getRoles');
        const message: string = t('roleManagementPage.deleteSuccessfully')
        toast.success(message)
      }
    } catch (error: any) {
      const message: string = `${error.response.data.errors?.[0].detail}`;
      toast.error(message);
    }
  };

  return (
    <div className="w-full role-management sm:px-8 px-4">
      <div>
        <ActionTable
          placeholderSearch={t('roleManagementPage.placeholderSearch')}
          buttonName={t('roleManagementPage.buttonAddRoleLabel')}
          handleAddClick={checkPermission(userInfo, props.type, [WRITE_ROLE], organizationId) && handleClickAddRole}
          handleSearch={handleSearch}
        />
      </div>
      {isLoading && <SpinnerComponent />}
      {!isLoading && totalEntities === 0 && <NoResult />}
      {totalEntities > 0 && roles.length > 0 && (
        <>
          <div className="overflow-x-auto mb-2 list-table">
            <Table>
              <Table.Head>
                <Table.HeadCell>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      handleHeaderSort('displayName', sortByType, sortBy, setSortBy, setSortByType);
                    }}
                  >
                    <div className="font-medium items-center mr-1 ">
                      {t('roleManagementPage.Table.ColumnColName')}
                    </div>
                    <Sort check={sortBy === 'displayName'} sortByType={sortByType} />
                  </div>
                </Table.HeadCell>
                <Table.HeadCell className="min-w-20 hidden-mobile">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      handleHeaderSort('type', sortByType, sortBy, setSortBy, setSortByType);
                    }}
                  >
                    <div className="font-medium items-center mr-1 ">
                      {t('roleManagementPage.Table.ColumnColType')}
                    </div>
                    <Sort check={sortBy === 'type'} sortByType={sortByType} />
                  </div>
                </Table.HeadCell>
                <Table.HeadCell className="hidden-mobile">{t('roleManagementPage.Table.countPermission')}</Table.HeadCell>
                <Table.HeadCell className="w-6">{''}</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {roles?.map((item: roleType, index) => {
                  return (
                    <Table.Row className="cursor-pointer" key={`${item.id}`} onClick={() => handleClickEdit(item)}>
                      <Table.Cell className="font-semibold">{`${item.displayName || ''}`}</Table.Cell>
                      <Table.Cell className="hidden-mobile">{item.type}</Table.Cell>
                      <Table.Cell className="py-2.5 hidden-mobile">{size(item.permissions)}</Table.Cell>
                      <Table.Cell>
                        {isWrite && (
                          <Actions>
                            <Actions.Item action={() => handleClickEdit(item)} label={t('edit')} />
                            <Actions.Item action={() => handleClickDelete(item.id)} label={t('roleManagementPage.buttonDeleteRoleLabel')} />
                          </Actions>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              {isLoading && <SpinnerComponent />}
            </Table>
          </div>
          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isLoadingTable={isLoading}
            exportHandler={handleOnClickExport}
          />
        </>
      )}
      <RoleModal
        headerTitle={targetRole.id.length > 0 ? t('roleManagementPage.RoleModal.ModalEditTitle') : t('roleManagementPage.RoleModal.ModalAddTitle')}
        openModal={openModal}
        targetData={targetRole}
        isEdit={targetRole.id.length > 0}
        allAvailablePermission={permissions || []}
        handleCloseModal={(isClose: any) => handleCloseModal(isClose)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        permissions={permissions}
      />
    </div>
  );
};

export default RoleManagementPage;
