import { Dropdown } from 'flowbite-react';
import './style.scss';
import { HiDotsVertical } from 'react-icons/hi';
import { filter, flatten, isEmpty } from 'lodash';
import { themDropdownItem } from 'utils/theme';

const Actions = ({ children }: any) => {
  const showChildren = filter(flatten([children]), (c: any) => c?.props?.show);
  const hileChildren = filter(flatten([children]), (c: any) => !c?.props?.show);
  return (
    <div className="more-action flex" onClick={e => e.stopPropagation()}>
      {showChildren}
      {!isEmpty(hileChildren) && (
        <Dropdown
          renderTrigger={() => (
            <button className="p-1.5 rounded mr-1.5 icon">
              <HiDotsVertical className="w-5 h-5" />
            </button>
          )}
          label={undefined}
        >
          {hileChildren}
        </Dropdown>
      )}
    </div>
  );
};

Actions.Item = ({ action, custom, label, show, icon, className = '', hidden = false }: any) => {
  if (hidden) return null;
  if (show) {
    return (
      <button className={`p-1.5 rounded mr-1.5 icon ${className}`} onClick={action}>
        {icon}
      </button>

    );
  } else {
    return (
      <Dropdown.Item onClick={action} theme={themDropdownItem}>
        {custom || label}
      </Dropdown.Item>
    );
  }
};

export default Actions;
