/* eslint-disable react/jsx-props-no-spreading */
import { Tabs } from 'flowbite-react';
import './userDetail.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { checkPermission, invalidateQueriesUser } from 'utils/utils';
import { updateUserImage, getUserById } from 'api/userApi';
import { find, flattenDeep, map, uniq } from 'lodash';
import { toast } from 'react-toastify';
import { permissionKeys } from 'utils/constants';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import { themeTabs } from 'utils/theme';
import Banner from 'components/banner/banner';
import ButtonBack from 'components/button/buttonBack';
import DetailTab from 'components/userInfo';
import OrganizationsTab from 'components/organizations';
import { getAdminRoles } from 'api/rolesApi';

const UserDetail = (props: any) => {
  const { type } = props;
  const { WRITE_USER, PLATFORM_ADMIN } = permissionKeys;
  const location = useLocation();
  const queryClient = useQueryClient();
  const params: any = useParams();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const { fetchCurrentUser } = useUser();
  const [user, setUser] = useState<any>(undefined);
  const [t] = useTranslation();
  const { data, refetch } = useQuery(['getUserDetail', params?.id], () => getUserById(params.id), { staleTime: Infinity });
  const [tab, setTab] = useState(location?.state?.tab || 'detail');

  const isWriteUser = () => {
    const platformAdmin = find(uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
    return checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin)
  }
  const { data: roles } = useQuery(['getAdminRoles'], () => getAdminRoles(), { staleTime: Infinity });

  const handleUpdateAvatar = async (formData: any) => {
    const { data } = await updateUserImage({ id: params?.id, file: formData });
    if (!data) return;
    const message = t('userManagementPage.editImageSuccessMessage');
    toast.success(message);
    refetch();
    invalidateQueriesUser(queryClient);
    if (params?.id === userInfo.id) fetchCurrentUser();
  };

  useEffect(() => {
    if (data !== undefined) setUser(data?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (location.state) {
      setTab(location.state?.tab);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [location.key]);

  return (
    <div className="flex flex-col user-detail-container w-full">
      {user && (
        <>
          <div className='flex items-center p-4 bg-gray-50'>
            <ButtonBack buttonName={'Users'} />
          </div>
          <Banner targetData={user} isWrite={isWriteUser()} handleUpdate={handleUpdateAvatar} labelId="User ID" />
          <div className="user-infor h-full">
            {/* eslint-disable-next-line  */}
            <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-button" theme={themeTabs}>
              <Tabs.Item active={tab === 'detail'} title="DETAILS">
                <DetailTab type={type} targetData={user} isWrite={isWriteUser()} roleData={roles?.data} />
              </Tabs.Item>
              <Tabs.Item active={tab === 'organizations'} title="ORGANIZATIONS">
                <OrganizationsTab organizations={user?.organizations} />
              </Tabs.Item>
            </Tabs.Group>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetail;
