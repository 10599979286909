import SkillItem from "components/commonComponent/skillItem";
import ReviewsOfAssignmentModal from "components/modal/reviewsOfAssignmentModal";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineClock } from 'react-icons/hi'
import { assignmentStatus } from "utils/constants";
import './pastAssignmentItem.scss'

const PlayIcon = require('../../../assets/image/svg/play-icon.svg').default
const StarIcon = require('../../../assets/image/svg/star-icon.svg').default
const PauseIcon = require('../../../assets/image/svg/pause-icon.svg').default

const PastAssignmentItem = (props: any) => {
    const { assignment, setAssignmentPlaying, assignmentPlaying, audio, setAudio } = props
    const [t] = useTranslation();
    const [openListReviewModal, setOpenListReviewModal] = useState(false);
    const [targetAssignment, setTargetAssignment] = useState();

    const clickOpenReviewListModal = (item: any) => {
        setTargetAssignment(item);
        setOpenListReviewModal(true);
    };

    const handlePlay = async (e: any, assignment: any) => {
        e.stopPropagation();
        setAssignmentPlaying(assignment?.id)
        if (audio) {
            audio.pause()
            setAudio(null)
        }
    }

    const handlePause = (e: any) => {
        e.stopPropagation();
        if (audio) {
            audio.pause()
            setAssignmentPlaying(null)
        }
    }

    useEffect(() => {
        audio?.addEventListener('ended', () => setAssignmentPlaying(null));
        return () => {
            audio?.removeEventListener('ended', () => setAssignmentPlaying(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio, assignmentPlaying]);

    useEffect(() => {
        if (assignment?.id === assignmentPlaying) {
            if (assignment?.recording?.file?.url) {
                const audio = new Audio(assignment?.recording?.file?.url)
                setAudio(audio)
                audio.play()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentPlaying])

    return (
        <>
            <div className="border my-3 p-3 flex justify-between bg-white past-assignment-item" onClick={() => clickOpenReviewListModal(assignment)}>
                <div className="flex items-center">
                    {assignmentPlaying !== assignment.id ? (
                        <div onClick={(e) => handlePlay(e, assignment)} className="bg-text-id rounded-full h-15 w-15 min-w-15 flex justify-center items-center">
                            <div className="border border-2 border-circle-primary bg-white rounded-full h-30 w-30 max-w-30 flex items-center justify-center">
                                <div style={{ paddingLeft: '2px', paddingBottom: '2px' }}>
                                    <img src={PlayIcon} alt="" />
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div onClick={(e) => handlePause(e)} className="bg-recording rounded-full h-15 w-15 min-w-15   flex justify-center items-center" >
                            <div className="border border-2 bg-white border-circle-primary rounded-full h-30 w-30 max-w-30 flex items-center justify-center">
                                <div style={{ paddingLeft: '1px', paddingBottom: '13px', width: '12px', height: '12px' }}>
                                    <img src={PauseIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="ml-5 flex flex-col justify-between">
                        <div className="font-semibold text-base text-on-primary-container mb-2">
                            {assignment.prompt?.text}
                        </div>
                    </div>
                </div>
                <div className="flex infor">
                    <div className=" flex flex-col items-end justify-center">
                        <div className="flex justify-center items-center mb-1">
                            <HiOutlineClock className="mr-1 text-on-primary-container" />
                            <div className="text-sm flex">
                                <div className="mr-1 text-on-primary-container">
                                    Recorded:
                                </div>
                                <div className="font-medium text-on-primary-container">
                                    {assignment?.recording?.created ? moment(assignment?.recording?.created).format('MMMM D, YYYY') : ''}
                                </div>
                            </div>
                        </div>
                        {assignment.status === assignmentStatus.COMPLETED && (
                            <div className="flex items-center ">
                                <div style={{ paddingLeft: '2px', paddingBottom: '2px', marginRight: '5px' }}>
                                    <img src={StarIcon} alt="" className="w-4 h-4" />
                                </div>
                                <div className="text-outline font-normal text-sm mr-1.5">
                                    Rate "Skill"
                                </div>
                                <div className="px-2 py-0.5 bg-badge-secondary text-sm rounded">
                                    {assignment.rating}
                                </div>
                            </div>
                        )}
                        <div>
                            {assignment.status === assignmentStatus.NOT_STARTED && (
                                <aside className="flex flex-row items-center">
                                    <span className="not-started-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                    <p className='not-started-status-text font-extralight'>
                                        {t('status.notStarted')}
                                    </p>
                                </aside>

                            )}
                            {assignment.status === assignmentStatus.COMPLETED && (
                                <aside className="flex flex-row items-center">
                                    <span className="completed-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                    <p className='completed-status-text font-extralight'>
                                        {t('status.completed')}
                                    </p>
                                </aside>
                            )}
                            {assignment.status === assignmentStatus.READY_FOR_REVIEW && (
                                <aside className="flex flex-row items-center">
                                    <span className="ready-for-review-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                    <p className='ready-for-review-status-text font-extralight'>
                                        {t('status.readyForReview')}
                                    </p>
                                </aside>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <ReviewsOfAssignmentModal openModal={openListReviewModal} setOpenModal={setOpenListReviewModal} targetData={targetAssignment} />
        </>

    )
}

export default PastAssignmentItem