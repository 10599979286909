/* eslint-disable react/jsx-props-no-spreading */

import { useTranslation } from 'react-i18next';
import './updateProfile.scss';
import FormUpdateProfile from 'components/modal/formUpdateProfile';
const logo = require('../assets/image/svg/logo.svg').default;
const unionHorizontal = require('../assets/image/svg/union-horizontal.svg').default;
const unionVertical = require('../assets/image/svg/union-vertical.svg').default;

const UpdateProfile = () => {
  const [t] = useTranslation()
  return (
    <div className='onboarding flex h-screen w-screen items-center justify-center sm:p-12 p-4 bg-gray-100 relative overflow-hidden'>
      <img className="absolute min-h-full lg:right-32 max-w-none" src={unionVertical} alt="unionHorizontal" />
      <div className='body bg-white relative'>
        <div className='banner w-full flex items-center justify-center relative'>
          <img className="absolute min-h-full min-w-full" src={unionHorizontal} alt="unionHorizontal" />
          <div className='body-left'>
            <div className='text-3xl sm:text-4xl font-semibold pb-3.5'>{t('userProfilePage.headerLabel1')}</div>
            <div className='text-lg font-semibold pb-3.5'>{t('userProfilePage.headerLabel2')}</div>
            <div className='text-sm sm:text-lg font-normal'>{t('userProfilePage.headerLabel3')}</div>
          </div>
          <img className="h-20 sm:h-32" src={logo} alt="logo" />
        </div>
        <div className='content'>
          <FormUpdateProfile isOnboading/>
        </div>
      </div>
    </div>
  );
};
export default UpdateProfile;
