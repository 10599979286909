/* eslint-disable react/jsx-props-no-spreading */
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'redux/actions';
import { useTranslation } from 'react-i18next';
import FileUpload from 'components/commonComponent/fileUpload';
import { useEffect, useState } from 'react';
import { updateUserProfile, updateUserImage } from 'api/userApi';
import { useMutation } from 'react-query';
import { omit } from 'lodash';
import useUser from 'hooks/useUser';
import { userSelector } from 'redux/selectors';
import { toast } from 'react-toastify';
import ChangePasswordModal from 'components/modal/ChangePasswordModal';
import 'styles/styles.scss';
import { handleTrimString, messageErrors } from 'utils/utils';
import { IMAGE_TYPE_REGEX } from 'utils/constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './updateProfileModal.scss';
import './formUpdateProfile.scss';
import InputText from 'components/inputText';
import Button from 'components/button/button';

const FormUpdateProfile = (props: any) => {
  const { isOnboading } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const closeModal = () => {
    const isOpenModal: any = false;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };
  const { fetchCurrentUser } = useUser();
  const [file, setFile] = useState<any>();
  const { userInfo } = useSelector(userSelector);
  const [imgUrl, setImgUrl] = useState(userInfo?.image?.url || '');
  const [imgSizeError, setImgSizeError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = hookForm;

  const handleRemoveImage = () => {
    setFile('');
    setImgUrl('');
  };

  const changePasswordHandler = () => setOpenModal(true);

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        setImgSizeError(true);
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          setImgSizeError(false);
          return;
        }
        if (currentFile.size > 8000000) {
          setImgSizeError(true);
          return;
        }
      }
      setImgUrl(URL.createObjectURL(currentFile));
      currentFile.isUploaded = true;
      setFile(currentFile);
      setImgSizeError(false);
    }
  };

  const handleUpdateUser = async (values: any) => {
    await updateUserProfile(omit({ ...values, updatedProfile: true }, ['id', 'organizations', 'roles']));
    if (file?.name) {
      const currentFileName = file.name
        .split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      const { data } = await updateUserImage({ id: userInfo.id, file: formData });
      setImgUrl(data.image.url);
    }
    if (isOnboading) {
      window.location.href = window.location.origin;
    }
  };

  const mutationUpdateUser = useMutation('update-user', {
    mutationFn: handleUpdateUser,
    onSuccess: async () => {
      await fetchCurrentUser();
      closeModal();
      const message: string = t('user.updateSuccess');
      toast.success(message);
    },
    onError: error => {
      toast.error(messageErrors(error, t));
    },
  });

  const onSubmit = (values: any) => {
    handleTrimString(values)
    mutationUpdateUser.mutate({ ...values });
  };

  useEffect(() => {
    const initData = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      emailAddress: userInfo.emailAddress,
    };
    reset(initData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const RenderOnboading = () => {
    return (
      <div className="sm:flex w-full p-0 sm:p-6">
        <div className="sm:h-full flex justify-center p-4 sm:p-6">
          <div>
            <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} rounded size={32} />
            {imgSizeError && <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{t('userProfilePage.imageSizeError')}</div>}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full p-4 sm:p-6">
          <div className="w-ful">
            <div className="mb-6">
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.firstName')} *`} name="firstName" isHolderLabel={true} />
            </div>
            <div className="mb-6">
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.lastName')} *`} name="lastName" isHolderLabel={true} />
            </div>
            <div className="mb-4">
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.email')}`} name="emailAddress" isHolderLabel={true} disabled />
            </div>
            <div className="flex">
              <Button className="mr-4" type="submit" color="primary" disabled={mutationUpdateUser.isLoading}>
                {t('userProfilePage.saveAndContinue')}
              </Button>
              <Button onClick={() => mutationUpdateUser.mutate({})} color="outline" outline>
                {t('userProfilePage.skip')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const RenderUpdateProfile = () => {
    return (
      <div className="w-full p-0">
        <div className="flex justify-center pb-4">
          <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} rounded size={32} />
          {imgSizeError && <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{t('userProfilePage.imageSizeError')}</div>}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full pb-2">
          <div className="w-ful">
            <div className="mb-6">
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.firstName')} *`} name="firstName" isHolderLabel={true} />
            </div>
            <div className="mb-6">
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.lastName')} *`} name="lastName" isHolderLabel={true} />
            </div>
            <div className="mb-4">
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.email')}`} name="emailAddress" isHolderLabel={true} disabled />
            </div>
            <div className="flex justify-center mt-8">
              <Button onClick={changePasswordHandler} color="outline" outline>
                {t('userProfilePage.changePassword')}
              </Button>
              <Button className="ml-4" type="submit" color="primary">
                {t('modal.save')}
              </Button>
            </div>
          </div>
        </form>
        <ChangePasswordModal openModal={openModal} setOpenModal={setOpenModal} closeModal={closeModal} />
      </div>
    );
  };

  return <div className="form-update-profile w-full flex justify-center">{isOnboading ? <RenderOnboading /> : <RenderUpdateProfile />}</div>;
};
export default FormUpdateProfile;
