import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { checkPermission, getNavbarUrl } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { pageType, permissionKeys, permissionsOrg, permissionsSite, routePaths } from 'utils/constants';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import './userProfile.scss';
import { userAction } from '../redux/actions';
import LabelUser from './labelUser';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const ref = useRef<any>();
  const { WRITE_SKILL, WRITE_PROMPT } = permissionKeys;

  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { handleLogout } = useUser();
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const checkOrg = checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId);
  const checkSite = checkPermission(userInfo, pageType.SITE, permissionsSite);
  const isWritePrompt = checkPermission(userInfo, pageType.SITE, [WRITE_PROMPT], organizationId)
  const isWriteSkill = checkPermission(userInfo, pageType.SITE, [WRITE_SKILL], organizationId)
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowDropDown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isShowDropDown]);

  const handleOpenModalProfile = () => {
    const isOpenModal: any = true;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };
  return (
    <div className="user-profile text-on-primary-container text-sm">
      <div className={`relative user-dropdown flex items-center`} ref={ref} onClick={() => setIsShowDropDown(!isShowDropDown)}>
        <div className="flex justify-between items-center  w-full">
          <img className="w-3 h-3 overflow-hidden rounded-full object-cover avatar-size" src={userInfo?.image?.url || user?.picture} alt="avatar" />
        </div>
        <div className={`${!isShowDropDown && 'hidden'} dropdown-item`}>
          <div className="flex p-3.5 items-center">
            <LabelUser item={userInfo} isCoppy={false} />
          </div>
          <Dropdown.Divider />
          <div className="px-4 py-3 hover:bg-gray-100 cursor-pointer hover:bg-gray-100 cursor-pointer" onClick={handleOpenModalProfile}>
            {t('navbar.profile')}
          </div>
          {checkSite && (
            <div
              className="px-4 py-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(getNavbarUrl(userInfo, pageType.SITE, organizationId))}
            >
              {t('navbar.admin')}
            </div>
          )}
          {checkOrg && (
            <div
              className="px-4 py-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(getNavbarUrl(userInfo, pageType.ORGANIZATION, organizationId))}
            >
              {t('navbar.organizationAdmin')}
            </div>
          )}

          {isWritePrompt && (
            <div
              className="px-4 py-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(routePaths.PROMPTS_PAGE)}
            >
              {t('sidebar.prompts')}
            </div>
          )}
          {isWriteSkill && (
            <div
              className="px-4 py-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(routePaths.SKILLS_PAGE)}
            >
              {t('sidebar.skills')}
            </div>
          )}
          <Dropdown.Divider />
          <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleLogout()}>
            <span>{t('logout')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
