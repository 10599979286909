import axios from 'axios';
import { httpMethods } from 'utils/constants';
// eslint-disable-next-line
import { headerType } from 'utils/proptypes';
import urls from './urls';
import { getToken } from 'auth0';

const BaseApi = () => {
  const execute = async (method: string, endpoint: string, headers: headerType = {}, body: object = {}) => {
    const token = await getToken();
    axios.defaults.baseURL = urls.baseUrl;
    const requestConfig = {
      ...(method === httpMethods.GET && { params: body }),
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: generateAuthToken(token),
      },
    };

    const handleError = (error: any) => {
      if (error?.response?.status === 403) {
        window.location.href = '/';
      }
    };

    let callAxios;
    if (method === httpMethods.GET) {
      callAxios = axios.get(endpoint, requestConfig);
    } else if (method === httpMethods.POST) {
      callAxios = axios.post(endpoint, body, requestConfig);
    } else if (method === httpMethods.PUT) {
      callAxios = axios.put(endpoint, body, requestConfig);
    } else if (method === httpMethods.PATCH) {
      callAxios = axios.patch(endpoint, body, requestConfig);
    } else {
      callAxios = axios.delete(endpoint, requestConfig);
    }
    callAxios.catch(handleError);
    return callAxios;
  };

  const generateAuthToken = (token: any) => {
    return `Bearer ${token}`;
  };

  return {
    methods: httpMethods,
    urls,
    execute,
  };
};

export default BaseApi;
