/* eslint-disable react/jsx-props-no-spreading */

import { HiOutlinePencil, HiOutlineEye, HiOutlineTrash } from 'react-icons/hi';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { apiStatus, assignmentStatus, pageType } from 'utils/constants';
import { toast } from 'react-toastify';
import { messageErrors } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector } from 'redux/selectors';
import './patientProfile.scss';
import useUser from 'hooks/useUser';
import { getMyPatientById } from 'api/userApi';
import { useParams } from 'react-router-dom';
import { Tabs, TabsRef } from 'flowbite-react';
import moment from 'moment';
import { groupBy, isEmpty, isEqual, size } from 'lodash';
import ButtonBack from 'components/button/buttonBack';
import '../../../styles/styles.scss';
import ActionTable from 'components/table/actionTable';
import { initAssignment, initOrganization } from 'utils/initData';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import SkillItem from 'components/commonComponent/skillItem';
import { getAssigments, deleteAssignment, reorderAssignment } from 'api/assignmentApi';
import { SortableList } from './SortableList';
import Actions from 'components/actions';
import AddAssignmentModal from './addAssignmentModal';
import ReviewsOfAssignmentModal from 'components/modal/reviewsOfAssignmentModal';
import LearningPlan from './learningPlan';
import AssignmentsEmpty from 'components/empty/assignmentsEmpty';
import Banner from 'components/banner/banner';
import DetailTab from 'components/userInfo';
import { getPrompts } from 'api/promptApi';
import NoLearning from './noLearning';

const PatientProfile = (props: any) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { organizationId } = useSelector(orgSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [patient, setPatient]: any = useState();
  const [assignmentsData, setAssignmentsData]: any = useState([]);
  const params: any = useParams();
  const { fetchCurrentUser } = useUser();
  const [isFetching, setIsFetching] = useState(false);
  const [isShowNoAssignment, setIsShowNoAssignment] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [isEditAssignment, setIsEditAssignment] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [openListReviewModal, setOpenListReviewModal] = useState(false);
  const [targetAssignment, setTargetAssignment] = useState();
  const tabsRef = useRef<TabsRef>(null);

  const { data, isLoading } = useQuery(['getPatientProfile', params?.id], () => getMyPatientById(params?.id), {
    retry: false,
    staleTime: Infinity,
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const { data: assignments } = useQuery(
    ['getPatientAssignments', params?.id, currentPage, searchValue, organizationId],
    () => getAssigments({ page: 0, limit: 0, searchQuery: searchValue, userId: params?.id }),
    {
      retry: false,
      staleTime: Infinity,
      onSuccess: (data: any) => {
        setTotalEntities(data.totalEntities);
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    },
  );
  const mutationReorderAssignment = useMutation('reorder-assignment', { mutationFn: reorderAssignment });

  const { data: prompts } = useQuery(['getPrompts', params?.id], () => getPrompts({ page: 0, limit: 0, userId: params?.id }), {
    retry: false,
    staleTime: Infinity,
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  useEffect(() => {
    if (data?.data) setPatient(data?.data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setIsFetching(true);
    if (assignments !== undefined) {
      const dataGroup = Object.values(groupBy(assignments?.data?.entities, item => moment(item.date).format('MMMM DD, YYYY'))).map(item =>
        item.sort((a, b) => a.order - b.order),
      );
      setAssignmentsData(dataGroup);
      setTotalEntities(assignments?.data.totalEntities);
      if (assignments?.data.entities.length > 0) {
        setIsShowNoAssignment(false);
      } else if (searchValue === '') setIsShowNoAssignment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments]);

  useEffect(() => {
    setTotalEntities(assignments?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments]);

  const handleRemoveAssignment = async (assignmentId: string) => {
    try {
      const data = await deleteAssignment(assignmentId);
      if (data.status === apiStatus.SUCCESS) {
        const message: string = t('assignment.deletedAssignmentSuccess');
        toast.success(message);
        queryClient.invalidateQueries('getPatientProfile', { refetchActive: true }, { cancelRefetch: true });
        queryClient.invalidateQueries('getPatientAssignments', { refetchActive: true }, { cancelRefetch: true });
        queryClient.invalidateQueries('getAssigments');
        queryClient.invalidateQueries('getPatients');
        fetchCurrentUser();
      }
    } catch (e) {
      const message: string = t('generalErrors');
      toast.error(message);
    }
  };

  const handleAddAssignment = () => {
    setOpenModal(true);
  };

  const clickOpenReviewListModal = (item: any) => {
    setTargetAssignment(item);
    setOpenListReviewModal(true);
  };

  const handleEditAssignment = (item: any) => {
    setIsEditAssignment(true);
    setOpenModal(true);
    setSelectedAssignment(item);
  };

  const handleReorder = (group: any, index: number) => {
    const tempAssignments = assignmentsData.map((item: any, key: number) => {
      let newArr;
      if (index === key) {
        newArr = group;
      } else newArr = item;
      return newArr;
    });
    setAssignmentsData(tempAssignments);

    const reorderData = group.map((item: any, index: number) => ({
      id: item.id,
      order: index,
      date: moment(item.date).format('MMMM DD, YYYY'),
    }));
    const payload = { assignments: reorderData };
    mutationReorderAssignment.mutate(
      { ...payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getPatientAssignments', { refetchActive: true }, { cancelRefetch: true });
        },
        onError: async (error: any) => {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);
        },
      },
    );
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  return (
    <div className="flex flex-col patient-profile w-full">
      {patient && (
        <>
          <div className="back-to-patient flex items-center pl-5">
            <ButtonBack buttonName={t('patient.backToPatients')} />
          </div>
          <Banner targetData={patient} isWrite={false} labelId="Patient ID" />
          <div className="patient-infor h-full flex">
            {/* eslint-disable-next-line  */}
            <Tabs.Group ref={tabsRef} aria-label="Tabs with underline" style="underline" className="tab-button w-full">
              <Tabs.Item active title="DETAILS">
                <div className="mb-4">
                  <DetailTab type={pageType.ORGANIZATION} targetData={patient} isWrite={false} />
                </div>
                <LearningPlan patientData={patient} />
              </Tabs.Item>
              <Tabs.Item title="MANAGE ASSIGNMENTS">
                {!isEqual(assignmentsData[0], initAssignment) &&
                  totalEntities === 0 &&
                  assignmentsData?.length === 0 &&
                  isShowNoAssignment &&
                  searchValue === '' &&
                  !isLoading ? (
                  <div className="px-8 pt-4">
                    <AssignmentsEmpty handleAddClick={handleAddAssignment} buttonName="Add Assignment" />
                  </div>
                ) : (
                  <div className="flex flex-col h-full organization-clinicians-container px-5">
                    <div className="w-full organization-clinicians  grow">
                      <div className="search-input">
                        <ActionTable
                          placeholderSearch="Search"
                          buttonName="Add Assignment"
                          handleAddClick={handleAddAssignment}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                          handleSearch={handleSearch}
                          handke
                        />
                      </div>
                      {!isEqual(assignmentsData[0], initAssignment) && assignmentsData.length === 0 && !isLoading && isFetching && <NoResult />}
                      {!isEqual(assignmentsData[0], initAssignment) && assignmentsData.length > 0 && (
                        <div className="relative">
                          {assignmentsData.map((group: any, index: number) => (
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32 assignment-table-style">
                              {index === 0 ? (
                                <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                                  <tr className="w-full">
                                    <th scope="col" className="font-medium py-3 px-4 external-id w-22per">
                                      {t('assignment.prompt')}
                                    </th>
                                    <th scope="col" className="hidden-mobile font-medium py-3 px-4 w-25per">
                                      {t('assignment.associatedSkills')}
                                    </th>
                                    <th scope="col" className="hidden-column-custom font-medium  py-3 px-4 w-16per">
                                      {t('assignment.dueDate')}
                                    </th>
                                    <th scope="col" className="hidden-status hidden-column-custom  font-medium py-3 px-4 w-16per">
                                      {t('assignment.status')}
                                    </th>
                                    <th scope="col" className="hidden-tablet font-medium py-3 px-4 w-16per">
                                      {t('assignment.reviews')}
                                    </th>
                                    <th scope="col" className="font-medium py-3 w-5per" />
                                  </tr>
                                </thead>
                              ) : (
                                <tr className="h-4 bg-text-id-outline border">
                                  <th colSpan={6}>{''}</th>
                                </tr>
                              )}
                              <SortableList
                                items={group}
                                onChange={gr => handleReorder(gr, index)}
                                setIsOrder={setIsOrder}
                                renderItem={(item: any) => (
                                  <SortableList.Item id={item.id}>
                                    <SortableList.DragHandle>
                                      <td className="w-22per py-2 px-4 font-medium text-on-primary-container dark:text-white cursor-pointer external-id">
                                        <div className="flex text-on-primary-container flex-row items-center w-full max-w-full clinician-externalId">
                                          <div>
                                            <p>{item?.prompt?.text}</p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="w-25per hidden-mobile py-2 px-4 itemx-center font-medium text-gray-900 dark:text-white cursor-pointer">
                                        <div className="break-word flex-wrap gap-0.5 dark:text-white font-semibold flex items-center text-on-primary-container">
                                          {item?.prompt?.skills.map((skill: any) => {
                                            return <SkillItem name={skill.name} />;
                                          })}
                                        </div>
                                      </td>
                                      <td className=" w-16per hidden-column-custom font-extralight text-on-primary-container py-2 px-4">
                                        {item?.date ? moment(item?.date).format('MMMM D, YYYY') : ''}
                                      </td>
                                      <td className=" w-16per hidden-status hidden-column-custom font-extralight text-primary py-2 px-4">
                                        {item.status === assignmentStatus.NOT_STARTED && (
                                          <aside className="flex flex-row items-center">
                                            <span className="not-started-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                            <p className="not-started-status-text font-extralight">{t('status.notStarted')}</p>
                                          </aside>
                                        )}
                                        {item.status === assignmentStatus.COMPLETED && (
                                          <aside className="flex flex-row items-center">
                                            <span className="completed-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                            <p className="completed-status-text font-extralight">{t('status.completed')}</p>
                                          </aside>
                                        )}{' '}
                                        {item.status === assignmentStatus.READY_FOR_REVIEW && (
                                          <aside className="flex flex-row items-center">
                                            <span className="ready-for-review-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                            <p className="ready-for-review-status-text font-extralight">{t('status.readyForReview')}</p>
                                          </aside>
                                        )}
                                      </td>
                                      <td className=" w-16per hidden-tablet  text-on-primary-container py-2 px-4">
                                        <div className="flex items-center">
                                          <Actions>
                                            <Actions.Item
                                              show
                                              action={() => clickOpenReviewListModal(item)}
                                              icon={<HiOutlineEye strokeWidth={2.5} className="w-5 h-5" />}
                                            />
                                          </Actions>
                                          <span className='font-extralight'>{size(item?.reviewers)}</span>
                                        </div>
                                      </td>
                                      <td className="py-2 w-5per">
                                        <div className="flex justify-end mr-1">
                                          <Actions>
                                            <Actions.Item
                                              show
                                              action={() => handleEditAssignment(item)}
                                              icon={<HiOutlinePencil className="w-5 text-on-primary-container" strokeWidth={2.5} />}
                                            />
                                            <Actions.Item
                                              show
                                              action={() => handleRemoveAssignment(item?.id)}
                                              icon={<HiOutlineTrash className="w-5 text-on-primary-container" strokeWidth={2.5} />}
                                            />
                                          </Actions>
                                        </div>
                                      </td>
                                    </SortableList.DragHandle>
                                  </SortableList.Item>
                                )}
                              />
                            </table>
                          ))}
                        </div>
                      )}
                      {isLoading && !isEqual(assignmentsData[0], initOrganization) && assignmentsData?.length > 0 && <SpinnerComponent />}
                    </div>
                  </div>
                )}
                {openModal && !isEmpty(prompts?.data?.entities) && (
                  <AddAssignmentModal
                    headerTitle={isEditAssignment ? t('patient.editAssignment') : t('patient.addAssignment')}
                    openModal={openModal}
                    isEdit={isEditAssignment}
                    setIsEdit={setIsEditAssignment}
                    selectedAssignment={selectedAssignment}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    prompts={prompts}
                  />
                )}
                {openModal && isEmpty(prompts?.data?.entities) && (
                  <NoLearning openModal={openModal} setOpenModal={setOpenModal} tabsRef={tabsRef} />
                )}
                <ReviewsOfAssignmentModal openModal={openListReviewModal} setOpenModal={setOpenListReviewModal} targetData={targetAssignment} />
              </Tabs.Item>
            </Tabs.Group>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientProfile;
