import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();
const getSkills = (queryFilter: queryFilter) => {
    const initParams:any = omitBy(queryFilter, v => v === '')

  const params = createSearchParams(initParams);

  const method = methods.GET;

  const url = `${urls.skills.skills}/?${params}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createSkill = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.skills.skills}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateSkill = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.skills.skills}/${payload.id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteSkill = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.skills.skills}/${id}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

export { getSkills, createSkill, updateSkill, deleteSkill };
