import { useState } from 'react';
import './style.scss';
import { isArray, isEmpty, map } from 'lodash';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const Select = (props: any) => {
  const { name, placeholder, errors, isHolderLabel, onChange, options, value, isMulti = false, isCustomOption = false, hookForm, className = '' } = props;
  const getValue = () => value || options?.filter((o: any) => hookForm?.getValues(name)?.includes(o.value));
  const [focus, setFocus] = useState(!isEmpty(getValue()));
  const fnErrors = errors || hookForm?.formState?.errors || {};

  const handleOnChange = (options: any) => {
    if (hookForm) {
      const values: any = isArray(options) ? map(options, 'value') : options.value;
      hookForm.setValue(name, values);
      if (!isEmpty(values) && !isEmpty(hookForm.formState.errors)) hookForm.clearErrors(name);
    }
    if (onChange) onChange(options);
  };

  return (
    <div className={`flex-1 select-custom relative w-full ${isMulti ? 'select-multi' : isCustomOption ? 'select-multi custom-single' : ''} ${className}`}>
      {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
      <ReactSelect
        closeMenuOnSelect={!isMulti}
        components={animatedComponents}
        isMulti={isMulti}
        defaultValue={getValue()}
        options={options}
        onChange={handleOnChange}
        className={`react-select ${fnErrors[name] ? 'error bg-red-50' : ''}`}
        classNamePrefix="react-select"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(!isEmpty(getValue()))}
        placeholder={!isHolderLabel ? placeholder : ''}
      />
      {fnErrors[name] && <div className={`text-red-600 text-xs font-normal mt-2 veri-modal height-16`}>{fnErrors[name]?.message}</div>}
    </div>
  );
};

export default Select;
