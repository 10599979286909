import { HiDotsHorizontal } from 'react-icons/hi';
import { take } from 'lodash';
import { Avatar } from 'flowbite-react';
import AvatarCustom from './Avatar';

const AVATAR_NUMBER_TO_SHOW = 8;

const GroupAvatar = (props: any) => {
    const { items, shows } = props;
    const showItems = take(items, shows || AVATAR_NUMBER_TO_SHOW);
    return (
        <div className="group-avatar flex items-center">
            <Avatar.Group className="-space-x-3">
                {showItems.map((item: any, index) => (
                    <AvatarCustom item={item} key={`${index + 1}-avatar`} />
                ))}
                {items?.length > showItems?.length && (
                    <div className="bg-outline rounded-full w-9 h-9 flex items-center justify-center border">
                        <HiDotsHorizontal className="w-5 h-5 align-middle table-cell text-white" />
                    </div>
                )}
            </Avatar.Group>
        </div>
    );
};

export default GroupAvatar;
