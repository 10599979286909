import { useState } from 'react';
import './scrollbar.scss';

const initAny: any = null;

const Scrollbar = (props: any) => {
  const { children, onScroll, endScroll, className } = props;
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [myTimeout, setMyTimeout] = useState(initAny);
  const [distanceY, setDistanceY] = useState(initAny);
  const [docScrollbar, setDocScrollbar] = useState(initAny);
  const [checkScroll, setCheckScroll] = useState(false);
  let random = (Math.random() + 1).toString(36).substring(7);

  const handerScroll = () => {
    if (myTimeout) clearTimeout(myTimeout);
    setIsScroll(true);
    setMyTimeout(
      setTimeout(() => {
        setIsScroll(false);
      }, 1000),
    );
  };
  const handerClickScroll = (event: any) => {
    const layerY = event.screenY - distanceY;
    const scrollDivContent: any = docScrollbar.getElementsByClassName('scrollbar-content');
    const scrollHeight = scrollDivContent[0].scrollHeight;
    const offsetHeight = scrollDivContent[0].offsetHeight;
    scrollDivContent[0].scrollTop = (layerY / (scrollDivContent[0].offsetHeight - 100)) * (scrollHeight - offsetHeight) - 38;
  };

  const handerMouseUp = () => {
    document.removeEventListener('mousemove', handerClickScroll, true);
  };

  const handerMouseDown = () => {
    document.addEventListener('mousemove', handerClickScroll, true);
    document.addEventListener('mouseup', handerMouseUp, true);
    return () => {
      document.removeEventListener('mouseup', handerMouseUp, true);
    };
  };

  const handleOnScroll = (event: any) => {
    const { scrollHeight, offsetHeight, scrollTop } = event.target;
    setScroll((scrollTop / (scrollHeight - offsetHeight)) * (offsetHeight - 100));
    handerScroll();
    if (onScroll) onScroll({ scrollHeight, offsetHeight, scrollTop });
    if (endScroll && scrollTop === scrollHeight - offsetHeight) endScroll();
  };

  const handerMouseOver = (event: any) => {
    if (distanceY === null) setDistanceY(event.screenY - event.nativeEvent.layerY);
    const documentScrollbar: any = document.getElementById(`scrollbar-${random}`);
    setDocScrollbar(documentScrollbar);
    const scrollDivContent: any = documentScrollbar.getElementsByClassName('scrollbar-content');
    const scrollDivContentScroll: any = documentScrollbar.getElementsByClassName('scrollbar-content-scroll');
    setCheckScroll(scrollDivContentScroll[0].offsetHeight > scrollDivContent[0].offsetHeight);
  };

  return (
    <div id={`scrollbar-${random}`} className={`relative scrollbar-custom ${className || ''}`} onMouseOver={handerMouseOver}>
      <div
        onClick={handerClickScroll}
        className={`transition-opacity absolute w-2 right-0.5 inset-y-0.5 rounded 
          ${checkScroll ? 'z-10 hover:opacity-1' : 'hidden'} ${isScroll ? 'opacity-1' : 'opacity-0'}`}
      >
        <button
          className="relative block w-full cursor-pointer h-[100px] z-10 rounded"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', transform: 'translateY(' + scroll + 'px)' }}
          onMouseDown={handerMouseDown}
        />
      </div>
      <div className="overflow-auto h-full scrollbar-content" onScroll={handleOnScroll}>
        <div className="scrollbar-content-scroll">{children}</div>
      </div>
    </div>
  );
};
export default Scrollbar;
