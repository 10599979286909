import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CustomModalHeader from './customModalHeader';
import { resetPassword } from 'api/userApi';
import { useMutation } from 'react-query';
import { messageErrors } from '../../utils/utils';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import Button from 'components/button/button';

type changePasswordModalTypes = {
  openModal: boolean,
  setOpenModal: Function,
  closeModal?: Function,
};

const ChangePasswordModal = (props: changePasswordModalTypes) => {
  const [t] = useTranslation();
  const { openModal, setOpenModal, closeModal } = props;
  const mutation = useMutation('changePassword', { mutationFn: resetPassword });
  const { handleSubmit } = useForm({});
  const onSubmit = () => {
    mutation.mutate(
      {},
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          if (closeModal) {
            closeModal();
          }
          const message: string = t('changePassword.messageSuccess');
          toast.success(message);
        },
        onError: (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = () => {
    setOpenModal(!openModal);
  };

  return (
    <Modal show={openModal} size="lg" popup={true} className="h-full-important">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModalHeader title={t('changePassword.title')} toggle={cancelHandler} />
        <Modal.Body>
          <div className="flex flex-col px-4 gap-4 justify-center">
            <div className="font-light text-on-primary-container text-base font-semibold text-center">
              {t('changePassword.sureResetPasswordTitle')}
            </div>
            <div className="font-light text-on-primary-container text-center">{t('changePassword.sureResetPasswordContent')}</div>
            <div className="flex justify-center">
              <Button className="mr-4" onClick={() => cancelHandler()} color="outline" outline>
                {t('modal.cancel')}
              </Button>
              <Button type="submit" color="primary" disabled={mutation.isLoading}>
                Yes
              </Button>
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};
export default ChangePasswordModal;
