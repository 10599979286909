/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import CustomModalHeader from './customModalHeader';
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import Button from 'components/button/button';
import Table from 'components/table/table';
import LabelUser from 'components/labelUser';
import { formatAgoDate } from 'utils/utils';
import { isEmpty } from 'lodash';
import ReviewModal from './reviewModal';
import Svg from 'components/svg';

const ReviewsOfAssignmentModal = (props: any) => {
  const { openModal, setOpenModal, targetData } = props;
  const [t] = useTranslation();
  const rootRef = useRef(null);
  const [userReviewId, setUserReviewId] = useState();
  const [targetAssignment, setTargetAssignment] = useState();
  const [openReviewModal, setOpenReviewModal] = useState(false);  

  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  const clickOpenReview = (item: any, userReviewId: any) => {
    setUserReviewId(userReviewId);
    setTargetAssignment(item);
    setOpenReviewModal(true);
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-skill"
        show={openModal}
        root={rootRef.current ?? undefined}
        size="xl"
        onClose={toggleHandler}
        theme={themModal}
        dismissible={true}
      >
        <CustomModalHeader title="Reviews" toggle={toggleHandler} />
        <Modal.Body theme={themModal.body}>
          {isEmpty(targetData?.reviewers) && (
            <div className="border border-hover-20 p-8 mt-2">
              <div className="flex justify-center mb-5">
                <Svg name='eye-icon'/>
              </div>
              <div className="text-center text-xl	font-semibold text-on-primary-container">This Assignment doesn’t have any reviews yet</div>
            </div>
          )}
          {!isEmpty(targetData?.reviewers) && (
            <Table>
              <Table.Head>
                <Table.HeadCell>
                  <div className="flex items-center">Clinician</div>
                </Table.HeadCell>
                <Table.HeadCell>
                  <div className="flex items-center">Date</div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {targetData?.reviewers?.map((reviewer: any) => (
                  <Table.Row key={`reviewer-${reviewer.id}`} className="cursor-pointer" onClick={() => clickOpenReview(targetData, reviewer.id)}>
                    <Table.Cell>
                      <LabelUser item={reviewer} noEmail/>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap">{formatAgoDate(reviewer.reviewDate)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
          <div className="flex justify-center pt-8">
            <Button color="outline" outline onClick={() => setOpenModal(false)}>
              {t('modal.close')}
            </Button>
          </div>
          <ReviewModal userReviewId={userReviewId} openModal={openReviewModal} setOpenModal={setOpenReviewModal} targetData={targetAssignment} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ReviewsOfAssignmentModal;
