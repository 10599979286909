
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import { toast } from 'react-toastify';
import { invalidateQueriesUser, messageErrors } from '../../utils/utils';
import useUser from 'hooks/useUser';
import { TextInput, Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import './organizationModal.scss'
import { assignClinician } from 'api/userApi';
import { getOrgRoles } from 'api/rolesApi';
import './organizationPatientModal.scss'
import './addPatientToClinician.scss'
import InputText from 'components/inputText';

type organizationModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    targetData?: any,
    headerTitle?: string,
    isEdit: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
    slpId?: string
};
const AddPatientToClinician = (props: organizationModalProps) => {
    const { openModal, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage, slpId } = props;
    const { fetchCurrentUser } = useUser();
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);

    const handleAddPatient = async (values: any) => {
        await assignClinician({ ...values });
    };

    const mutation = useMutation('addPatientToClinician', {
        mutationFn: handleAddPatient,
        onSuccess: () => {
            toggleHandler()
            const message: string = t('organizationPatients.assignPatientSuccess');
            toast.success(message);
            invalidateQueriesUser(queryClient);
            fetchCurrentUser();
        },
        onError: (error) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        }
    });

    const ValidateSchema = Yup.object().shape({
        patientEmail: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        formState: { errors },
        handleSubmit,
        reset
    } = hookForm

    const onSubmit = async (values: any) => {
        const payload = {
            patientEmail: values.patientEmail.trim(),
            clinicianId: slpId
        }
        mutation.mutate({
            ...payload
        })
    };

    const toggleHandler = () => {
        setOpenModal(!openModal)
        reset()
    }

    return (
        <div ref={rootRef}>
            <Modal
                className='modal-clinician-patient'
                show={openModal}
                size="md"
                popup={true}
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}>
                <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${errors?.patientEmail && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder="Email *" name="patientEmail" isHolderLabel={true} />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 pb-2"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.save'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                },
                            ]} />
                    </form>
                </Modal.Body>
            </Modal >
        </div>
    );
};
export default AddPatientToClinician;
