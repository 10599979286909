import base from './baseApi';

const { urls, methods, execute } = base();

const getHealth = () => {
  const method = methods.GET;
  const url = urls.health;

  return execute(method, url, { authorization: false });
};

export { getHealth };
