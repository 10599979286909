import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();
const getLearnings = (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  const url = `${urls.learnings.learnings}/?${params.toString()}`;

  const response = execute(method, url, { authorization: true });

  return response;
};

const createLearning = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.learnings.learnings}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateLearning = (payload: any, learningId: string) => {
  const method = methods.PUT;
  const url = `${urls.learnings.learnings}/${learningId}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteLearning = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.learnings.learnings}/${id}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

export { getLearnings, createLearning, updateLearning, deleteLearning };
