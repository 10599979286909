/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { HiChevronUp, HiChevronDown } from 'react-icons/hi';
import CustomModalHeader from './customModalHeader';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { themModal } from 'utils/theme';
import InputTextarea from 'components/textarea';
import Player from 'components/player';
import Progress from 'components/progress';
import Button from 'components/button/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createReview } from 'api/reviewApi';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { getLearnings } from 'api/learningApi';
import { omit } from 'lodash';

const initReviewer: any = {};

const ReviewModal = (props: any) => {
  const { openModal, setOpenModal, targetData, userReviewId } = props;
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const queryClient = useQueryClient();
  const rootRef = useRef(null);
  const [isShowFeedback, setIsShowFeedback] = useState(false);
  const [reviewer, setReviewer] = useState(initReviewer);
  const [skills, setSkills]: any = useState([]);

  const { data: learningPlansData } = useQuery(
    ['getLearningPlans', targetData?.userId],
    () => getLearnings({ page: 0, limit: 0, userId: targetData?.userId }),
    {
      retry: false,
      staleTime: Infinity,
      enabled: targetData?.userId !== undefined,
    },
  );

  const handleAddReviews = async (reviews: any) => {
    await Promise.all(reviews.map((review: any) => createReview(review)));
  };

  const mutAddReview = useMutation('addReviews', {
    mutationFn: handleAddReviews,
    onSuccess: () => {
      const message = t('review.addReviewSuccess');
      toast.success(message);
      queryClient.invalidateQueries('getAssigments');
      queryClient.invalidateQueries('getPatientAssignments');
      queryClient.invalidateQueries('resetGetRecordingsToReview');
      queryClient.invalidateQueries('getLearningPlans');
      setOpenModal(!openModal);
    },
  });

  const ValidateSchema = Yup.object().shape({
    notes: Yup.string().required(t('requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });
  const { handleSubmit, reset, register } = hookForm;

  const onSubmit = (values: any) => {
    mutAddReview.mutate(
      values.reviews.map((review: any) => ({
        notes: values.notes,
        userId: userInfo.id,
        assignmentId: targetData.id,
        rating: review.rating,
        skillId: review.skillId,
      })),
    );
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  const isReview = () => !reviewer && userReviewId === userInfo.id;

  useEffect(() => {
    if (targetData) {
      const learningPlansTemp = learningPlansData?.data?.entities?.map((item: any) => omit(item.skill, ['reviews']));
      const skillList = targetData?.prompt?.skills?.filter((item: any) => learningPlansTemp?.find((lp: any) => lp.id === item.id));
      const myReviewer = targetData?.reviewers?.find((reviewer: any) => reviewer.id === userReviewId);

      reset({
        notes: myReviewer?.notes,
        reviews: skillList?.map((skill: any) => ({
          skillId: skill.id,
          rating: myReviewer?.reviews?.find((review: any) => review.skillId === skill.id)?.rating || 0,
        })),
      });
      setSkills(skillList);
      setReviewer(myReviewer);
      setIsShowFeedback(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData, userReviewId, learningPlansData]);

  useEffect(() => {
    setIsShowFeedback(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-skill"
        show={openModal}
        root={rootRef.current ?? undefined}
        size="3xl"
        onClose={toggleHandler}
        theme={themModal}
        dismissible={true}
      >
        <CustomModalHeader title="Review Assignment" toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-1 px-12 !py-8 overflow-auto' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Player targetData={targetData} />
            {targetData?.specialInstructions && (
          <div className="p-3 border-b border-gray-200">
            <p className="text-center text-sm font-semibold">{t('assignment.specialInstructions')}</p>
            <p className="text-center break-word pre">{targetData?.specialInstructions}</p>
          </div>
          )}
            <div className="mt-4 relative text-sm text-on-primary-container font-semibold pb-4 border-b border-gray-200">Rate “Skill”</div>
            {skills?.map((skill: any, index: any) => (
              <div key={`${skill.id}`} className="mt-4 relative flex items-center text-xs text-on-primary-container pb-4 border-b border-gray-200">
                <div className="w-32 flex">
                  <p className="bg-hover-20 py-1.5 px-3 rounded text-xs">“{skill.name}”</p>
                </div>
                <p className="mx-2">{t('review.notAchieved')}</p>
                <div className="w-full">
                  <Progress name={`reviews[${index}].rating`} min={0} max={100} hookForm={hookForm} disabled={!isReview()} />
                  <input className="hidden" value={skill.id} {...register(`reviews[${index}].skillId`)} />
                </div>
                <p className="ml-2">{t('review.perfect')}</p>
              </div>
            ))}
            {isReview() && (
              <div className="mb-4 mt-4">
                <InputTextarea hookForm={hookForm} placeholder="Notes" name="notes" isHolderLabel={true} />
              </div>
            )}
            {!isReview() && (
              <>
                <div className="mt-4 relative flex items-center justify-between text-xs text-on-primary-container pb-4 border-b border-gray-200">
                  <div className="text-sm font-semibold">Feedback</div>
                  <div className="flex items-center text-tertiary cursor-pointer" onClick={() => setIsShowFeedback(!isShowFeedback)}>
                    View {isShowFeedback ? <HiChevronUp className="w-4 h-4" /> : <HiChevronDown className="w-4 h-4" />}
                  </div>
                </div>
                <div className={`${isShowFeedback ? 'max-h-20' : 'max-h-0'} overflow-hidden transition-all delay-75 ease-out`}>
                  <div className="mt-4 relative flex items-center justify-between text-sm text-on-primary-container pb-4 border-b border-gray-200">
                    {reviewer?.notes}
                  </div>
                </div>
              </>
            )}
            <div className="flex justify-center pt-8">
              <Button color="outline" outline onClick={() => setOpenModal(false)}>
                {isReview() ? t('modal.cancel') : t('modal.close')}
              </Button>
              {isReview() && (
                <Button className="ml-4" type="submit" color="primary">
                  {t('modal.save')}
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ReviewModal;
