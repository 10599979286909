import base from './baseApi';

const { urls, methods, execute } = base();

const resendEmailVerification = () => {
  const method = methods.POST;
  const url = urls.user.resendEmailVerification;

  return execute(method, url, { authorization: true });
};

export { resendEmailVerification };
