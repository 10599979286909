import Svg from 'components/svg';
import { useTranslation } from 'react-i18next';
const NoResult = () => {
  const [t] = useTranslation();
  return (
    <div className="flex flex-col mt-50 items-center">
      <Svg name="search-normal" />
      <div className="text-on-primary-container text-3xl font-semibold mt-6">{t('searchResult.title')}</div>
      <div className="text-outline text-lg font-normal mt-2">{t('searchResult.subTitle')}</div>
    </div>
  );
};
export default NoResult;
