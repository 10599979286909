import { Spinner } from 'flowbite-react';

const SpinnerComponent = () => {
  return (
    <span className="spinner">
      <Spinner aria-label="Center-aligned spinner example" />
    </span>
  );
};
export default SpinnerComponent;
