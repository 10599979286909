import { LogoutPage, DashboardPage, OrganizationPage, UsersPage, VersionPage, ContactSupportPage, LoginPage, RoleManagementPage } from 'pages';
import EmailVerification from 'pages/emailVerification';
import NotFoundPage from 'pages/notFound';
import { routePaths, permissionKeys, pageType } from 'utils/constants';
import { routeType } from 'utils/proptypes';
import Subscription from '../pages/subscription/subscription';
import StripePayment from 'pages/stripePayment/stripePayment';
import OrganizationDetail from 'pages/organization/organizationDetail';
import OrganizationClinician from 'pages/organization/clinician/organizationClinician';
import OrganizationClinicianDetail from 'pages/organization/clinician/organizationClinicianDetail';
import OrganizationPatients from 'pages/organization/organizationPatients';
import PromptManagementPage from 'pages/prompts/promptManagement';
import SkillManagementPage from 'pages/skills/skillManagement';
import OrganizationPatientDetail from 'pages/organization/patient/organizationPatientDetail';
import UserDetail from 'pages/user/userDetail';
import PatientListPage from 'pages/slp/patient/patientList';
import AssignmentListPage from 'pages/slp/assignments/assignmentList';
import PatientProfile from 'pages/slp/patient/patientProfile';
import Assignments from 'pages/dashboard/patient/assignmentsPage';
import LearningPlan from 'pages/slp/patient/learningPlan';
const { READ_USER, WRITE_USER, READ_ORG, WRITE_ORG } = permissionKeys;
const { READ_ROLE, WRITE_ROLE } = permissionKeys;
const isUseStripe = process.env.REACT_APP_IS_USE_STRIPE === 'true';
const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';
const { ORGANIZATION, SITE, CLINICIANS, PATIENTS } = pageType

const routes: routeType[] = [
  { path: routePaths.VERSION_PAGE, component: VersionPage },
  { path: routePaths.CONTACT_SUPPORT, component: ContactSupportPage },
  { path: routePaths.LOGIN_PAGE, component: LoginPage, title: 'Hello Audrey' },
  { path: routePaths.LOGOUT_PAGE, component: LogoutPage },
  { path: routePaths.DASHBOARD_PAGE, component: DashboardPage, layout: 'MainLayout', isDashboard: true },
  { path: routePaths.ORGANIZATION_PAGE, component: OrganizationPage, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: SITE },
  { path: routePaths.NOT_FOUND_PAGE, component: NotFoundPage },
  { path: routePaths.ADMIN_USERS_PAGE, component: UsersPage, layout: 'MainLayout', permissions: [READ_USER, WRITE_USER], type: SITE },
  { path: routePaths.ADMIN_USERS_DETAIL, component: UserDetail, layout: 'MainLayout', permissions: [READ_USER, WRITE_USER], type: SITE },
  { path: routePaths.ROLES_PAGE, component: RoleManagementPage, layout: 'MainLayout', permissions: [READ_ROLE, WRITE_ROLE], type: SITE },
  { path: routePaths.EMAIL_VERIFICATION, component: EmailVerification },
  { path: routePaths.ORG_OVERVIEW_PAGE, component: OrganizationDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_CLINICIANS, component: OrganizationClinician, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_CLINICIANS_DETAIL, component: OrganizationClinicianDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_PATIENTS, component: OrganizationPatients, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_PATIENTS_DETAIL, component: OrganizationPatientDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.PROMPTS_PAGE, component: PromptManagementPage, layout: 'MainLayout', type: SITE },
  { path: routePaths.SKILLS_PAGE, component: SkillManagementPage, layout: 'MainLayout', type: SITE },
  { path: routePaths.SLP_PATIENT_PROFILE, component: PatientProfile, layout: 'MainLayout', type: CLINICIANS },
  { path: routePaths.SLP_PATIENTS, component: PatientListPage, layout: 'MainLayout', type: CLINICIANS },
  { path: routePaths.SLP_ASSIGNMENTS, component: AssignmentListPage, layout: 'MainLayout', type: CLINICIANS },
  { path: routePaths.PATIENT_ASSIGNMENTS, component: Assignments, layout: 'MainLayout', type: PATIENTS },
  { path: routePaths.PATIENT_LEARNING_PLAN, component: LearningPlan, layout: 'MainLayout', type: PATIENTS },
  { path: routePaths.UPDATE_PROFILE },
  { ...(isUseSubscription ? { path: routePaths.SUBSCRIPTION, component: Subscription }: {}) },
  { ...(isUseStripe ? { path: routePaths.STRIPE_PAYMENT, component: StripePayment, layout: 'UserLayout' } : {}) },
];

export default routes;
