import '../../../styles/styles.scss'
import { HiChevronRight } from 'react-icons/hi'
import { useQuery, } from 'react-query';
import { getPatientAssignments } from 'api/assignmentApi';
import PastAssignmentItem from './pastAssignmentsItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { assignmentStatus, routePaths, TypeAssignment } from 'utils/constants';
import { useEffect, useState } from 'react';
import UpcomingAssignmentItem from './upcomingAssignmentItem';
import './patientDashboard.scss'
import AssignmentsEmpty from 'components/empty/assignmentsEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { orgSelector } from 'redux/selectors';

const PatientDashboard = (props: any) => {
    const { userInfo } = props
    const navigate = useNavigate();
    const [upcommingAssignmentData, setUpcommingAssignmentData] = useState([])
    const [pastAssignmentsData, setPastAssignmentsData] = useState<any>([])
    const [open, setOpen] = useState({})
    const [assignmentPlaying, setAssignmentPlaying] = useState(null)
    const [audio, setAudio] = useState<any>(null)

    const endDate = moment().add(365, 'days').endOf('days').format();
    const startDate = moment().subtract(1, 'y').startOf('days').format();
    const { organizationId } = useSelector(orgSelector);

    const { data: upcommingAssignments, isLoading: isLoadingUpcoming } = useQuery(['getUpcomingAssigments', userInfo?.id, organizationId], () => getPatientAssignments(
        { page: 0, limit: 0, userId: userInfo?.id, type: TypeAssignment.UPCOMING, startDate, endDate }
    ),
        {
            retry: false,
            staleTime: Infinity,
            onSuccess: (data: any) => {
            },

        });

    const { data: pastAssignments, isLoading: isLoadingPast } = useQuery(['getPastAssigmentsDashboard', userInfo?.id, organizationId], () => getPatientAssignments(
        { page: 0, limit: 0, userId: userInfo?.id, type: TypeAssignment.PAST }
    ),
        {
            retry: false,
            staleTime: Infinity,
            onSuccess: (data: any) => {
            },

        });

    useEffect(() => {
        if (pastAssignments !== undefined) {
            const completedAssignments = pastAssignments.data?.entities?.filter((item: any) => item.status === assignmentStatus.COMPLETED).slice(0, 5)
            const consReadyForReviewAssignments = pastAssignments.data?.entities?.filter((item: any) => item.status === assignmentStatus.READY_FOR_REVIEW)
            const tempPastAssignment = [...consReadyForReviewAssignments, ...completedAssignments]
            setPastAssignmentsData(tempPastAssignment)
        }

    }, [pastAssignments])

    useEffect(() => {
        if (upcommingAssignments !== undefined) {
            setUpcommingAssignmentData(upcommingAssignments?.data?.entities)
        }
    }, [upcommingAssignments])

    const handleSeeAllAssignment = (tab?: string) => {
        navigate(`${routePaths.PATIENT_ASSIGNMENTS}`, { state: { tab } });
    };
    const [t] = useTranslation()

    return (
        <div className="patient-dashboard flex h-full">
            <div className="w-1/2 p-5 left-side">
                <div className='flex justify-between'>
                    <div className='font-semibold text-xl text-on-primary-container'>{t('patient.currentAssignments')}</div>
                    <button className='flex link-color font-normal' onClick={() => handleSeeAllAssignment('current')}>
                        {t('seeAll')}
                        <HiChevronRight className="w-6 h-6" />
                    </button>
                </div>
                <div className='mt-5'>
                    {upcommingAssignmentData?.length === 0 && !isLoadingUpcoming ? (
                        <AssignmentsEmpty title={t('titleYouAssignmentEmpty')} note={t('noteSlpAssignmentEmpty')} />
                    ) : (
                        upcommingAssignmentData?.map((assignment: any) => {
                            return <UpcomingAssignmentItem assignment={assignment} open={open} setOpen={setOpen} />
                        }
                        )
                    )}
                </div>
            </div>
            <div className="w-1/2 py-5 pr-5 right-side">
                <div className="bg-blue p-5 h-full">
                    <div className='flex justify-between'>
                        <div className='font-semibold text-xl text-on-primary-container'>{t('patient.pastAssignments')}</div>
                        <button className='flex link-color font-normal' onClick={() => handleSeeAllAssignment('past')} >
                            {t('seeAll')}
                            <HiChevronRight className="w-6 h-6" />
                        </button>
                    </div>
                    <div className='mt-5'>
                        {pastAssignmentsData?.length === 0 && !isLoadingPast ? (
                            <AssignmentsEmpty title={t('titleYouAssignmentEmpty')} note={t('noteSlpAssignmentEmpty')} />
                        ) : (
                            pastAssignmentsData?.map((assignment: any) => (
                                <PastAssignmentItem assignment={assignment} setAssignmentPlaying={setAssignmentPlaying} assignmentPlaying={assignmentPlaying} setAudio={setAudio} audio={audio} />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientDashboard