import Svg from 'components/svg';

const PlayButton = (props: any) => {
  const { isPlay = true, isPause = true, action, className = '' } = props;
  return (
    <button
      type="button"
      className={`${isPause ? 'bg-hover-20' : 'bg-error'} transition-all duration-200 rounded-full ${className} play-button`}
      onClick={isPlay && action}
    >
      {isPlay ? (
        <div className="w-7 h-7 flex justify-center items-center bg-white rounded-full border-2 border-on-primary-container cursor-pointer">
          <Svg name={isPause ? 'play' : 'pause'} className="w-auto h-auto" />
        </div>
      ) : (
        <div className="w-7 h-7 flex justify-center items-center rounded-full border-2 border-on-primary-container">{''}</div>
      )}
    </button>
  );
};
export default PlayButton;
