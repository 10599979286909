/* eslint-disable react/jsx-props-no-spreading */
import { HiOutlineDuplicate, HiOutlineTrash } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { pageType, permissionKeys, roleName } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkPermission, messageErrors, copyToClipboard, invalidateQueriesUser, handleHeaderSort } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import './organizationClinicianDetail.scss';
import useUser from 'hooks/useUser';
import { getOrgUsers, getUserById, unassignClinician, updateUserImage } from 'api/userApi';
import { useParams } from 'react-router-dom';
import { Tabs, Tooltip } from 'flowbite-react';
import moment from 'moment';
import { getOrgRoles } from 'api/rolesApi';
import { filter, find, flattenDeep, isEqual, map, uniq } from 'lodash';
import ButtonBack from 'components/button/buttonBack';
import { initUser } from 'utils/initData';
import NoResult from 'components/commonComponent/noResult';
import { userType } from 'utils/proptypes';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import SpinnerComponent from 'components/spinner';
import PaginateTable from 'components/table/paginate';
import ActionTable from 'components/table/actionTable';
import AddPatientToClinician from 'components/modal/addPatientToClinician';
import Actions from 'components/actions';
import PatientsEmpty from 'components/empty/patientsEmpty';
import Banner from 'components/banner/banner';
import DetailTab from 'components/userInfo';
import Sort from 'components/commonComponent/sort';

const OrganizationClinicianDetail = (props: any) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { PLATFORM_ADMIN, WRITE_USER } = permissionKeys;
  const { ORGANIZATION_ADMIN, SLP } = roleName;
  const { organizationId } = useSelector(orgSelector);
  const [clinician, setClinician]: any = useState();
  const { userInfo } = useSelector(userSelector);
  const [isEdit, setIsEdit] = useState(false);
  const { fetchCurrentUser } = useUser();
  const params: any = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [totalPatientEntities, setTotalPatientEntities] = useState(0);
  const [isShowNoPatient, setIsShowNoPatient] = useState(true);
  const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const { data } = useQuery(['getOrganizationClinicianDetail', params?.id], () => getUserById(params?.id), {
    retry: false,
    staleTime: Infinity,
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const platformAdmin = find(uniq(flattenDeep(map(data?.data?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
  const isWrite = checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin);

  const { data: roles } = useQuery(['getOrganizationRoles'], () => getOrgRoles(), { staleTime: Infinity });
  const roleOptions: any = filter(roles?.data, role => [ORGANIZATION_ADMIN, SLP].includes(role.displayName));

  const { data: patients, isLoading } = useQuery(
    ['getPatientsOfClinician', searchValue, currentPage, params?.id, sortBy, sortByType],
    () => getOrgUsers({ page: currentPage, limit: 5, searchQuery: searchValue, slpUserId: params?.id, sortBy, sortByType }),
    {
      staleTime: Infinity,
      onSuccess: data => {
        setTotalPatientEntities(data.data.totalEntities);
      },
    },
  );
  const mutaOrganizationImage = useMutation('clinicianImage', {
    mutationFn: updateUserImage,
    onSuccess: () => {
      const message: string = t('organizationClinicians.editSuccessMessage');
      toast.success(message);
      invalidateQueriesUser(queryClient);
      setIsEdit(false);
      if (params?.id === userInfo.id) fetchCurrentUser();
    },
  });

  const mutUnassignClinician = useMutation('unassignClinician', {
    mutationFn: unassignClinician,
    onSuccess: () => {
      const message: string = t('organizationPatients.deletedPatientFromClinicianSuccess');
      toast.success(message);
      invalidateQueriesUser(queryClient);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleUploadImage = async (formData: any) => {
    mutaOrganizationImage.mutate({ id: params.id, file: formData });
  };

  const handleUnassignClinician = async (patientId: string) => {
    mutUnassignClinician.mutate({ patientId, clinicianId: params?.id });
  };


  useEffect(() => {
    if (data?.data) {
      setClinician(data?.data);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleCopyToClipboard = (e: any, item: any, message: string) => {
    copyToClipboard(item, message);
    e.stopPropagation();
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleAddPatient = () => {
    setOpenAddPatientModal(true);
  };

  useEffect(() => {
    if (patients?.data) {
      setPatientList(patients?.data.entities);
      setTotalPatientEntities(patients?.data.totalEntities);
      if (patients?.data.entities.length > 0) {
        setIsShowNoPatient(false);
      }
    }
  }, [patients?.data]);

  return (
    <>
      <div className="flex flex-col org-clinician-detail w-full">
        {clinician && (
          <>
            <div className="back-to-clinician flex items-center pl-5">
              <ButtonBack buttonName={t('organizationClinicians.backToClinicians')} />
            </div>
            <Banner targetData={clinician} isWrite={isWrite} handleUpdate={handleUploadImage} labelId="Clinician ID" />
            <div className="clinician-infor h-full flex">
              {/* eslint-disable-next-line  */}
              <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-button w-full">
                <Tabs.Item active title="DETAILS">
                  <DetailTab type={pageType.ORGANIZATION} targetData={clinician} isWrite={isWrite} roleData={roleOptions} />
                </Tabs.Item>
                <Tabs.Item title="PATIENTS" className="haha">
                  {!isEqual(patientList[0], initUser) &&
                    totalPatientEntities === 0 &&
                    patientList.length === 0 &&
                    isShowNoPatient &&
                    searchValue === '' &&
                    !isLoading ? (
                    <div className="px-8 pt-4">
                      <PatientsEmpty handleAddClick={isWrite && handleAddPatient} buttonName={t('organizationPatients.assignPatient')} />
                    </div>
                  ) : (
                    <div className="flex flex-col h-full clinician-patients-container px-7">
                      <div className="w-full clinician-patients  grow">
                        <div>
                          <ActionTable
                            placeholderSearch="Search"
                            buttonName={t('organizationPatients.assignPatient')}
                            handleAddClick={isWrite && handleAddPatient}
                            handleSearch={handleSearch}
                          />
                        </div>
                        {!isEqual(patientList[0], initUser) && patientList.length === 0 && <NoResult />}
                        {!isEqual(patientList[0], initUser) && patientList?.length > 0 && (
                          <div className="relative">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
                              <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                                <tr className="w-full">
                                  <th scope="col" className="font-medium py-3 px-4 external-id">
                                    {t('organizationPatients.userId')}
                                  </th>
                                  <th scope="col" className="font-medium py-3 px-4 full-name">
                                    <div
                                      className="flex items-center cursor-pointer"
                                      onClick={() => {
                                        handleHeaderSort('firstName', sortByType, sortBy, setSortBy, setSortByType);
                                      }}
                                    >
                                      <div className="font-medium items-center mr-1 ">
                                        {t('organizationPatients.fullName')}
                                      </div>
                                      <Sort check={sortBy === 'firstName'} sortByType={sortByType} />
                                    </div>
                                  </th>
                                  <th scope="col" className="hidden-custom font-medium py-3 px-4">
                                    {t('organizationPatients.dateOfBirth')}
                                  </th>
                                  <th scope="col" className="font-medium py-3 w-5per" />
                                </tr>
                              </thead>
                              <tbody>
                                {patientList.map((item: userType) => {
                                  return (
                                    <tr
                                      key={`${item.id}-list-organization`}
                                      className="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                                    >
                                      <td className="py-1 px-4 font-medium text-gray-900 dark:text-white cursor-pointer external-id">
                                        <div className="flex text-on-primary-container flex-row items-center w-full max-w-full patient-externalId">
                                          <Tooltip content={item?.externalId} placement="top">
                                            <p className="truncate patient-id text-on-primary-container">{item?.externalId}</p>
                                          </Tooltip>
                                          <div className="copy-icon pl-1 cursor-pointer">
                                            <HiOutlineDuplicate
                                              size={20}
                                              onClick={e => handleCopyToClipboard(e, item?.externalId, t('organizationPatients.copiedExternalId'))}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td className="full-name py-1.5 px-4 font-medium items-center text-gray-900 dark:text-white cursor-pointer flex">
                                        <div className="mr-2">
                                          <GroupAvatar items={[{ ...item, url: item?.image?.url }]} />
                                        </div>
                                        <div>
                                          <div className="flex items-center name-badge">
                                            <div className="flex items-center name-badge">
                                              {(item?.firstName || item?.lastName) && (
                                                <div className="flex hover-name items-center">
                                                  <Tooltip content={`${item.firstName || ''} ${item.lastName || ''}`} placement="top">
                                                    <p className="text-on-primary-container mr-2">{`${item.firstName || ''} ${item.lastName || ''}`}</p>
                                                  </Tooltip>
                                                  <div className="copy-icon-name cursor-pointer">
                                                    <HiOutlineDuplicate
                                                      size={20}
                                                      onClick={e =>
                                                        handleCopyToClipboard(
                                                          e,
                                                          `${item.firstName || ''} ${item.lastName || ''}`,
                                                          t('organizationPatients.copiedName'),
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex hover-email ">
                                            <Tooltip content={item?.emailAddress} placement="top">
                                              <p className="break-all text-xs text-outline font-normal mr-2">{item?.emailAddress}</p>
                                            </Tooltip>
                                            <div className="copy-icon-email cursor-pointer">
                                              <HiOutlineDuplicate
                                                size={20}
                                                onClick={e => handleCopyToClipboard(e, item?.emailAddress, t('organizationPatients.copiedEmail'))}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="hidden-custom text-on-primary-container py-1.5 px-4 ">
                                        {item?.dateOfBirth ? moment(item?.dateOfBirth).format('ll') : ''}
                                      </td>
                                      <td className="py-2 w-5per">
                                        {isWrite && (
                                          <div className="flex justify-end mr-1">
                                            <Actions>
                                              <Actions.Item
                                                show
                                                action={() => handleUnassignClinician(item?.id)}
                                                icon={<HiOutlineTrash className="w-5 h-5 text-on-primary-container" strokeWidth={2.5} />}
                                              />
                                            </Actions>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {isLoading && !isEqual(patientList[0], initUser) && patientList?.length > 0 && <SpinnerComponent />}
                      </div>
                      {patientList?.length > 0 && (
                        <div className="">
                          <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalPatientEntities} pageCount={5} />
                        </div>
                      )}
                    </div>
                  )}
                </Tabs.Item>
              </Tabs.Group>
            </div>
          </>
        )}
        {
          openAddPatientModal && (
            <AddPatientToClinician
              headerTitle={t('organizationPatients.assignPatient')}
              openModal={openAddPatientModal}
              isEdit={isEdit}
              setOpenModal={setOpenAddPatientModal}
              queryClient={queryClient}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              slpId={params?.id}
            />
          )}
      </div>
    </>
  );
};

export default OrganizationClinicianDetail;
