import cn from 'classnames';
import '../../styles/styles.scss'

const GroupButton = (props: any) => {
  const { className, buttons } = props;
  return (
    <div className={cn('flex mt-2', className)}>
      {buttons?.map((button: any, index: number) => {
        let classType = '';
        if (button?.classType === 'white') {
          classType = 'button-cancel';
        } else if (button?.classType === 'blue') {
          classType = 'button-save';
        }
        return (
          <div className={`relative ${index !== 0 ? 'ml-5' : ''}`} key={`key_${index}`}>
            <button
              type={button?.type}
              onClick={button?.action || (() => { })}
              disabled={button?.isLoading}
              className={`button ${button?.isLoading ? 'is-loading' : ''} ${classType} flex justify-center items-center h-34 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-700`}
            >
              {button?.text}
            </button>
          </div>
        );
      })}
    </div>
  );
};
export default GroupButton;
