import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();
const getAssigments = (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);

  const url = `${urls.assignments.assignments}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getPatientAssignments = (queryFilter: any) => {
  const method = methods.GET;
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);

  const url = `${urls.assignments.patientAssignments}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};


const createAssignment = (payload: any) => {
  const method = methods.POST;
  const url = urls.assignments.assignments;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateAssignment = (payload: any, assignmentId: string) => {
  const method = methods.PUT;
  const url = `${urls.assignments.assignments}/${assignmentId}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteAssignment = async (assignmentId: string) => {
  const method = methods.DELETE;
  const url = `${urls.assignments.assignments}/${assignmentId}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};
const reorderAssignment = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.assignments.reorderAssignment}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

export { getAssigments, deleteAssignment,createAssignment,reorderAssignment, updateAssignment, getPatientAssignments };
