// eslint-disable-next-line
import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();
const getOrganizationByFilter = (queryFilter: queryFilter) => {
  const method = methods.GET;         
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  const url = `${urls.organization.organizations}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};
const getOrganizations = () => {
  const method = methods.GET;
  const url = `${urls.organization.organizations}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getOrganizationById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.organization.organizations}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createOrganization = (payload: { name: string }) => {
  const method = methods.POST;
  const url = `${urls.organization.organizations}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateOrganizationById = (payload: { id: any, name: string }) => {
  const method = methods.PUT;
  const url = `${urls.organization.organizations}/${payload.id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateOptionalOrganizationById = (payload: any) => {
  const method = methods.PATCH;
  const url = `${urls.organization.organizations}/${payload.id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateOrganizationImage = (payload: { id: string, file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.organization.organizations}/${id}/uploadimage`;
  const response = execute(method, url, { authorization: true }, file);
  return response;
};

const setOrgCookie = (id: any) => {
  const method = methods.GET;
  const url = `${urls.organization.organizations}/${id}/set-context`;
  const response = execute(method, url, { authorization: true });

  return response;
};

export {
  getOrganizationByFilter,
  getOrganizationById,
  updateOrganizationById,
  getOrganizations,
  createOrganization,
  updateOptionalOrganizationById,
  setOrgCookie,
  updateOrganizationImage,
};
