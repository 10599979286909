/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from '../../utils/utils';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { createSkill, updateSkill } from 'api/skillApi';
import { get, map } from 'lodash';
import InputText from 'components/inputText';
import Select from 'components/select';
import { getPrompts } from 'api/promptApi';
import { themModal } from 'utils/theme';
import '../../styles/styles.scss'
import Button from 'components/button/button';

const SkillModal = (props: any) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, queryClient, setCurrentPage } = props;
  const [t] = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState([]);

  const handleAddAndUpdate = async (values: any) => {
    if (isEdit) await updateSkill({ id: targetData.id, ...values });
    else await createSkill({ ...values });
  };

  const handleError = (error: any) => {
    const message: string = messageErrors(error, t);
    toast.error(message);
  };

  const mutSkill = useMutation('updateSkill', { mutationFn: handleAddAndUpdate });
  const { data: prompts } = useQuery(['getPrompts'], () => getPrompts({ limit: 0 }));

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = hookForm;

  const onSubmit = (values: any, props: any) => {
    mutSkill.mutate(values, {
      onSuccess: () => {
        toggleHandler();
        setCurrentPage(0);
        const message: string = isEdit ? t('skill.editSuccess') : t('skill.saveSuccess');
        toast.success(message);
        queryClient.invalidateQueries('getPrompts');
        queryClient.invalidateQueries('getSkills');
      },
      onError: handleError,
    });
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  useEffect(() => {
    if (isEdit) {
      const prompts: any = map(targetData.prompts, (s: any) => ({ value: s.id, label: s.text }));
      const initData = { name: targetData.name, promptIds: map(prompts, 'value') };
      reset(initData);
      setSelectedOption(prompts);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (option: any) => {
    const promptIds: any = map(option, 'value');
    setValue('promptIds', promptIds);
    setSelectedOption(option);
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-skill"
        show={openModal}
        root={rootRef.current ?? undefined}
        size="lg"
        onClose={toggleHandler}
        theme={themModal}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={themModal.body}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='relative'>
              <InputText hookForm={hookForm} placeholder="Skill Name" name="name" isHolderLabel={true} />
            </div>
            <div className='mt-4 relative'>
              <Select
                options={map(get(prompts, 'data.entities', []), (s: any) => ({ value: s.id, label: s.text }))}
                onChange={(option: any) => handleChange(option)}
                placeholder="Associated Prompts"
                isHolderLabel={true}
                value={selectedOption}
                errors={errors}
                name="promptIds"
                isMulti
              />
            </div>
            <div className="flex justify-center pt-8">
              <Button color="outline" outline onClick={() => toggleHandler()}>
                {t('modal.cancel')}
              </Button>
              <Button className="ml-4" type="submit" color="primary" disabled={mutSkill.isLoading}>
                  {t('modal.save')}
                </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SkillModal;
