import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createRecording, uploadRecordingFile } from 'api/recordingApi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './uploadRecording.scss';
import { WavRecorder } from 'webm-to-wav-converter';
import getBlobDuration from 'get-blob-duration';
import moment from 'moment';
import Button from 'components/button/button';

const PlayIcon = require('../../../assets/image/svg/play-icon.svg').default;
const PauseIcon = require('../../../assets/image/svg/pause-icon.svg').default;

const UploadRecording = (props: any) => {
  const { assignment, isDashboard = false } = props;
  const queryClient = useQueryClient();
  const [wavRecorder, setWavRecorder] = useState(new WavRecorder());
  const [isRecording, setIsRecording]: any = useState(false);
  const [isShowButtonSave, setIsShowButtonSave] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio]: any = useState(null);
  const [duration, setDuration]: any = useState(0);
  const [newInterval, setNewInterval]: any = useState(null);
  const [t] = useTranslation();

  const handleStartRecording = () => {
    wavRecorder.start();
    setIsRecording(true);
    const startTime = moment();
    setNewInterval(setInterval(() => setDuration(moment().diff(startTime, 'seconds')), 1000));
    setTimeout(() => handleStopRecording(), 60000 * 5); //5 Minutes (60s x 5)
    return () => clearInterval(newInterval);
  };

  const handleStopRecording = () => {
    wavRecorder.stop();
    setDuration(0);
    setIsRecording(false);
    setIsShowButtonSave(true);
    clearInterval(newInterval);
  };

  const handleSaveRecording = async () => {
    if (wavRecorder?.__data) {
      let file: any = await wavRecorder.getBlob(true);
      const audiofile = new File([file], 'audio.wav', { type: 'audio/wave' });
      const formData = new FormData();
      const dura: any = await getBlobDuration(wavRecorder.__data);
      formData.append('file', audiofile);
      formData.append('type', 'audio/wave');
      formData.append('duration', dura);
      const recording = await uploadRecordingFile(formData);
      if (recording.status === 201) {
        const payload = {
          fileId: recording.data[0].id,
          fileWaveId: recording.data[1].id,
          assignmentId: assignment?.id,
        };
        await createRecording(payload);
      }
    }
  };

  const mutSaveRecording = useMutation('handleSaveRecording', {
    mutationFn: handleSaveRecording,
    onSuccess: () => {
      queryClient.invalidateQueries('getUpcomingAssigments', { refetchActive: true });
      queryClient.invalidateQueries('getPastAssigmentsDashboard', { refetchActive: true });
      queryClient.invalidateQueries('getPastAssignment', { refetchActive: true });
      queryClient.invalidateQueries('getCurrentAssignment', { refetchActive: true });
      const message: string = t('assignment.uploadedRecordingSuccessfully');
      toast.success(message);
    },
  });

  const handleRecordAgain = () => {
    audio?.pause();
    setAudio(null);
    setIsPlaying(false);
    setDuration(0);
    setIsShowButtonSave(false);
  };

  const handlePlay = async () => {
    const url = URL.createObjectURL(wavRecorder.__data);
    const audio = new Audio(url);
    const dura = await getBlobDuration(wavRecorder.__data);
    setDuration(duration);
    const startTime = moment();
    setNewInterval(setInterval(() => setDuration(dura - moment().diff(startTime, 'seconds')), 1000));
    setAudio(audio);
    audio.play();
    setIsPlaying(true);
    return () => clearInterval(newInterval);
  };

  const handlePause = () => {
    audio.pause();
    setIsPlaying(false);
    clearInterval(newInterval);
  };

  useEffect(() => {
    setIsShowButtonSave(false);
    setAudio(null);
    setIsPlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);

  useEffect(() => {
    if (!isPlaying) {
      setDuration(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    if (duration === 0) {
      clearInterval(newInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    audio?.addEventListener('ended', () => setIsPlaying(false));
    return () => {
      audio?.removeEventListener('ended', () => setIsPlaying(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, assignment]);

  return (
    <div className={`flex items-center  w-full recording`}>
      <div className={`prompt-image ${!isDashboard ? 'mr-auto	' : 'mr-10'}`}>
        <div className="flex justify-center items-center w-60 h-60  rounded-md bg-hover image">
          <img src={assignment?.prompt?.media?.url} alt="" className="max-60" />
        </div>
      </div>
      <div className={`flex flex-col`}>
        <div className="flex justify-center mb-2 border-b border-dashed border-color-recording pb-3 mb-4">
          <p className="text-recording font-semibold text-4xl break-word">{assignment?.prompt?.text}</p>
        </div>
        {assignment?.specialInstructions && (
        <div className="flex justify-center mb-2 border-b border-dashed border-color-recording pb-3 mb-4">
          <p className="break-word pre">{assignment?.specialInstructions}</p>
        </div>
        )}
        <div className="flex justify-center items-center">
          <div>
            {!isShowButtonSave ? (
              !isRecording ? (
                <div className="bg-text-id rounded-full h-24 w-24 min-w-16 flex justify-center items-center cursor-pointer">
                  <div className="border border-2 border-circle-primary bg-recording rounded-full h-12 w-12" onClick={handleStartRecording}>
                    {''}
                  </div>
                </div>
              ) : (
                <div className="bg-text-id rounded-full h-24 w-24 min-w-16 flex justify-center items-center cursor-pointer">
                  <div className="border border-2 border-circle-primary bg-recording rounded h-12 w-12" onClick={handleStopRecording}>
                    {''}
                  </div>
                </div>
              )
            ) : isPlaying ? (
              <div className="bg-recording rounded-full h-24 w-24 min-w-15 flex justify-center items-center">
                <div
                  className="border border-2 bg-white border-circle-primary rounded-full h-12 w-12 flex items-center justify-center"
                  onClick={handlePause}
                >
                  <div style={{ paddingLeft: '2px', paddingBottom: '2px', width: '20px' }}>
                    <img src={PauseIcon} alt="" style={{ width: '20px' }} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-text-id rounded-full h-24 w-24 min-w-15 flex justify-center items-center">
                <div
                  className="border border-2 bg-white border-circle-primary rounded-full h-12 w-12 flex items-center justify-center"
                  onClick={handlePlay}
                >
                  <div style={{ paddingLeft: '2px', paddingBottom: '2px', width: '20px' }}>
                    <img src={PlayIcon} alt="" style={{ width: '20px' }} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="ml-12">
            <div className="font-semibold text-lg text-on-primary-container mb-2 flex justify-center">
              {isShowButtonSave ? 'Play' : isRecording ? 'End Recording' : 'Start Recording'}
            </div>
            <div className="font-semibold text-4xl time-color flex justify-center">
              {moment().startOf('days').add(duration, 'seconds').format('mm:ss')}
            </div>
          </div>
        </div>
        {isShowButtonSave && (
          <div className="flex mt-3">
            <Button className="mr-3.5 font-semibold" outline color="outline" onClick={handleRecordAgain}>
              {t('assignment.recordAgain')}
            </Button>
            <Button className="font-semibold" color="primary" onClick={() => mutSaveRecording.mutate()} disabled={mutSaveRecording.isLoading}>
              {t('assignment.submitRecording')}
            </Button>
          </div>
        )}
      </div>
      <div className={`${!isDashboard ? 'ml-auto' : ''} empty-div `}>{''}</div>
    </div>
  );
};
export default UploadRecording;
