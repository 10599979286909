import Routes from './router/';
import { QueryClient, QueryClientProvider } from 'react-query';
import i18next from '@core/locales';
import { I18nextProvider } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { ToastContainer } from 'react-toastify';
import { delayShowToast } from './utils/constants';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import UpdateProfileModal from 'components/modal/updateProfileModal';

function App() {
  if (typeof window !== 'undefined') {
    injectStyle();
  }
  ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_TRACKING_ID}`);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });
  return (
    <div className="App">
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
      >
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18next}>
            <Routes />
            <ToastContainer
              position="top-right"
              autoClose={delayShowToast}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <UpdateProfileModal />
          </I18nextProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </div>
  );
}

export default App;
