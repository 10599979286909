/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from '../../utils/utils';
import useUser from 'hooks/useUser';
import { TextInput, Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import './organizationModal.scss'
import { assignClinician } from 'api/userApi';
import { getOrgRoles } from 'api/rolesApi';

import './organizationPatientModal.scss'
import { roleName } from 'utils/constants';
import './addClinicianToPatient.scss'
import InputText from 'components/inputText';
type organizationModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    targetData?: any,
    headerTitle?: string,
    isEdit: boolean,
    queryClient?: any,
    patientId?: string
};
const AddClinicianToPatient = (props: organizationModalProps) => {
    const { openModal, setOpenModal, headerTitle, queryClient, patientId } = props;
    const { fetchCurrentUser } = useUser();
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);

    const handleAddPatient = async (values: any) => {
        await assignClinician(values);
    };

    const mutation = useMutation('addClinicianToPatient', {
        mutationFn: handleAddPatient,
        onSuccess: () => {
            toggleHandler()
            queryClient.invalidateQueries('getOrganizationPatients', { refetchActive: true }, { cancelRefetch: true })
            const message: string = t('organizationPatients.assignClinicianSuccess');
            toast.success(message);
            queryClient.invalidateQueries('getOrganizationPatientDetail', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getOrganizationClinician', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getPatientsOfClinician', { refetchActive: true }, { cancelRefetch: true }); fetchCurrentUser();
            queryClient.invalidateQueries('getOrganizationClinicianDetail', { refetchActive: true }, { cancelRefetch: true }); fetchCurrentUser();
        },
        onError: (error) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        }
    });

    const ValidateSchema = Yup.object().shape({
        clinicianEmail: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });
    const {
        formState: { errors },
        handleSubmit,
        reset
    } = hookForm

    const onSubmit = async (values: any, props: any) => {
        const payload = {
            clinicianEmail: values.clinicianEmail.trim(),
            patientId: patientId,
        }

        mutation.mutate({
            ...payload
        });

    };

    const toggleHandler = () => {
        setOpenModal(!openModal)
        reset()
    }

    return (
        <div ref={rootRef}>
            <Modal
                className='modal-patient-clinician'
                show={openModal}
                size="md"
                popup={true}
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}>
                <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <div className={`${errors?.emailAddress && 'border-error'} pt-4 px-5`}>
                                <InputText hookForm={hookForm} placeholder="Email *" name="clinicianEmail" isHolderLabel={true} />
                            </div>
                            <GroupButton
                                className="items-center justify-center pt-5 pb-2"
                                buttons={[
                                    {
                                        type: 'button',
                                        text: t('modal.cancel'),
                                        classType: 'white',
                                        action: () => toggleHandler(),
                                    },
                                    {
                                        type: 'submit',
                                        text: t('modal.save'),
                                        classType: 'blue',
                                        isLoading: mutation.isLoading,
                                    },
                                ]} />
                        </>
                    </form>

                </Modal.Body>
            </Modal >
        </div>
    );
};
export default AddClinicianToPatient;
