// eslint-disable-next-line
import { propsBasicLayoutType } from 'utils/proptypes';

const BasicLayout = (props: propsBasicLayoutType) => {
  const { children } = props;
  return (
    <div className="">
      <div className=" flex justify-center h-screen mb-auto">{children}</div>
    </div>
  );
};
export default BasicLayout;
