import { camelCase, map, split } from 'lodash';
import { getAvatarColor } from '../../utils/utils';
import { HiUser } from 'react-icons/hi';

const Avatar = (props: any) => {
  const { item, className, avatarDefault } = props;
  const nameArr = split(item.name || `${camelCase(item.firstName)} ${camelCase(item.lastName)}`, ' ', 2);
  const name = map(nameArr, str => str[0]).join('');
  const backgroundAvatar = getAvatarColor(item);
  const url = item?.url || item?.image?.url;
  const textImg = className?.includes('xl') ? 'text-base' : 'text-xs';
  return (
    <div
      className={`${className || 'w-9 h-9 text-xs'} flex items-center justify-center rounded-full overflow-hidden`}
      style={{ backgroundColor: url || avatarDefault ? 'none' : name ? backgroundAvatar : '#E5E7EB' }}
    >
      {url && <img className={`w-full h-full ${textImg}`} src={url} alt="avatar" />}
      {!url && name && !avatarDefault && <span className="text-white uppercase">{name}</span>}
      {!url && !name && !avatarDefault && <HiUser className="w-4/6 h-4/6 text-outline" />}
      {!url && avatarDefault && avatarDefault}
    </div>
  );
};

export default Avatar;
