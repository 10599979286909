import SkillItem from "components/commonComponent/skillItem";
import { Accordion } from "flowbite-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { HiOutlineClock } from 'react-icons/hi'
import './upcomingAssignmentItem.scss'
import UploadRecording from "./uploadRecording";
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'

const UpcomingAssignmentItem = (props: any) => {
    const { assignment, open, setOpen } = props
    const [t] = useTranslation();
    const handleClickAssignment = (assignment: any) => {
        const tempOpen = {
            ...open,
            [assignment.id]: !open?.[assignment.id]
        }
        setOpen(tempOpen)
    }

    return (
        <div>
            <div className={`current-assignment flex justify-between p-3 border-t border-l border-r ${!open?.[assignment.id] ? 'border-b mb-3' : ' bg-title'} `} onClick={() => handleClickAssignment(assignment)}>
                <div className="flex items-center">
                    <div className="bg-text-id rounded-full h-15 w-15 min-w-15 flex justify-center items-center">
                        <div className="border border-2 border-circle-primary rounded-full h-30 w-30">
                            {''}
                        </div>
                    </div>
                    <div className="ml-5 flex flex-col justify-between">
                        <div className="font-semibold text-base text-on-primary-container mb-2">
                            {assignment.prompt?.text}
                        </div>
                    </div>
                </div>
                <div className="flex items-center infor-side">
                    <div className=" flex flex-col items-end justify-center">
                        <div className="flex justify-center items-center mb-1">
                            <HiOutlineClock className="mr-1 text-on-primary-container" />
                            <div className="text-sm flex">
                                <div className="mr-1 text-on-primary-container">
                                    Due:
                                </div>
                                <div className="font-medium text-on-primary-container">
                                    {assignment?.date ? moment(assignment?.date).format('MMMM D, YYYY') : ''}
                                </div>
                            </div>
                        </div>
                        <div>
                            <aside className="flex flex-row items-center">
                                <span className="not-started-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                <p className='not-started-status-text text-sm font-extralight'>
                                    {t('status.notStarted')}
                                </p>
                            </aside>
                        </div>

                    </div>
                    <div className="flex justify-end ml-5">
                        {!open?.[assignment.id] ? (<HiChevronDown className="w-6 h-6 text-outline" />) : (<HiChevronUp className="w-6 h-6 text-outline" />)}
                    </div>
                </div>
            </div>
            {
                open?.[assignment.id] && (
                    <div className="border-b border-r border-l mb-3 p-7">
                        <UploadRecording isDashboard assignment={assignment} />
                    </div>
                )
            }
        </div >
    )
}

export default UpcomingAssignmentItem