/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import CustomModalHeader from './customModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'redux/actions';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import FormUpdateProfile from './formUpdateProfile';
import { userSelector } from 'redux/selectors';
import { themModal } from 'utils/theme';

const UpdateProfileModal = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { isOpenProfileModal } = useSelector(userSelector);
  const closeModal = () => {
    const isOpenModal: any = false;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };

  return (
    <div ref={rootRef}>
      <Modal
        show={isOpenProfileModal}
        size="md"
        root={rootRef.current ?? undefined}
        onClose={closeModal}
        dismissible={true}
        className="modal-profile"
      >
        <CustomModalHeader title={t('updateProfile')} toggle={closeModal} />
        <Modal.Body theme={themModal.body}>
            <FormUpdateProfile />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default UpdateProfileModal;
