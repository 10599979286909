import Button from 'components/button/button';
import Svg from 'components/svg';
import { useTranslation } from 'react-i18next';

const CliniciansEmpty = (props: any) => {
  const { handleAddClick, buttonName, note, title, className } = props;
  const [t] = useTranslation();
  return (
    <div className={`bg-white border border-gray-200 rounded-sm flex flex-col items-center p-8 ${className || ''}`}>
      <Svg name='clinicians-empty' className='h-[84px] w-auto text-outline-variant' />
      <p className="text-on-primary-container font-semibold text-xl mt-7">{title || t('titleSlpEmpty')}</p>
      <p className="text-outline mt-2 text-base">{note || t('noteSlpEmpty')}</p>
      {handleAddClick && (
        <div className="mt-6 flex justify-center">
          <Button color="primary" label={buttonName} onClick={handleAddClick} />
        </div>
      )}
    </div>
  );
};
export default CliniciansEmpty;
