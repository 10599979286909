import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizationId: null,
};

const setOrgCookie = (state: any, action: any) => {
  state.organizationId = action.payload;
};

export const orgSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setOrgCookie,
  },
});

export default orgSlice;
