/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { optionType, roleType } from 'utils/proptypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { createAdminUser, createOrgUser, getUsersByEmail, updateAdminUser, updateOrgUser } from 'api/userApi';
import CustomModalHeader from './customModalHeader';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'flowbite-react';
import { pageType } from 'utils/constants';
import { HiCheck } from 'react-icons/hi';
import './userModal.scss';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import moment from 'moment';
import InputText from 'components/inputText';
import Select from 'components/select';
import DatePicker from 'components/datePicker';
import { useSelector } from 'react-redux';
import { orgSelector } from 'redux/selectors';
import { themModal } from 'utils/theme';
import { handleTrimString } from 'utils/utils';

type userOrganizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle: Function,
  organizationId?: string,
  successFunc?: Function,
  isEdit: boolean,
  isEditSuperAdmin?: boolean,
  roleOptions?: [roleType],
  setIsEdit?: any,
  type?: string,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};

const UserModal = (props: userOrganizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, setIsEdit, roleOptions, type, queryClient, currentPage, setCurrentPage } = props;
  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();
  const { organizationId } = useSelector(orgSelector);
  const rootRef = useRef<HTMLDivElement>(null);
  const roleSelectOptions: any = roleOptions?.map((r: roleType) => ({ value: r.id, label: r.displayName }));
  const [emailInput, setEmailInput] = useState('');
  const [dateValue, setDateValue] = useState('');

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    roleIds:
      type === pageType.SITE
        ? Yup.array().min(1, t('userManagementPage.roleMin')).required(t('userManagementPage.requiredField'))
        : Yup.string().required(t('userManagementPage.requiredField')),
  });

  const hookForm = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchema) });
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues,
  } = hookForm;

  const { data } = useQuery(['checkEmailExist', emailInput], () => getUsersByEmail(emailInput), {
    staleTime: Infinity,
    enabled: emailInput !== '',
    retry: false,
  });
  const handleSubmitUser = (data: any) => {
    if (isEdit) {
      return type === pageType.SITE ? updateAdminUser(data) : updateOrgUser(data);
    } else {
      return type === pageType.SITE ? createAdminUser(data) : createOrgUser(data);
    }
  };

  const mutation = useMutation('create-update-usesOrg', { mutationFn: handleSubmitUser });

  const onSubmit = (value: any) => {
    const payload = {
      ...value,
      firstName: isEmpty(value.firstName) ? null : value.firstName,
      lastName: isEmpty(value.lastName) ? null : value.lastName,
    };
    handleTrimString(payload)
    mutation.mutate(
      { payload, id: targetData?.id },
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = isEdit ? t('userManagementPage.editSuccessMessage') : t('userManagementPage.createSuccessMessage');
          toast.success(message);
          reset({});
          fetchCurrentUser();
          if (currentPage !== 0 && !isEdit) {
            queryClient.invalidateQueries('getUsers', { refetchActive: false }, { cancelRefetch: true });
            setCurrentPage(0);
          } else queryClient.invalidateQueries('getUsers', { refetchActive: true }, { cancelRefetch: true });
        },
        onError: async (error: any) => {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = () => {
    reset();
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (isEdit) {
      let roleIds;
      if (type === pageType.SITE) {
        roleIds = targetData?.roles?.map((role: roleType) => role.id);
      } else {
        roleIds = targetData?.organizations?.find((org: any) => org.organizationId === organizationId)?.roleId;
      }
      const initData = {
        firstName: targetData.firstName,
        lastName: targetData.lastName,
        emailAddress: targetData.emailAddress,
        roleIds,
      };
      reset(initData);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setValue('firstName', data?.data.firstName);
      setValue('lastName', data?.data.lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const checkEmailIsExist = () => {
    if (type === pageType.ORGANIZATION && !errors.emailAddress) {
      const email: any = getValues('emailAddress');
      setEmailInput(email);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      checkEmailIsExist();
    }
  };

  const handleSelectDate = (date: Date) => {
    const formatDate: any = moment(date).format('YYYY-MM-DD');
    setValue('dateOfBirth', formatDate);
    const tempDate: any = moment(date).format('MMMM DD, YYYY');
    setDateValue(tempDate);
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-add-user"
        show={openModal}
        size="md"
        root={rootRef.current ?? undefined}
        onClose={() => cancelHandler()}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle()} toggle={() => cancelHandler()} />
        <Modal.Body theme={themModal.body}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <div className={`${errors.firstName && 'border-error'}`}>
                <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.firstName')} *`} name="firstName" isHolderLabel={true} />
              </div>
              <div className={`${errors.lastName && 'border-error'}`}>
                <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.lastName')} *`} name="lastName" isHolderLabel={true} />
              </div>
              <div className={`${errors.emailAddress && 'border-error'}`} onBlur={() => checkEmailIsExist()}>
                <InputText
                  hookForm={hookForm}
                  placeholder={`${t('userProfilePage.email')} *`}
                  name="emailAddress"
                  isHolderLabel={true}
                  handleKeyPress={handleKeyPress}
                />
                {data && !errors.emailAddress && (
                  <div className="flex items-center">
                    <HiCheck className="w-6 h-6 mr-2 text-green-600 " />
                    <div className={`text-green-600 text-xs font-normal mt-1 flex`}>{t('userManagementPage.emailExist')}</div>
                  </div>
                )}
              </div>
              <div>
                <DatePicker
                  hookForm={hookForm}
                  placeholder="Date of Birth"
                  name="dateOfBirth"
                  dateValue={dateValue}
                  handleSelectDate={handleSelectDate}
                  isHolderLabel={true}
                />
              </div>
              <div className="custom-multi-select mt-2.5">
                <Select
                  options={roleSelectOptions}
                  hookForm={hookForm}
                  name="roleIds"
                  isHolderLabel={true}
                  placeholder={`${t('userProfilePage.role')} *`}
                  isMulti={type === pageType.SITE}
                />
              </div>
              <GroupButton
                className="items-center justify-center pt-1 pb-2"
                buttons={[
                  {
                    type: 'button',
                    text: t('modal.cancel'),
                    classType: 'white',
                    action: () => cancelHandler(),
                  },
                  {
                    type: 'submit',
                    text: t('modal.save'),
                    classType: 'blue',
                    isLoading: mutation.isLoading,
                  },
                ]}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default UserModal;
