import { userType } from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import { apiStatus, pageCount, permissionKeys, roleName, routePaths } from 'utils/constants';
import { isEmpty } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import { checkPermission, copyToClipboard, handleHeaderSort, invalidateQueriesUser } from '../../utils/utils';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import { Tooltip } from 'flowbite-react';
import { deleteOrganizationPatient, getOrgUsers } from 'api/userApi';
import OrganizationPatientModal from 'components/modal/organizationPatientModal';
import moment from 'moment';
import './organizationPatients.scss';
import { useNavigate } from 'react-router-dom';
import Avatar from 'components/GroupAvatar/Avatar';
import Actions from 'components/actions';
import LabelUser from 'components/labelUser';
import PatientsEmpty from 'components/empty/patientsEmpty';
import Sort from 'components/commonComponent/sort';

const OrganizationPatients = (props: any) => {
  const queryClient = useQueryClient();
  const { WRITE_USER, PLATFORM_ADMIN } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [targetOrganization, setTartgetOrganization] = useState(initOrganization);
  const [organizationPatients, setOrganizationPatients] = useState([]);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const { fetchCurrentUser } = useUser();
  const navigate = useNavigate();
  const handleAddOrganization = () => {
    setTartgetOrganization(initOrganization);
    setOpenModal(true);
    setIsEdit(false);
  };

  const { data, isLoading } = useQuery(
    ['getOrganizationPatients', currentPage, searchValue, organizationId, sortBy, sortByType],
    () => getOrgUsers({ page: currentPage, limit: pageCount, searchQuery: searchValue, roleNames: roleName.PATIENTS, sortBy, sortByType }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data?.data.totalEntities);
      },
      onError: () => setOrganizationPatients([]),
      staleTime: Infinity,
    },
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleViewDetail = (patientId: string) => {
    navigate(`${routePaths.ORG_PATIENTS}/${patientId}`);
  };

  const handleRemoveMember = async (id: string) => {
    try {
      const data = await deleteOrganizationPatient(id);
      if (data.status === apiStatus.NO_CONTENT) {
        const message: string = t('organizationPatients.deletedSuccess');
        toast.success(message);
        invalidateQueriesUser(queryClient);
        fetchCurrentUser();
      }
    } catch (e) {
      const message: string = t('generalErrors');
      toast.error(message);
    }
  };

  const handleCopyToClipboard = (e: any, item: any, message: string) => {
    copyToClipboard(item, message);
    e.stopPropagation();
  };

  const renderClinicians = (item: any) => {
    const org = item?.organizations?.find((org: any) => org.id === organizationId);
    const orgName = `${org?.slpUser?.firstName} ${org?.slpUser?.lastName}`;
    return (
      org?.slpUser && (
        <div className="flex">
          <Avatar item={org?.slpUser} />
          <div className="ml-2 break-word dark:text-white font-semibold flex items-center">{orgName}</div>
        </div>
      )
    );
  };

  useEffect(() => {
    setIsFetching(true);
    if (data !== undefined) {
      setOrganizationPatients(data?.data?.entities);
      setTotalEntities(data?.data.totalEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setCurrentPage(0)
  }, [organizationId])

  const AddModal: any = () => {
    return (
      openModal && (
        <OrganizationPatientModal
          headerTitle={t('organizationPatients.addPatient')}
          openModal={openModal}
          targetData={targetOrganization}
          isEdit={isEdit}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )
    );
  };

  if (!isLoading && isEmpty(searchValue) && totalEntities === 0) {
    return (
      <div className="w-full sm:p-8 p-4">
        <PatientsEmpty
          title={t('titleOrgPatientEmpty')}
          note={t('noteOrgPatientEmpty')}
          buttonName="Add Patient"
          handleAddClick={handleAddOrganization}
        />
        <AddModal />
      </div>
    );
  }

  return (
    <div className="w-full sm:px-8 px-4">
      <div>
        <ActionTable
          placeholderSearch="Search"
          buttonName="Add Patient"
          handleAddClick={checkPermission(userInfo, props.type, [WRITE_USER], organizationId) && handleAddOrganization}
          handleSearch={handleSearch}
        />
      </div>
      {!isLoading && isEmpty(organizationPatients) && <NoResult />}
      {!isLoading && !isEmpty(organizationPatients) && (
        <>
          <div className="overflow-x-auto mb-2 list-table">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
              <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                <tr className="w-full">
                  <th scope="col" className="font-medium py-3 px-4 external-id">
                    {t('organizationPatients.userId')}
                  </th>
                  <th scope="col" className="font-medium py-3 px-4 full-name">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleHeaderSort('firstName', sortByType, sortBy, setSortBy, setSortByType);
                      }}
                    >
                      <div className="font-medium items-center mr-1 ">
                        {t('organizationPatients.fullName')}
                      </div>
                      <Sort check={sortBy === 'firstName'} sortByType={sortByType} />
                    </div>
                  </th>
                  <th scope="col" className="hidden-custom font-medium py-3 px-4">
                    {t('organizationPatients.dateOfBirth')}
                  </th>
                  <th scope="col" className="hidden-mobile hidden-clinician font-medium py-3 px-4">
                    {t('organizationPatients.clinician')}
                  </th>
                  <th scope="col" className="font-medium py-3 w-5per" />
                </tr>
              </thead>
              <tbody>
                {organizationPatients.map((item: userType) => {
                  return (
                    <tr
                      onClick={() => handleViewDetail(item?.id)}
                      key={`${item.id}-list-organization`}
                      className="border-b dark:bg-gray-800 dark:border-gray-700  hover:bg-hover-5 dark:hover:bg-gray-600"
                    >
                      <td className="py-1.5 px-4 font-medium text-gray-900 dark:text-white cursor-pointer external-id">
                        <div className="flex text-on-primary-container flex-row items-center w-full max-w-full patient-externalId">
                          <Tooltip content={item?.externalId} placement="top">
                            <p className="truncate patient-id text-on-primary-container">{item?.externalId}</p>
                          </Tooltip>
                          <div className="copy-icon pl-1 cursor-pointer">
                            <HiOutlineDuplicate
                              size={20}
                              onClick={e => handleCopyToClipboard(e, item?.externalId, t('organizationPatients.copiedExternalId'))}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="full-name py-1.5 items-center px-4 font-medium items-center text-gray-900 dark:text-white cursor-pointer flex">
                        <LabelUser item={item} />
                      </td>
                      <td className="hidden-custom text-on-primary-container py-1.5 px-4 ">
                        {item?.dateOfBirth ? moment(item?.dateOfBirth).format('ll') : ''}
                      </td>
                      <td className="hidden-mobile hidden-clinician text-on-primary-container py-1.5 px-4">{renderClinicians(item)}</td>
                      <td className="py-1.5 w-5per">
                        {checkPermission(userInfo, props.type, [WRITE_USER], organizationId) && (
                          <Actions>
                            <Actions.Item action={() => handleViewDetail(item?.id)} label={t('organizationPage.overview')} />
                            <Actions.Item
                              action={() => handleRemoveMember(item?.id)}
                              label={t('organizationPatients.removeMember')}
                            />
                          </Actions>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
        </>
      )}
      <AddModal />
    </div>
  );
};

export default OrganizationPatients;
