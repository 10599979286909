/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { handleTrimString, invalidateQueriesUser, messageErrors } from '../../../utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './organizationClinicianModal.scss'
import { optionType, roleType } from 'utils/proptypes';
import { getUsersByEmail, createOrgUser } from 'api/userApi';
import { HiCheck } from 'react-icons/hi';
import { filter, flattenDeep, isEmpty, omitBy } from 'lodash';
import { roleName } from 'utils/constants';
import InputText from 'components/inputText';
import Select from 'components/select';

type organizationModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    targetData: any,
    headerTitle?: string,
    isEdit: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
    roles?: any,
};
const OrganizationClinicianModal = (props: organizationModalProps) => {
    const { openModal, setOpenModal, targetData, headerTitle, currentPage, queryClient, setCurrentPage, roles } = props;
    const { fetchCurrentUser } = useUser();
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);
    const [emailInput, setEmailInput] = useState<any>('')
    const [selectedOption, setSelectedOption] = useState([]);

    const roleSelectOptions: any = roles.data
        ?.filter((role: any) => role.displayName === roleName.ORGANIZATION_ADMIN || role.displayName === roleName.SLP)
        .map((r: roleType) => ({ value: r.id, label: r.displayName }));

    const handleAddClinician = async (values: any) => {
        const payload: any = omitBy(values, v => v === '');
        await createOrgUser(payload);
    };

    const ValidateSchema = Yup.object().shape({
        firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
        lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
        emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
        roleIds: Yup.array().min(1, t('userManagementPage.roleMin')).required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        formState: { errors },
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        setError
    } = hookForm

    const checkEmailIsExist = () => {
        if (!errors.emailAddress) {
            const email = getValues('emailAddress')
            setEmailInput(email)
        }
    }
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            checkEmailIsExist()
        }
    }

    const handleChange = (options: any = []) => {
        const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
        setSelectedOption(noDefaultOptions);
        const roleIds = noDefaultOptions.map((option: optionType) => option.value);
        setValue('roleIds', roleIds);
        if (roleIds.length > 0) {
            clearErrors('roleIds');
        } else {
            setError('roleIds', {
                type: 'required',
                message: t('userManagementPage.requiredField'),
            });
        }
    };

    const mutation = useMutation('createOrganizationClinician', {
        mutationFn: handleAddClinician,
        onSuccess: () => {
            toggleHandler()
            if (currentPage !== 0) setCurrentPage(0);
            invalidateQueriesUser(queryClient);
            const message: string = t('organizationClinicians.addClinicianMessage');
            toast.success(message);
            fetchCurrentUser();
        },
        onError: (error) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        }
    });


    const { data } = useQuery(['checkEmailExist', emailInput], () => getUsersByEmail(emailInput),
        {
            staleTime: Infinity,
            enabled: emailInput !== '',
            retry: false
        }
    )

    const onSubmit = (value: any, props: any) => {
        const payload = {
            ...value,
            firstName: isEmpty(value.firstName) ? null : value.firstName,
            lastName: isEmpty(value.lastName) ? null : value.lastName,
        };
        handleTrimString(payload)

        mutation.mutate({ payload, id: targetData?.id });
    };

    const toggleHandler = () => {
        setOpenModal(!openModal)
        reset()
    }

    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data) {
            setValue('firstName', data?.data.firstName)
            setValue('lastName', data?.data.lastName)
            clearErrors('firstName');
            clearErrors('lastName');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div ref={rootRef} className="relative">
            <Modal
                className='modal-organization-clinician'
                show={openModal}
                size="md"
                popup={true}
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}>
                <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
                <Modal.Body className='modal-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${errors.emailAddress && 'border-error'} pt-4 px-5`} onBlur={() => checkEmailIsExist()}>
                            <InputText hookForm={hookForm} placeholder="Email *" name="emailAddress" isHolderLabel={true} />
                            {data && !errors.emailAddress && (
                                <div className='flex items-center'>
                                    <HiCheck className='w-6 h-6 mr-2 text-green-600 ' />
                                    <div className={`text-green-600 text-xs font-normal mt-1 flex`}>
                                        {t('userManagementPage.emailExist')}</div>
                                </div>
                            )}
                        </div>
                        <div className={`${errors?.externalId && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder="Ext. User ID" name="externalId" isHolderLabel={true} />
                        </div>
                        <div className={`${errors?.firstName && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder="First Name *" name="firstName" isHolderLabel={true} />
                        </div>
                        <div className={`${errors?.lastName && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder="Last Name *" name="lastName" isHolderLabel={true} />
                        </div>
                        <div className="custom-multi-select mt-2.5 pt-4 px-5">
                            <div>
                                <Select
                                    options={roleSelectOptions}
                                    onChange={(option: any) => handleChange(option)}
                                    placeholder={`${t('userProfilePage.role')} *`} isHolderLabel={true}
                                    value={selectedOption}
                                    errors={errors}
                                    name="roleIds"
                                    isMulti={false}
                                />
                            </div>
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-2 pb-2"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.save'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal >
        </div>
    );
};
export default OrganizationClinicianModal;
