import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roles: [],
};

const setRoles = (state: any, action: any) => {
  state.roles = action.payload;
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoles,
  },
});

export default roleSlice;
