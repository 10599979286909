import { useTranslation } from 'react-i18next';
import { Modal } from 'flowbite-react';
import { useRef } from 'react';
import './organizationModal.scss';
import './organizationPatientModal.scss';
import Button from 'components/button/button';
import { themModal } from 'utils/theme';

const PatientInviteModal = (props: any) => {
  const { openInviteModal, setOpenInviteModal, isReinvite, onSubmit } = props;
  const [t] = useTranslation();
  const rootRef = useRef(null);

  const toggleHandler = () => {
    setOpenInviteModal(!openInviteModal);
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-organization-patient"
        show={openInviteModal}
        size="md"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
        theme={themModal}
      >
        <Modal.Body theme={themModal.body}>
          <p className="text-on-primary-container text-base font-semibold text-center">
            {isReinvite ? 'Invitation Sent' : 'User doesn’t exist on the system'}
          </p>
          <p className="text-on-primary-container text-sm text-center pt-2">
            {isReinvite
              ? 'You already invited them but they haven’t created an account yet. Do you want invite again?'
              : 'Are you sure you want to invite this user to join as a the patient.'}
          </p>
          <div className="flex justify-center items-center pt-6">
            <Button color="outline" outline onClick={() => toggleHandler()}>
              {t('modal.cancel')}
            </Button>
            <Button className="ml-4" color="primary" onClick={() => onSubmit()}>
              {t('modal.invite')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PatientInviteModal;
