import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  users: [],
  isOpenProfileModal: false
};

const setUserInfo = (state: any, action: any) => {
  state.userInfo = action.payload;
};

const setUsers = (state: any, action: any) => {
  state.users = action.payload;
};

const setOpenProfileModal = (state: any, action: any) => {
  state.isOpenProfileModal = action.payload;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo,
    setUsers,
    setOpenProfileModal
  },
});
export default userSlice;
