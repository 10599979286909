import useUser from 'hooks/useUser';
import { routePaths } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const { handleLogout } = useUser();
  const navigate = useNavigate();

  const handleLogoutUser = () => {
    handleLogout();
    navigate(routePaths.LOGIN_PAGE);
  };

  handleLogoutUser();
  return null;
};

export default LogoutPage;
