import PlayButton from 'components/button/playButton';
import { HiOutlineClock } from 'react-icons/hi';
import { assign, cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Svg from 'components/svg';
import './style.scss'

const stateInit: any = {
  pip: false,
  playing: false,
  controls: false,
  light: false,
  volume: 1,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
};

const Duration = ({ className, seconds }: any) => {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
};

const format = (seconds: any) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return hh ? `${hh}:${pad(mm)}:${ss}` : `${mm}:${ss}`;
};

const pad = (string: any) => {
  return ('0' + string).slice(-2);
};

const Player = (props: any) => {
  const playerRef = useRef<any>(null);
  const [state, setState] = useState(stateInit);
  const { isPlay = true, targetData } = props;

  const setStateElm = (value: any) => {
    const newStage = cloneDeep(state);
    assign(newStage, value);
    setState(newStage);
  };

  useEffect(() => {
    if (targetData?.recording?.file?.duration) setStateElm({ duration: Number(targetData?.recording?.file?.duration) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData]);

  const handleSeekMouseDown = () => {
    setStateElm({ seeking: true, seekingLine: state.played });
  };

  const handleSeekChange = (e: any) => {
    setStateElm({ seekingLine: parseFloat(e.target.value) });
  };

  const handleSeekMouseUp = (e: any) => {
    setStateElm({ seeking: false, played: state.seekingLine });
    playerRef.current?.seekTo(state.seekingLine * state.duration, 'seconds');
  };

  const handleChangeSeek = (isNext: any, seconds: any = 3) => {
    let newSeconds = state.played * state.duration + (isNext ? seconds : -seconds);
    newSeconds = newSeconds > 0 ? (newSeconds > state.duration ? state.duration : newSeconds) : 0;
    setStateElm({ played: newSeconds / state.duration, playing: newSeconds !== state.duration });
    playerRef.current?.seekTo(newSeconds, 'seconds');
  };

  return (
    <div className="relative bg-hover-5 flex player">
      <div className='image w-46'>
        <div className='flex justify-center items-center w-46 h-46 rounded-md mr-2 bg-hover' >
          <div className=" max-36 max-h-36 h-36 flex justify-center items-center" >
            <img src={targetData?.prompt?.media?.url} alt="" className="w-full max-h-36" />
          </div>
        </div>
      </div>
      <div className='grow flex flex-col justify-center'>
        <div className="p-4">
          <div className="flex justify-between items-center">
            <div className="text-base font-semibold text-on-primary-container mb-1">{targetData.prompt.text}</div>
            <div className="text-xs text-outline flex items-center">
              <HiOutlineClock className="mr-0.5" />
              {moment(targetData.date).format('MMM DD, YYYY')}
            </div>
          </div>
          <div className="my-2">
            <div className="bg-hover-20 w-full h-1 rounded-sm relative pr-5">
              <div
                className="absolute top-0 left-0 bg-error h-full rounded-sm transition-all delay-50 ease-out relative"
                style={{ width: `${(state.seeking ? state.seekingLine : state.played) * 100}%` }}
              >
                <span className="absolute rounded-full w-5 h-5 bg-error right-0" style={{ top: -8, right: -20 }} />
              </div>
              <input
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
                onPointerDown={() => handleSeekMouseDown()}
                onPointerUp={event => handleSeekMouseUp(event)}
                type="range"
                className="w-full absolute top-0 left-0 h-2 cursor-pointer opacity-0"
                min={0}
                max={0.999999}
                step="any"
                value="0"
              />
            </div>
            <div className="mt-2 flex justify-between text-xs text-outline">
              <Duration seconds={state.duration * (state.seeking ? state.seekingLine : state.played)} />
              <span>
                -<Duration seconds={state.duration - state.duration * (state.seeking ? state.seekingLine : state.played)} />
              </span>
            </div>
          </div>
          <div className="flex justify-center">
            <button type="button" onClick={() => handleChangeSeek(false)}>
              <Svg name="backward-3-seconds" />
            </button>
            <PlayButton
              isPlay={isPlay}
              isPause={!state.playing}
              action={() => setStateElm({ playing: !state.playing })}
              className="cursor-pointer p-3.5 mx-5"
            />
            <button type="button" onClick={() => handleChangeSeek(true)}>
              <Svg name="forward-3-seconds" />
            </button>
          </div>
        </div>
        <ReactPlayer
          className="!hidden"
          ref={playerRef}
          pip={state.pip}
          playing={state.playing}
          light={state.light}
          loop={state.loop}
          playbackRate={state.playbackRate}
          volume={state.volume}
          muted={state.muted}
          url={targetData?.recording?.file?.url}
          onDuration={duration => duration !== Infinity && setStateElm({ duration })}
          onProgress={s => setStateElm({ played: s.played || s.playedSeconds / state?.duration })}
          onEnded={() => setStateElm({ playing: false })}
        />
      </div>

    </div >
  );
};
export default Player;
