/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from './customModalHeader';
import { toast } from 'react-toastify';
import { handleTrimString, invalidateQueriesUser, messageErrors } from '../../utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './organizationModal.scss';
import { assignClinician, createOrgUser, getUsersByEmail, invitationEmail } from 'api/userApi';
import { getOrgRoles } from 'api/rolesApi';
import './organizationPatientModal.scss';
import { roleName } from 'utils/constants';
import PatientInviteModal from './patientInviteModal';
import InputText from 'components/inputText';
import '../../styles/styles.scss';
import Button from 'components/button/button';
import { themModal } from 'utils/theme';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';

type organizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData?: any,
  headerTitle?: string,
  isEdit?: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
  isAssign?: any,
};
const OrganizationPatientModal = (props: organizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, currentPage, queryClient, setCurrentPage, isAssign } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);
  const { userInfo } = useSelector(userSelector);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [isReinvite, setIsReinvite] = useState(false);
  const { data: roles } = useQuery(['getOrganizationRoles'], () => getOrgRoles(), { staleTime: Infinity });

  const mutaCreatePatient = useMutation('createOrganizationPatient', {
    mutationFn: createOrgUser,
    onSuccess: () => {
      if (isAssign) {
        const patientEmail = getValues('emailAddress');
        mutAssignClinician.mutate({ patientEmail, clinicianId: userInfo.id });
      } else {
        toggleHandler();
        const message: string = t('organizationPatients.addPatientMessage');
        toast.success(message);
      }
      if (currentPage !== 0) setCurrentPage(0);
      invalidateQueriesUser(queryClient);
      fetchCurrentUser();
    },
    onError: (error: any) => {
      if (error.response.status === 409 && isAssign) {
        const patientEmail = getValues('emailAddress');
        mutAssignClinician.mutate({ patientEmail, clinicianId: userInfo.id });
      } else {
        toggleHandler();
        const message: string = messageErrors(error, t);
        toast.error(message);
      }
    },
  });

  const mutAssignClinician = useMutation('addPatientToClinician', {
    mutationFn: assignClinician,
    onSuccess: () => {
      toggleHandler();
      const message: string = t('organizationPatients.assignPatientSuccess');
      toast.success(message);
      invalidateQueriesUser(queryClient);
      fetchCurrentUser();
    },
    onError: error => {
      toggleHandler();
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const mutaInvitationEmail = useMutation('invitationEmail', {
    mutationFn: invitationEmail,
    onSuccess: () => {
      if (isAssign) {
        const patientEmailExtId = getValues('emailAddress');
        mutAssignClinician.mutate({ patientEmailExtId, clinicianId: userInfo.id });
      } else {
        toggleHandler();
        const message: string = t('organizationPatients.reinvited');
        toast.success(message);
      }
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const ValidateSchema = Yup.object().shape({
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = hookForm;
  const roleIds = roles?.data.filter((role: any) => role.displayName === roleName.PATIENTS)?.map((role: any) => role.id);

  const onSubmit = async (values: any) => {
    try {
      const { data }: any = await getUsersByEmail(values.emailAddress);
      if (!data.authId && data?.organizations?.[0]?.role?.displayName === roleName.PATIENTS) {
        setIsReinvite(true);
        setOpenInviteModal(true);
        return;
      }
      const payload = { ...values, roleIds };
      mutaCreatePatient.mutate({ payload: payload });
    } catch (error) {
      setIsReinvite(false);
      setOpenInviteModal(true);
    }
  };

  const onSave = async () => {
    if (isReinvite) {
      mutaInvitationEmail.mutate(getValues('emailAddress'));
    } else {
      const values = handleTrimString(getValues());
      mutaCreatePatient.mutate({ payload: { ...values, roleIds } });
    }
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  useEffect(() => {
    if (isEdit) {
      const initData = {
        name: targetData.name,
      };
      reset(initData);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-organization-patient"
        show={openModal}
        size="md"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={themModal.body}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${errors?.emailAddress && 'border-error'}`}>
              <InputText hookForm={hookForm} placeholder="Email *" name="emailAddress" isHolderLabel={true} />
            </div>
            <div className={`${errors?.externalId && 'border-error'} pt-2`}>
              <InputText hookForm={hookForm} placeholder="External ID" name="externalId" isHolderLabel={true} />
            </div>
            <div className="flex justify-center items-center pt-4">
              <Button color="outline" outline onClick={() => setOpenModal(false)}>
                {t('modal.cancel')}
              </Button>
              <Button className="ml-4" color="primary" type="submit">
                {t('modal.save')}
              </Button>
            </div>
          </form>
        </Modal.Body>
        <PatientInviteModal openInviteModal={openInviteModal} setOpenInviteModal={setOpenInviteModal} isReinvite={isReinvite} onSubmit={onSave} />
      </Modal>
    </div>
  );
};
export default OrganizationPatientModal;
