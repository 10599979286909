// eslint-disable-next-line
export default {
  baseUrl: `${process.env.REACT_APP_BASE_API_URL}/v1`,
  user: {
    users: 'Users',
    userProfile: 'users/profile',
    getCurrentUser: 'Users/CurrentUser',
    adminUsers: 'users/adminUsers',
    organizationUsers: 'users/organizationUsers',
    adminUser: 'users/adminUser',
    organizationUser: 'users/organizationUser',
    userControllerChangeEmailAddress: 'Users/ChangeEmailAddress',
    setRoles: 'Users/SetRoles',
    resetPassword: 'Users/reset-password',
    getUserByVerifiedCode: 'Users/VerifiedCode',
    resendEmailVerification: 'Users/Auth0/ResendEmailVerification',
    userEmail: 'users/email',
    removePatient: 'users/removePatient',
    myPatients: 'users/myPatients',
    assignClinician: 'users/assignClinician',
    invitationEmail: 'users/invitationEmail',
    unassignClinician: 'users/unassignClinician',
  },
  prompts: {
    prompts: 'prompts'
  },
  skills: {
    skills: 'skills'
  },
  review: {
    review: 'review'
  },
  learnings: {
    learnings: 'learnings'
  },
  assignments: {
    assignments: 'assignments',
    reorderAssignment: '/assignments/reorderAssignment',
    patientAssignments:  '/assignments/patientAssignments',
  },
  role: {
    Roles: 'roles',
    adminRoles: 'roles/adminRoles',
    organizationRoles: 'roles/organizationRoles',
  },

  organization: {
    organizations: 'organizations',
  },

  userOrganization: {
    userOrganizations: 'UserOrganizations',
  },

  myTokens: {
    myTokens: 'authentication',
  },

  health: 'Health',

  plans: 'plans',
  subscriptions: 'subscriptions',
  stripes: {
    stripes: 'stripes',
    confirm: 'stripes/confirm',
  },
  recording: {
    recordings: 'recordings',
    uploadRecording: 'recordings/uploadRecordingFile'
  }
};
