import { fill, groupBy, size, sumBy } from 'lodash';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
const logo = require('../../../assets/image/svg/logo.svg').default;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  animation: {
    duration: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: 'rgba(48, 20, 0, 1)',
      },
    },
    y: {
      ticks: {
        color: 'rgba(48, 20, 0, 1)',
        suggestedMin: 0,
      },
      min: 0,
      max: 100,
    },
  },
};

const ReportLearning = (props: any) => {
  const { targetData, reportRef } = props;
  if (!targetData) return null;
  const fullName = `${targetData.firstName} ${targetData.lastName}`.trim();
  const groupData = groupBy(targetData?.learningPlan?.skill?.reviews, (rv: any) => moment(rv.created).startOf('days'));

  const groupDataKeysMap = Object.keys(groupData).sort((a, b) => {
    const dateA: any = new Date(a)
    const dateB: any = new Date(b)
    return dateA - dateB
  })

  const labels = groupDataKeysMap.map(key => moment(key).format('MMM DD'));
  if (size(labels) === 1) labels.push('')
  const datasets = [
    {
      data: fill(Array(size(labels)), targetData?.learningPlan?.targetRating),
      borderColor: 'rgba(0, 146, 255, 1)',
      pointRadius: 0,
      borderWidth: 4,

    },
    {
      data: Object.values(groupData).sort((a, b) => {
        const dateA: any = new Date(a[0].created)
        const dateB: any = new Date(b[0].created)
        return dateA - dateB
      }).map(value => sumBy(value, 'rating') / size(value)),
      borderColor: 'rgba(186, 26, 26, 1)',
      pointBackgroundColor: 'rgba(186, 26, 26, 1)',
      pointRadius: 5,
      borderWidth: 4,
    },
  ];

  return (
    <div className="text-xs h-0 overflow-hidden">
      <div className="p-8 text-on-primary-container" ref={reportRef}>
        <div className="flex justify-between items-center">
          <div className="text-xl font-semibold">PROGRESS REPORT</div>
          <img alt="MileMarker logo" className="h-12" src={logo} />
        </div>
        <div className="flex mt-2">
          <div className="w-3/5">
            <div className="flex">
              <p className="w-24 text-outline">{fullName ? 'Name' : 'Email'}</p>
              <p className="w-full flex-1 border-b border-dashed border-on-primary-container">{fullName || targetData.emailAddress}</p>
            </div>
            <div className="flex mt-1">
              <p className="w-24 text-outline">Patient ID</p>
              <p className="w-full flex-1 border-b border-dashed border-on-primary-container">{targetData.id}</p>
            </div>
          </div>
          <div className="w-2/5">
            <div className="ml-8 flex">
              <p className="w-28 text-outline">Date of Birth</p>
              <p className="w-full flex-1 border-b border-dashed border-on-primary-container">
                {targetData?.dateOfBirth ? moment(targetData?.dateOfBirth).format('MMMM DD, YYYY') : ''}
              </p>
            </div>
            <div className="ml-8 flex mt-1">
              <p className="w-28 text-outline">Report Date</p>
              <p className="w-full flex-1 border-b border-dashed border-on-primary-container">{moment().format('MMMM DD, YYYY')}</p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="w-full bg-hover-5 flex justify-between items-center h-10 px-2.5">
            <div className="flex items-center">
              <div className="w-8 h-4 bg-error mr-2.5 pt-0.5" />
              <span>Skill: /{targetData.learningPlan?.skill?.name}/</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-error mr-1 pt-0.5" />
              <span>Target Score</span> <span className="pl-2.5 text-error">{targetData.learningPlan?.targetRating}</span>
            </div>
          </div>
          <div className="p-3" style={{ width: '720px' }}>
            <Line options={options} data={{ labels, datasets }} />
          </div>
          <div className="p-3">
            <p className="font-semibold text-primary">Comments:</p>
            {groupDataKeysMap.map((key, index) => (
              <div className="mt-2" key={`cm-${index + 1}`}>
                <span className="font-semibold">{moment(key).format('MM/DD/YYYY')}: </span>
                {groupData[key].map(value => value.notes).join('; ')}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportLearning;
