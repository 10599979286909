import { Datepicker } from 'flowbite-react';
import { useEffect, useState } from 'react';
import './style.scss';
import { themDatepicker } from 'utils/theme';
import moment from 'moment';

const DatePicker = (props: any) => {
    const { name, placeholder, hookForm, isHolderLabel, handleSelectDate, dateValue, className = '' } = props;
    const {
        formState: { errors },
        register,
        getValues,
    } = hookForm;

    const [focus, setFocus] = useState(!!getValues(name));
    const handleSelect = (date: any) => {
        handleSelectDate(date);
        setFocus(true);
    };

    useEffect(() => {
        if (dateValue) {
            setFocus(true)
        }
    }, [dateValue])

    return (
        <div className={`flex-1 datepicker relative ${className}`}>
            {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
            {/* eslint-disable-next-line  */}
            <Datepicker className={`date-style ${errors[name] ? 'error' : ''} `}  {...register(name)}
                showTodayButton={false}
                autoHide={true}
                showClearButton={false}
                onSelectedDateChanged={(date: Date) => handleSelect(date)}
                value={dateValue}
                defaultDate={dateValue ? new Date(dateValue) : undefined}
            />
            {errors[name] && <div className={`text-red-600 text-xs font-normal mt-2 veri-modal height-16`}>{errors[name]?.message}</div>}
        </div>
    );
};

export default DatePicker;
