import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from '../redux/selectors';
import { Spinner } from 'flowbite-react';
import { roleName } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import PatientDashboard from './dashboard/patient/patientDashboard';
import ClinicianDashboard from './dashboard/clinician/clinicianDashboard';
import { HiOfficeBuilding } from 'react-icons/hi';

const DashboardPage = () => {
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const organization = userInfo?.organizations?.find((o: any) => o.id === organizationId && !!o.role);
  const isSlp = [roleName.SLP, roleName.ORGANIZATION_ADMIN].includes(organization?.role?.displayName);
  const isPatient = organization?.role?.displayName === roleName.PATIENTS;

  if (!userInfo?.id) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }
  return isPatient ? (
    <PatientDashboard userInfo={userInfo} />
  ) : isSlp ? (
    <ClinicianDashboard />
  ) : (
    <div>
      {userInfo?.organizations.length === 0 && (
        <div className="bg-white border border-gray-200 rounded-sm flex flex-col items-center p-8 m-8">
          <div className="mb-4 flex justify-center">
            <HiOfficeBuilding className="text-outline-variant h-[74px] w-auto" />
          </div>
          <p className="text-xl font-semibold text-center text-on-primary-container mb-1">{t('dashboard.notification')}</p>
          <p className="text-base text-outline font-normal text-center">{t('dashboard.note')}</p>
        </div>
      )}
    </div>
  );
};
export default DashboardPage;
