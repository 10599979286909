import { getPatientAssignments } from "api/assignmentApi";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { orgSelector, userSelector } from "redux/selectors";
import './assignmentsPage.scss'
import { initAssignment, initOrganization } from 'utils/initData';
import ActionTable from "components/table/actionTable";
import NoResult from "components/commonComponent/noResult";
import SpinnerComponent from "components/spinner";
import { assignmentStatus, pageCount, TypeAssignment } from "utils/constants";
import moment from "moment";
import SkillItem from "components/commonComponent/skillItem";
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import PaginateTable from "components/table/paginate";
import UploadRecording from "./uploadRecording";
import AssignmentsEmpty from "components/empty/assignmentsEmpty";

const CurrentAssignmentList = () => {
    const { userInfo } = useSelector(userSelector);
    const [currentAssignmentsData, setAssignmentsData] = useState<any>([])
    const [totalEntities, setTotalEntities] = useState(0);
    const [isShowNoAssignment, setIsShowNoAssignment] = useState(false)
    const [searchValue, setSearchValue] = useState('');
    const [isCollapse, setIsCollapse] = useState<any>({})
    const [currentPage, setCurrentPage] = useState(0);
    const { organizationId } = useSelector(orgSelector);

    const { data: currentAssignments, isLoading } = useQuery(['getCurrentAssignment', userInfo?.id, currentPage, searchValue, organizationId], () => getPatientAssignments(
        { page: currentPage, limit: pageCount, userId: userInfo?.id, searchQuery: searchValue, type: TypeAssignment.CURRENT }
    ),
        {
            retry: false,
            staleTime: Infinity,
            onSuccess: (data: any) => {
            },

        });
    const [t] = useTranslation()
    const handleCollapse = (id: string) => {
        const temp = {
            ...isCollapse,
            [id]: !isCollapse?.[id]
        }
        setIsCollapse(temp)
    }

    const handleSearch = (value: string) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    useEffect(() => {
        if (currentAssignments !== undefined) {
            // setAssignmentsData(assignments?.data?.entities);
            setAssignmentsData(currentAssignments.data.entities);

            setTotalEntities(currentAssignments?.data.totalEntities);
            if (currentAssignments?.data.entities.length > 0) {
                setIsShowNoAssignment(false)
            } else if (searchValue === '') setIsShowNoAssignment(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAssignments]);

    return (
        <div className="flex flex-col h-full">
            <div className="grow">
                {!isEqual(currentAssignmentsData[0], initAssignment) && totalEntities === 0 && currentAssignmentsData?.length === 0 && isShowNoAssignment && searchValue === '' && !isLoading ? (
                    <div className="px-5">
                        <AssignmentsEmpty title={t('titleYouAssignmentEmpty')} note={t('noteSlpAssignmentEmpty')} />
                    </div>
                ) : (
                    <div className='flex flex-col h-full organization-clinicians-container px-5'>
                        <div className="w-full organization-clinicians grow">
                            <div className='search-input'>
                                <ActionTable
                                    placeholderSearch="Search"
                                    buttonName="Add Assignment"
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    handleSearch={handleSearch}
                                />
                            </div>
                            {!isEqual(currentAssignmentsData[0], initAssignment) && currentAssignmentsData.length === 0 && isShowNoAssignment && !isLoading && <NoResult />}
                            {!isEqual(currentAssignmentsData[0], initAssignment) && currentAssignmentsData.length > 0 && (
                                <div className="relative">
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32 assignment-table-style">
                                        <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                                            <tr className='w-full'>
                                                <th scope="col" className="font-medium py-3 px-4">
                                                    {t('assignment.prompt')}
                                                </th>
                                                <th scope="col" className="hidden-column-custom font-medium  py-3 px-4 w-22per">
                                                    {t('assignment.dueDate')}
                                                </th>
                                                <th scope="col" className="hidden-mobile  font-medium py-3 px-4 w-22per">
                                                    {t('assignment.status')}
                                                </th>
                                                <th scope="col" className="font-medium py-3 w-9per" />
                                            </tr>
                                        </thead>
                                        {currentAssignmentsData.map((item: any, index: number) => {
                                            return (
                                                <>
                                                    <tr
                                                        onClick={() => handleCollapse(item?.id)}
                                                        key={`${item.id}-list-organization`}
                                                        className="border-b dark:bg-gray-800 dark:border-gray-700  hover:bg-hover-5 dark:hover:bg-gray-600"
                                                    >
                                                        <td className="py-2 px-4 font-medium text-on-primary-container dark:text-white cursor-pointer">
                                                            <div className="flex items-center">
                                                                <div className="bg-text-id flex items-center justify-center rounded-full min-w-9 h-9 w-9">
                                                                    <div className="border border-2 border-circle-primary rounded-full min-w-30 h-30 w-30 bg-text-id">
                                                                        {''}
                                                                    </div>
                                                                </div>
                                                                <div className='text-on-primary-container flex flex-row items-center justify-center pl-2 '>
                                                                    <p className=''>
                                                                        {item?.prompt?.text}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td className=" w-22per hidden-column-custom text-on-primary-container py-2 px-4">
                                                            {item?.date ? moment(item?.date).format('MMMM D, YYYY') : ''}
                                                        </td>
                                                        <td className=" w-22per hidden-mobile text-primary py-2 px-4">
                                                            {item.status === assignmentStatus.NOT_STARTED && (
                                                                <aside className="flex flex-row items-center">
                                                                    <span className="not-started-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                                                    <p className='not-started-status-text font-extralight'>
                                                                        {t('status.notStarted')}
                                                                    </p>
                                                                </aside>

                                                            )}
                                                            {item.status === assignmentStatus.COMPLETED && (
                                                                <aside className="flex flex-row items-center">
                                                                    <span className="completed-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                                                    <p className='completed-status-text font-extralight'>
                                                                        {t('status.completed')}
                                                                    </p>
                                                                </aside>
                                                            )}     {item.status === assignmentStatus.READY_FOR_REVIEW && (
                                                                <aside className="flex flex-row items-center">
                                                                    <span className="ready-for-review-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                                                    <p className='ready-for-review-status-text font-extralight'>
                                                                        {t('status.readyForReview')}
                                                                    </p>
                                                                </aside>
                                                            )}
                                                        </td>
                                                        <td className="font-medium py-3 w-9per">
                                                            <div className="flex justify-end mr-5">
                                                                {!isCollapse?.[item.id] ? (<HiChevronDown className="w-6 h-6" />) : (<HiChevronUp className="w-6 h-6" />)}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {isCollapse?.[item.id] && (
                                                        <tr className="border-b ">
                                                            <td colSpan={6}  >
                                                                <div className="flex py-5 w-full">
                                                                    <UploadRecording assignment={item} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </table>
                                </div>
                            )}
                            {isLoading && !isEqual(currentAssignmentsData[0], initOrganization) && currentAssignmentsData?.length > 0 && <SpinnerComponent />}
                        </div>
                    </div>)}
            </div>
            {totalEntities > 0 && (
                <div className='px-5 mt-5'>
                    <PaginateTable
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        totalEntities={totalEntities}
                    />
                </div>
            )}
        </div>
    )
}

export default CurrentAssignmentList