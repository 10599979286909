import { HiArrowNarrowLeft } from 'react-icons/hi';
import './buttonBack.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ButtonBack = (props: any) => {
  const { buttonName } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <div className="back-to-previous-page flex items-center ">
      <div
        className="arrow-left-icon mr-4 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <HiArrowNarrowLeft className="w-7 h-5 text-outline" />
      </div>
      <p className='text-outline'>{buttonName} </p>
    </div>
  );
};
export default ButtonBack;
