import PaginateTable from 'components/table/paginate';
import { pageCount, roleName, routePaths } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import ActionTable from 'components/table/actionTable';
import './patientList.scss';
import SpinnerComponent from 'components/spinner';
import { getMyPatients, getOrgUsers } from 'api/userApi';
import { isEmpty, orderBy, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import Table from 'components/table/table';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { useNavigate } from 'react-router-dom';
import LabelUser from 'components/labelUser';
import PatientsEmpty from 'components/empty/patientsEmpty';
import OrganizationPatientModal from 'components/modal/organizationPatientModal';

const PatientListPage = (props: any) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [patients, setPatients] = useState([]);
  const { organizationId } = useSelector(orgSelector);
  const { userInfo } = useSelector(userSelector);
  const [openModal, setOpenModal] = useState(false);
  const orgRole = userInfo?.organizations.find((org: any) => org.id === organizationId)?.role?.displayName;
  const { isLoading, data } = useQuery(
    ['getPatients', currentPage, searchValue, organizationId],
    () =>
      orgRole === roleName.ORGANIZATION_ADMIN
        ? getOrgUsers({ page: 0, limit: 0, roleNames: roleName.PATIENTS })
        : getMyPatients({ page: currentPage, limit: pageCount, searchQuery: searchValue }),
    {
      onSuccess: ({ data }) => {
        setPatients(data.entities);
        setTotalEntities(data.totalEntities);
      },
      onError: () => setPatients([]),
      staleTime: Infinity,
    },
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (data !== undefined) {
      setPatients(data?.data?.entities);
      setTotalEntities(data?.data?.totalEntities);
    }
  }, [data]);

  const handleViewDetail = (patientId: string) => {
    navigate(`${routePaths.SLP_PATIENTS}/${patientId}`);
  };
  const handleAddPatient = () => {
    setOpenModal(true);
  };

  const addModal: any = () => {
    return (
      <OrganizationPatientModal
        headerTitle={t('organizationPatients.addPatient')}
        openModal={openModal}
        setOpenModal={setOpenModal}
        queryClient={queryClient}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        isAssign
      />
    );
  };

  if (!isLoading && isEmpty(patients) && totalEntities === 0) {
    return (
      <div className="w-full slp-patient sm:p-8 p-4">
        <PatientsEmpty note={t('noteSlpPatientEmpty')} title={t('titleSlpPatientEmpty')} buttonName="Add Patient" handleAddClick={handleAddPatient} />
        {addModal()}
      </div>
    );
  }

  return (
    <div className="w-full slp-patient sm:px-8 px-4">
      <div>
        <ActionTable placeholderSearch={t('search')} buttonName="Add Patient" handleSearch={handleSearch} handleAddClick={handleAddPatient} />
      </div>
      {isLoading && <SpinnerComponent />}
      {totalEntities === 0 && !isLoading && <NoResult />}
      {totalEntities > 0 && (
        <>
          <div className="overflow-x-auto mb-2 list-table">
            <Table>
              <Table.Head>
                <Table.HeadCell className="cursor-pointer">
                  <div className="flex items-center">{t('patient.fullName')}</div>
                </Table.HeadCell>
                <Table.HeadCell className="hidden-mobile-tablet">
                  <div className="flex items-center">{t('patient.currentAssignment')}</div>
                </Table.HeadCell>
                <Table.HeadCell className="hidden-mobile-tablet">
                  <div className="flex items-center">{t('patient.nextDueDate')}</div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {patients.map((item: any, index) => {
                  const assignments = item?.assignments?.filter((ass: any) => moment(ass.date).startOf('days') >= moment().startOf('days'));
                  const assignmentsUniq = uniqBy(assignments, 'date');

                  const [current, next]: any = orderBy(assignmentsUniq, (ass: any) => moment(ass.date));
                  return (
                    <Table.Row
                      onClick={() => handleViewDetail(item.id)}
                      key={`row-${index + 1}`}
                      className="bg-white text-base hover:border-b text-on-primary-container"
                    >
                      <Table.Cell className="font-medium py-2.5 flex flex-row items-center">
                        <LabelUser item={item} />
                      </Table.Cell>
                      <Table.Cell className="text-sm hidden-mobile py-2.5">{current ? moment(current?.date).format('MMMM DD, YYYY') : ''}</Table.Cell>
                      <Table.Cell className="text-sm hidden-mobile py-2.5">{next ? moment(next?.date).format('MMMM DD, YYYY') : ''}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
          {addModal()}
        </>
      )}
    </div>
  );
};
export default PatientListPage;
