const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

const roleName = {
  SITE_ADMIN: 'Site Admin',
  ORGANIZATION_ADMIN: 'Organization Admin',
  STANDARD_USER: 'Standard User',
  INTEGRATOR: 'Integrator',
  SLP: 'SLP',
  CLINICIAN: 'Clinicians',
  PATIENTS: 'Patients'
};

const roleOptions = [
  { label: 'Site Admin', value: roleName.SITE_ADMIN },
  { label: 'Organization Admin', value: roleName.ORGANIZATION_ADMIN },
  { label: 'Standard User', value: roleName.STANDARD_USER },
];

const routePaths = {
  FILE_REVIEW_PAGE: '/review/:id',
  LOGIN_PAGE: '/login',
  VERSION_PAGE: '/version',
  LOGOUT_PAGE: '/logout',
  NOT_FOUND_PAGE: '/not-found',
  CONTACT_SUPPORT: '/contact-support',
  PROFILE_PAGE: '/profile',
  UPDATE_PROFILE: '/update-profile',
  ORGANIZATION_PAGE: '/admin/organization',
  ORG_OVERVIEW_PAGE: '/organization/overview',
  ORG_PATIENTS: '/organization/patients',
  ROLES_PAGE: '/admin/roles',
  ADMIN_USERS_PAGE: '/admin/users',
  ADMIN_USERS_DETAIL: '/admin/users/:id',
  DASHBOARD_PAGE: '/',
  EMAIL_VERIFICATION: '/email-verification',
  SUBSCRIPTION: '/subscription',
  STRIPE_PAYMENT: '/stripe-payment',
  ORG_CLINICIANS: '/organization/clinicians',
  ORG_CLINICIANS_DETAIL: '/organization/clinicians/:id',
  PROMPTS_PAGE: '/admin/prompts',
  SKILLS_PAGE: '/admin/skills',
  ORG_PATIENTS_DETAIL: '/organization/patients/:id',
  SLP_PATIENT: '/slp/patient',
  SLP_PATIENT_PROFILE: '/slp/patients/:id',
  SLP_PATIENTS: '/slp/patients',
  SLP_ASSIGNMENTS: '/slp/assignments',
  PATIENT_ASSIGNMENTS: '/patient/assignments',
  PATIENT_REPORT_TREND_DATA: 'patients/report-trend-data',
  SIGNUP: '/signup',
  PATIENT_LEARNING_PLAN: '/patient/learning-plan'
};

const status = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const assignmentStatus = {
  NOT_STARTED: 'Not Started',
  READY_FOR_REVIEW:'Ready For Review',
  COMPLETED: 'Completed',
}

const apiStatus = {
  SUCCESS: 200,
  NO_CONTENT: 204,
};

const pageCount = 10;

const exportFileName = {
  ORGANIZATION: 'ListOrganization.csv',
  USER: ' ListUser.csv',
  USER_ORGANIZATION: 'UserOrganization.csv',
  TOKENS: 'Tokens.csv',
  ROLE: 'Role.csv',
};

const roles = ['admin', 'user', 'super-admin'];

const tokenKey = 'token';

const searchMaxLength = 500;

const delayShowToast = 2000;

const acceptImageTypeFile = '.png, .jpeg, .jpg, .gif';

const BUTTON_COLOR = {
  BLUE: 'bg-blue-600 hover:bg-blue-700',
  RED: 'bg-red-500 hover:bg-red-800',
  GRAY: 'bg-gray-300 hover:bg-gray-500',
  OUTLINE: 'bg-white border border-gray-200 hover:bg-blue-100 hover:border-blue-600',
};

const page_default = 0;
const limit_default = 1000;

const INTERVAL = {
  MONTH: 'month',
  YEAR: 'year',
};

const SUBSCRIPTION_TYPE = {
  BASIC: 'Basic',
  STANDARD: 'Standard',
  ENTERPRISE: 'Enterprise',
};

const stripeSucceeded = 'succeeded';
const roleTypeOptions = [
  { label: 'Site', value: 'Site' },
  { label: 'Organization', value: 'Organization' },
];

const ORG_COOKIE_KEY = 'organization_id';
const IMAGE_TYPE_REGEX = /\.(jpg|jpeg|png|gif)$/i;

const permissionKeys = {
  PLATFORM_ADMIN: 'PLATFORM_ADMIN',
  READ_AUTHEN: 'READ_AUTHEN',
  READ_ORG: 'READ_ORG',
  READ_USER: 'READ_USER',
  READ_ROLE: 'READ_ROLE',
  READ_PLAN: 'READ_PLAN',
  READ_SUBSCRIPTION: 'READ_SUBSCRIPTION',
  READ_PROMPT: 'READ_PROMPT',
  READ_SKILL: 'READ_SKILL',
  WRITE_AUTHEN: 'WRITE_AUTHEN',
  WRITE_ORG: 'WRITE_ORG',
  WRITE_USER: 'WRITE_USER',
  WRITE_ROLE: 'WRITE_ROLE',
  WRITE_PLAN: 'WRITE_PLAN',
  WRITE_SUBSCRIPTION: 'WRITE_SUBSCRIPTION',
  WRITE_PROMPT: 'WRITE_PROMPT',
  WRITE_SKILL: 'WRITE_SKILL',
};

const permissionsSite =[
  permissionKeys.PLATFORM_ADMIN,
  permissionKeys.READ_ORG,
  permissionKeys.READ_USER,
  permissionKeys.READ_ROLE,
  permissionKeys.READ_AUTHEN,
  permissionKeys.WRITE_ORG,
  permissionKeys.WRITE_USER,
  permissionKeys.WRITE_ROLE,
  permissionKeys.WRITE_AUTHEN,
];
const permissionsOrg = [
  permissionKeys.PLATFORM_ADMIN,
  permissionKeys.READ_USER,
  permissionKeys.WRITE_USER,
];

const pageType = {
  CLINICIANS: 'Clinicians',
  SITE: 'Site',
  ORGANIZATION: 'Organization',
  PATIENTS: 'Patients', 
}

const sortByTypeType  = {
  ASC:  'ASC',
  DESC:  'DESC'
}

const assignStatus  = {
  NOT_STARTED:  'NOT_STARTED',
  READY_FOR_REVIEW:  'READY_FOR_REVIEW',
  COMPLETED:  'COMPLETED'
}
  
const TypeAssignment = {
  UPCOMING :'Upcoming',
  CURRENT :'Current',
  PAST :'Past',
}

export {
  httpMethods,
  roleName,
  roleOptions,
  routePaths,
  status,
  roles,
  pageCount,
  apiStatus,
  exportFileName,
  tokenKey,
  searchMaxLength,
  delayShowToast,
  acceptImageTypeFile,
  BUTTON_COLOR,
  page_default,
  limit_default,
  INTERVAL,
  SUBSCRIPTION_TYPE,
  roleTypeOptions,
  ORG_COOKIE_KEY,
  stripeSucceeded,
  IMAGE_TYPE_REGEX,
  permissionKeys,
  pageType,
  permissionsSite,
  permissionsOrg,
  sortByTypeType,
  assignStatus,
  assignmentStatus,
  TypeAssignment,
};
