import { Textarea } from 'flowbite-react';
import { useState } from 'react';
import './style.scss';
import { omit } from 'lodash';
import { themTextarea } from 'utils/theme';

const InputTextarea = (props: any) => {
  const { name, placeholder, hookForm, isHolderLabel, handleKeyPress } = props;
  const {
    formState: { errors },
    register,
    getValues,
  } = hookForm;
  const [focus, setFocus] = useState(!!getValues(name));

  return (
    <div className="flex-1 textarea-text relative">
      {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
      <Textarea
        theme={themTextarea}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(!!getValues(name));
          return register(name).onBlur;
        }}
        rows={3}
        className="flex-1 items-center bg-white"
        color={errors[name] ? 'failure' : 'gray'}
        id={name}
        placeholder={!isHolderLabel ? placeholder || '' : ''}
        onKeyPress={handleKeyPress ? e => handleKeyPress(e) : undefined}
        /* eslint-disable react/jsx-props-no-spreading */
        {...(register ? omit(register(name), 'onBlur') : {})}
        helperText={<span className="text-xs font-normal">{errors?.[name]?.message}</span>}
      />
    </div>
  );
};

export default InputTextarea;
