import { deleteLearning, getLearnings, updateLearning } from 'api/learningApi';
import { HiCheck } from 'react-icons/hi';
import { TbDownload } from 'react-icons/tb';
import Actions from 'components/actions';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import PaginateTable from 'components/table/paginate';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiStatus, pageType } from 'utils/constants';
import { messageErrors } from 'utils/utils';
import AddLearningPlanModal from './addLearningPlanModal';
import './learningPlan.scss';
import ReportLearning from './reportLearning';
import { useReactToPrint } from 'react-to-print';
import SkillItem from 'components/commonComponent/skillItem';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import ActionTable from 'components/table/actionTable';

const LearningPlan = (props: any) => {
  const { patientData, type } = props;

  const { userInfo } = useSelector(userSelector);
  console.log("props", userInfo);
  const { PATIENTS } = pageType
  const params: any = useParams();
  const reportRef: any = useRef();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isEditLearning, setIsEditLearning] = useState(false);
  const [selectedLearning, setSelectedLearning] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const isPatient = type === PATIENTS
  const [t] = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: learningPlans,
    isLoading,
    isFetching,
  } = useQuery(['getLearningPlans', params?.id, currentPage, searchValue], () => getLearnings({ page: currentPage, limit: 5, userId: params?.id ? params?.id : userInfo?.id, searchQuery: searchValue }), {
    retry: false,
    staleTime: Infinity,
    onSuccess: (data: any) => {
      setTotalEntities(data?.data?.totalEntities);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleRemoveLearning = async (learningId: string) => {
    try {
      const data = await deleteLearning(learningId);
      if (data.status === apiStatus.SUCCESS) {
        const message: string = t('learning.deletedLearning');
        toast.success(message);
        queryClient.invalidateQueries('getLearningPlans', { refetchActive: true }, { cancelRefetch: true });
        queryClient.invalidateQueries('getPrompts');
      }
    } catch (e) {
      const message: string = t('generalErrors');
      toast.error(message);
    }
  };

  const handleAddLearning = () => {
    setOpenModal(true);
  };

  const handleEditLearning = (learning: any) => {
    setIsEditLearning(true);
    setOpenModal(true);
    setSelectedLearning(learning);
  };

  const handleCompleted = async (learning: any) => {
    const values = { completedDate: new Date() };
    const updateCompleteDate = await updateLearning(values, learning.id);
    if (updateCompleteDate.status === apiStatus.NO_CONTENT) {
      queryClient.invalidateQueries('getLearningPlans', { refetchActive: true }, { cancelRefetch: true });
    }
  };

  const reactToPrint = useReactToPrint({ content: () => reportRef.current });
  const handlePrint = async (learningPlan: any) => {
    const patient = !isPatient ? patientData : userInfo
    setPrintData({ ...patient, learningPlan });
    setTimeout(() => {
      reactToPrint();
    }, 200);
  };


  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    setTotalEntities(learningPlans?.data?.totalEntities);
  }, [learningPlans]);

  return (
    <div className="py-4 px-8 w-full learning-plan">
      {type !== PATIENTS ? (<div className="flex items-center justify-between">
        <div className="text-on-primary-container font-medium"> {t('learning.learningPlan')}</div>
        <div className="flex justify-between w-auto ">
          <div className=" h-full w-auto flex-row gap-2 action-add">
            <button
              onClick={() => handleAddLearning()}
              className="w-auto px-4 h-34 text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-sm"
            >
              {t('learning.addSkillButton')}
            </button>
          </div>
        </div>
      </div>
      ) : (
        <ActionTable
          placeholderSearch={t('roleManagementPage.placeholderSearch')}
          handleSearch={handleSearch}
        />
      )
      }
      {learningPlans?.data?.entities?.length === 0 && !isLoading && !isFetching && <NoResult />}
      {learningPlans?.data?.entities?.length > 0 && (
        <>
          <div className="relative mt-2">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
              <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                <tr className="w-full">
                  <th scope="col" className="font-medium py-3 px-4 external-id">
                    {t('learning.skill')}
                  </th>
                  <th scope="col" className="hidden-mobile font-medium py-3 px-4 full-name">
                    {t('learning.startDate')}
                  </th>
                  <th scope="col" className="hidden-mobile font-medium py-3 px-4">
                    {t('learning.targetRating')}
                  </th>
                  <th scope="col" className="hidden-mobile-tablet hidden-mobile  font-medium py-3 px-4">
                    {t('learning.lastRating')}
                  </th>
                  <th scope="col" className="hidden-mobile-tablet hidden-mobile font-medium py-3 px-4">
                    {t('learning.goalDate')}
                  </th>
                  <th scope="col" className="font-medium py-3 px-4">
                    {t('learning.completedDate')}
                  </th>
                  <th scope="col" className="font-medium py-3 w-5per" />
                </tr>
              </thead>
              <tbody>
                {learningPlans?.data?.entities?.map((item: any) => {
                  return (
                    <tr
                      // onClick={() => handleViewDetail(item?.id)}
                      key={`${item.id}-list-organization`}
                      className="border-b dark:bg-gray-800 dark:border-gray-700  hover:bg-hover-5 dark:hover:bg-gray-600"
                    >
                      <td className=" text-on-primary-container py-2 px-4">
                        <div className="flex-wrap gap-0.5 dark:text-white font-semibold flex items-center  text-on-primary-container">
                          <SkillItem name={item?.skill?.name} />
                        </div>
                      </td>
                      <td className=" text-on-primary-container hidden-mobile py-2 px-4">{moment(item?.startDate).format('MMMM DD, YYYY')}</td>

                      <td className="hidden-mobile text-on-primary-container py-2 px-4">{item.targetRating}</td>
                      <td className="hidden-mobile-tablet hidden-mobile  text-on-primary-container py-2 px-4">
                        {orderBy(item?.skill?.reviews, ['created'], ['desc'])[0]?.rating}
                      </td>
                      <td className="hidden-mobile-tablet hidden-mobile  text-on-primary-container py-2 px-4">
                        {moment(item?.goalDate).format('MMMM DD, YYYY')}
                      </td>
                      <td className="text-on-primary-container py-2 px-4">
                        {item.completedDate ? (
                          <div className="completed-status-text">{moment(item?.completedDate).format('MMMM DD, YYYY')}</div>
                        ) : (
                          <div>
                            {moment(item?.startDate) < moment() ? (
                              <aside className="flex flex-row items-center">
                                <span className="status-active w-2.5 h-2.5 flex rounded-lg mr-2 " />
                                <p className="status-active-text">{t('learning.inProgress')}</p>
                              </aside>
                            ) : (
                              <aside className="flex flex-row items-center">
                                <span className="not-started-status w-2.5 h-2.5 flex  rounded-lg mr-2 " />
                                <p className="not-started-status-text">{t('learning.notStarted')}</p>
                              </aside>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="py-2">
                        <div className="flex justify-end items-center">
                          {item?.completedDate ? (
                            <HiCheck className="w-5 h-5 p-0.5 mr-3 text-white bg-green-400 rounded-full" />
                          ) : (
                            <button onClick={!isPatient ? () => handleCompleted(item) : undefined} className={`${isPatient ? 'patient-style' : 'slp-style'}`}>
                              <HiCheck className="w-5 h-5 p-0.5 mr-3 text-outline bg-hover-10 rounded-full" />
                            </button>
                          )}
                          {isPatient ? (
                            <Actions>
                              <Actions.Item
                                show
                                hidden={isEmpty(item?.skill?.reviews)}
                                action={() => handlePrint(item)}
                                icon={<TbDownload className="w-5 h-5 text-on-primary-container" strokeWidth={2.5} />}
                              />
                            </Actions>
                          ) : (
                            <Actions>
                              <Actions.Item
                                show
                                hidden={isEmpty(item?.skill?.reviews)}
                                action={() => handlePrint(item)}
                                icon={<TbDownload className="w-5 h-5 text-on-primary-container" strokeWidth={2.5} />}
                              />
                              <>
                                <Actions.Item action={() => handleEditLearning(item)} label={t('edit')} />
                                <Actions.Item action={() => handleRemoveLearning(item.id)} label={t('delete')} />
                              </>
                            </Actions>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isLoading && totalEntities > 0 && <SpinnerComponent />}
          {totalEntities > 0 && (
            <div className="mt-8">
              <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} pageCount={5} />
            </div>
          )}
        </>
      )}
      <ReportLearning targetData={printData} reportRef={reportRef} />
      {openModal && (
        <AddLearningPlanModal
          headerTitle={isEditLearning ? t('learning.editSkill') : t('learning.addSkill')}
          openModal={openModal}
          isEdit={isEditLearning}
          setIsEdit={setIsEditLearning}
          selectedLearning={selectedLearning}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};
export default LearningPlan;
