import { Spinner } from 'flowbite-react';

const Button = (props: any) => {
  const { type, onClick, color, outline, disabled, label, children } = props;
  let className = 'flex flex-col items-center justify-center rounded-sm rounded-full text-sm px-4 h-[34px] ' + (props.className || '');
  if (color) {
    if (!outline) {
      className = className + ` bg-${color} text-white ${disabled ? 'bg-opacity-50' : 'bg-opacity-90 hover:bg-opacity-100'}`;
    } else {
      className = className + ` bg-white border border-${color} text-${color} ${!disabled ? `hover:bg-${color} !hover:bg-opacity-10` : ''}`;
    }
  }

  return (
    <button className={className} type={type || 'button'} onClick={onClick} disabled={disabled}>
      <div className={`${disabled ? 'opacity-0 h-0' : ''}`}>{label || children}</div>
      {disabled && <Spinner className="fill-primary w-4 center" />}
    </button>
  );
};
export default Button;
