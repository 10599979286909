import { Button, Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { routePaths } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

const ContactSupportPage = () => {
  const [t] = useTranslation();
  const navigate = useNavigate()
  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <div className="w-100 self-center ">
          <Card>
            <div className="p-3 text-center">
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-5">
                {t('contactSupport')} <br />
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                {t('yourAccountHasBeenDeactivated')}
              </p>
              <p className="font-normal text-gray-700 dark:text-gray-400 mb-8">
                {t('pleaseContactSupport')}
              </p>
              <div className="flex justify-center">
              <Button color="info" type="button" onClick={() => navigate(routePaths.LOGOUT_PAGE)}>
                {t('logout')}
              </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default ContactSupportPage;
