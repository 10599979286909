import { useTranslation } from 'react-i18next';
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'utils/constants';


const invalidIcon = require('../assets/image/svg/invalid-icon.svg').default;

const InvalidLink = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="flex flex-col self-center items-center justify-center p-50 w-screen bg-white shadow dark:bg-gray-800">
        <img className="w-15 h-15 md:rounded-none  mx-auto" src={invalidIcon} alt="successIcon" />
        <h1 className="mt-50 text-2xl text-red-600 font-medium text-center mt-6 mb-6 tracking-tight dark:text-white">
          {t('changePassword.invalidTitle')}
        </h1>
        <p className="text-base mb-50 text-center">
          {t('changePassword.invalidContent1')}
          <b>{t('changePassword.invalidContent2')}</b>
          {t('changePassword.invalidContent3')}
        </p>
        <Button color="info" type="button" onClick={() => navigate(routePaths.LOGIN_PAGE)}>
          {t('changePassword.loginPage')}
        </Button>
      </div>
    </div>
  );
};
export default InvalidLink;
