import { HiOutlineDuplicate } from 'react-icons/hi';
import Avatar from 'components/GroupAvatar/Avatar';
import { copyToClipboard } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const LabelUser = (props: any) => {
  const [t] = useTranslation();
  const { item, theme, isCoppy = true, noEmail } = props;

  const classFullName = theme?.name || 'text-primary-name';
  const classEmail = theme?.email || 'text-outline';

  const getFullName = (item: any) => `${item.firstName || ''} ${item.lastName || ''}`.trim();

  const handleCopyToClipboard = (e: any, text: any) => {
    copyToClipboard(text, t('patient.copiedName'));
    e.stopPropagation();
  };

  return (
    <div className="flex items-center">
      <Avatar item={item} />
      <div className="flex flex-1 items-center">
        <div>
          <div className="flex mb-0.5 ml-2">
            <div className="flex items-center">
              {getFullName(item) && <div className={`break-word font-semibold text-sm mr-1.5 ${classFullName}`}>{getFullName(item)}</div>}
              {!item.authId && (
                <div className="mr-1.5">
                  <div className="invitation-sent bg-tertiary w-auto">{t('invitationSent')}</div>
                </div>
              )}
              {isCoppy && getFullName(item) && (
                <div className="copy-icon cursor-pointer">
                  <HiOutlineDuplicate size={20} onClick={(e: any) => handleCopyToClipboard(e, getFullName(item))} />
                </div>
              )}
            </div>
          </div>
          {!noEmail && (
            <div className="flex">
              <div className={`ml-2 break-word text-xs font-normal ${classEmail} `}>{item.emailAddress}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelUser;
