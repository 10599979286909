import SelectOrganization from 'components/selectOrg';
import UserProfile from 'components/userProfile';
import { useNavigate } from 'react-router-dom';
import './header.scss'
import { routePaths } from 'utils/constants';
// eslint-disable-next-line


const Header = (props: any) => {
  const logo = require('../assets/image/svg/logo.svg').default;
  const { type } = props
  const navigate = useNavigate();
  return (
    <div className="flex flex-col mt-dashboard-mb sm:flex-row bg-white items-center justify-between py-3 flex-wrap shadow-md header fixed z-10 w-ful hidden-mobile">
      <div className="flex flex-1 justify-between h-8 px-3 items-center">
        <div className='flex'>
          <div className='logo-container'>
            <button
              className="logo pl-3 h-full w-full border-gray-200 pt-4 pb-4 flex items-center cursor-pointer"
              onClick={() => navigate(routePaths.DASHBOARD_PAGE)}
            >
              <img alt="MileMarker logo" className="mr-3 h-9 sm:h-9" src={logo} />
              <span className="ml-1 hidden sm:flex self-center text-white whitespace-nowrap text-lg font-semibold dark:text-white">TRT - Hello Audrey</span>
            </button>
          </div>
          <div className='ml-3 flex items-center'>
            <SelectOrganization type={type} />
          </div>
        </div>
        <div className='user-profile'>
          <UserProfile />
        </div>
      </div>
    </div>
  )
}

export default Header;
