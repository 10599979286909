import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();
const getPrompts = (queryFilter: queryFilter) => {
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);

  const method = methods.GET;

  const url = `${urls.prompts.prompts}/?${params}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createPrompt = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.prompts.prompts}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updatePrompt = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.prompts.prompts}/${payload.id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deletePrompt = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.prompts.prompts}/${id}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

const updatePromptImage = (payload: { id: string, file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.prompts.prompts}/${id}/uploadimage`;
  const response = execute(method, url, { authorization: true }, file);
  return response;
};

export { getPrompts, createPrompt, updatePrompt, deletePrompt, updatePromptImage };
