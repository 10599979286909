import { HiOutlineUser, HiOutlineOfficeBuilding } from 'react-icons/hi';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { isEmpty, size } from 'lodash';
import Avatar from 'components/GroupAvatar/Avatar';

const OrganizationsTab = (props: any) => {
  const { organizations } = props;
  const [t] = useTranslation();

  return (
    <div className="p-8 border border-gray-200 rounded mx-8">
      {isEmpty(organizations) && (
        <div className="h-full pt-2">
          <div className="mb-4 flex justify-center">
            <HiOutlineOfficeBuilding className="w-20 h-20 text-outline-variant" strokeWidth={1} />
          </div>
          <p className="text-xl font-semibold text-center mb-1 text-on-primary-container">{t('userDetail.haveNoOrgNotification')}</p>
          <p className="text-base font-normal text-center mb-2 text-outline">{t('userDetail.note')}</p>
        </div>
      )}
      {!isEmpty(organizations) && (
        <div className="organization w-full grid grid-cols-4 gap-5 justify-start">
          {organizations?.map((item: any) => (
            <div className="organization-item bg-hover-10 rounded-sm flex flex-row p-4">
              <Avatar
                item={item}
                className="w-[60px] h-[60px] text-outline bg-gray-50 border border-gray-200 shadow-sm"
                avatarDefault={<HiOutlineOfficeBuilding className="w-7 h-7 text-outline" />}
              />
              <div className="grow organization-item-infor pl-5">
                <p className="truncate text-lg text-on-primary-container font-semibold mb-1">{item?.name}</p>
                <div className="flex items-center">
                  <HiOutlineUser className="w-4 h-4 text-outline mr-1" strokeWidth={3} />
                  <p className="text-sm text-outline">
                    {size(item?.users)} {size(item?.users) > 1 ? 'Members' : 'Member'}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default OrganizationsTab;
