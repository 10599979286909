import { useTranslation } from 'react-i18next';
import { HiOutlineUpload, HiX } from 'react-icons/hi';
import { acceptImageTypeFile } from 'utils/constants';
import { uploadImageTypes } from 'utils/proptypes';

const FileUpload = (props: uploadImageTypes) => {
  const { handleUploadImage, imgUrl, size = 24, rounded } = props;
  const [t] = useTranslation();
  const style = `w-${size} h-${size} ${rounded ? 'rounded-full' : 'rounded-lg'}`;

  return (
    <label
      htmlFor="dropzone-file"
      className={`${style} overflow-hidden flex flex-col justify-center items-center text-outline bg-hover-5 border border-dashed border-outline-variant hover:bg-opacity-10 font-normal text-xs`}
    >
      {imgUrl && <img className="preview w-full h-24 object-cover rounded-sm" src={imgUrl} alt="avatar" />}
      {!imgUrl && (
        <div className="flex flex-col justify-center items-center cursor-pointer">
          <HiOutlineUpload className="h-6 w-6" />
          <p className="text-on-primary-container mt-1">{t('uploadPicture')}</p>
        </div>
      )}
      <input id="dropzone-file" type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
    </label>
  );
};
export default FileUpload;
