/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { createOrganization, updateOrganizationById } from 'api/organizationApi';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import { toast } from 'react-toastify';
import { handleTrimString, messageErrors } from '../../utils/utils';
import useUser from 'hooks/useUser';
import { TextInput, Modal, Textarea } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import './organizationModal.scss'
import InputText from 'components/inputText';
import InputTextarea from 'components/textarea';
import { themModal } from 'utils/theme';

type organizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle?: string,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number
};
const OrganizationModal = (props: organizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, currentPage, queryClient, setCurrentPage } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);

  const handleAddAndUpdate = async (values: any) => {
    handleTrimString(values)
    if (isEdit) await updateOrganizationById({ id: targetData.id, ...values });
    else await createOrganization({ ...values });
  };

  const mutation = useMutation('organization', {
    mutationFn: handleAddAndUpdate
  });
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const ValidateSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('userManagementPage.requiredField')),
    phone: Yup.string().nullable(true).matches(phoneRegExp, 'Phone number is not valid').transform(value => (value === '' || Number.isNaN(value) ? null : value)),
  });

  const hookForm = useForm({
    mode: "onChange",
    resolver: yupResolver(ValidateSchema)
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset
  } = hookForm

  const onSubmit = (values: any, props: any) => {
    mutation.mutate(values, {
      onSuccess: () => {
        toggleHandler()
        if (currentPage !== 0 && !isEdit) {
          queryClient.invalidateQueries('getOrganizations', { refetchActive: false }, { cancelRefetch: true })
          setCurrentPage(0)
        } else
          queryClient.invalidateQueries('getOrganizations', { refetchActive: true }, { cancelRefetch: true })
        const message: string = isEdit ? t('organizationPage.editSuccess') : t('organizationPage.saveSuccess');
        toast.success(message);
        fetchCurrentUser();
      },
      onError: (error) => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      }
    });
  };

  const toggleHandler = () => {
    setOpenModal(!openModal)
    reset()
  }

  useEffect(() => {
    if (isEdit) {
      const initData = {
        name: targetData.name,
      }
      reset(initData)
    }
    else {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div ref={rootRef}>
      <Modal
        className='modal-organization'
        show={openModal}
        size="md"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}>
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={themModal.body}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${errors?.name && 'border-error'}`}>
              <InputText hookForm={hookForm} placeholder="Organization's Name" name="name" isHolderLabel={true} />
            </div>
            <div className={`${errors?.phone && 'border-error'} pt-4`}>
              <InputText hookForm={hookForm} placeholder="Phone" name="phone" isHolderLabel={true} />
            </div>
            <div className={`${errors?.address && 'border-error'} mb-7 pt-4`}>
              <InputTextarea hookForm={hookForm} placeholder="Address" name="address" isHolderLabel={true} />
            </div>
            <GroupButton
              className="items-center justify-center pt-2 pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutation.isLoading,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal >
    </div>
  );
};
export default OrganizationModal;
