import base from './baseApi';
const { urls, methods, execute } = base();

const uploadRecordingFile = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.recording.uploadRecording}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const createRecording = (payload: any) => {
  const method = methods.POST;
  const url = urls.recording.recordings;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

export {uploadRecordingFile, createRecording };
