import { orgTypes } from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import { HiOutlineDuplicate, HiArrowNarrowUp } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOrganizationByFilter, updateOptionalOrganizationById } from 'api/organizationApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import { apiStatus, exportFileName, pageCount, permissionKeys, roleName, routePaths, sortByTypeType } from 'utils/constants';
import OrganizationModal from 'components/modal/organizationModal';
import { isEmpty, isEqual } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import { checkPermission, exportToFile, messageErrors, copyToClipboard, invalidateQueriesUser, handleHeaderSort } from '../../utils/utils';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import Sort from 'components/commonComponent/sort';
import { Tooltip } from 'flowbite-react';
import './organizationList.scss';
import Actions from 'components/actions';

const OrganizationList = (props: any) => {
  const queryClient = useQueryClient();
  const { WRITE_ORG, PLATFORM_ADMIN } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const { handleSetTookie } = useUser();
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [targetOrganization, setTartgetOrganization] = useState(initOrganization);
  const [organizations, setOrganizations] = useState([initOrganization]);
  const [allOrgs, setAllOrgs] = useState([]);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const navigate = useNavigate();

  const handleAddOrganization = () => {
    setTartgetOrganization(initOrganization);
    setOpenModal(true);
    setIsEdit(false);
  };

  const handleClickEditOrganization = (org: orgTypes) => {
    setTartgetOrganization(org);
    setIsEdit(true);
    setOpenModal(true);
  };

  const onRowClickHandler = async (org: orgTypes) => {
    if (checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId)) {
      await handleSetTookie(org.id);
      navigate(routePaths.ORG_OVERVIEW_PAGE);
    }
  };

  const { data, isLoading } = useQuery(
    ['getOrganizations', currentPage, searchValue, sortBy, sortByType],
    () => getOrganizationByFilter({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data?.data.totalEntities);
      },
      onError: () => setOrganizations([initOrganization]),
      staleTime: Infinity,
    },
  );

  const getAllOrgs = useMutation('getAllOrganizations', {
    mutationFn: getOrganizationByFilter,
    onSuccess: ({ data }) => setAllOrgs(data.entities),
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleOnClickExport = () => {
    if (organizations[0] !== initOrganization && organizations?.length > 0) {
      getAllOrgs.mutate({ page: 0, limit: totalEntities, searchQuery: searchValue, sortBy, sortByType });
    }
  };

  const handleActive = async (item: orgTypes) => {
    const { status } = await updateOptionalOrganizationById({ id: item.id, isActive: !item.isActive });
    if (status === apiStatus.NO_CONTENT) {
      invalidateQueriesUser(queryClient);
    }
  };

  const handleCopyToClipboard = (e: any, item: any) => {
    copyToClipboard(item.name, t('organizationPage.copiedName'));
    e.stopPropagation();
  };

  useEffect(() => {
    if (!isEmpty(allOrgs)) {
      const exportedData = allOrgs.map((or: any) => ({
        Organization: or.name,
        Clinician: or?.userClinicians?.map((c: any) => `${c?.user.firstName} ${c?.user.lastName}`).toString(),
        NumberOfClinician: or.cliniciansCount,
        Status: or.isActive ? t('organizationPage.active') : t('organizationPage.inactive'),
      }));

      exportToFile(exportedData, exportFileName.ORGANIZATION);
    }

    // eslint-disable-next-line
  }, [allOrgs]);

  useEffect(() => {
    if (data !== undefined) {
      setOrganizations(data?.data?.entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="flex flex-col h-full organization-container px-5">
      <div className="w-full organization  grow">
        <div>
          <ActionTable
            placeholderSearch="Search"
            buttonName="Add Organization"
            handleAddClick={checkPermission(userInfo, props.type, [WRITE_ORG], organizationId) && handleAddOrganization}
            handleSearch={handleSearch}
            exportHandler={handleOnClickExport}
          />
        </div>
        {!isEqual(organizations[0], initOrganization) && totalEntities === 0 && <NoResult />}
        {!isEqual(organizations[0], initOrganization) && organizations?.length > 0 && (
          <div className="relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
              <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                <tr className="w-full">
                  <th scope="col" className=" font-medium py-3 px-4 w-26per">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleHeaderSort('name', sortByType, sortBy, setSortBy, setSortByType);
                      }}
                    >
                      <div className="font-medium items-center mr-1 ">{t('organizationPage.organization')}</div>
                      <Sort check={sortBy === 'name'} sortByType={sortByType} />
                    </div>
                  </th>
                  <th scope="col" className="hidden-mobile-tablet font-medium py-3 px-4">
                    {t('organizationPage.clinician')}
                  </th>
                  <th scope="col" className="hidden-mobile-tablet font-medium py-3 px-4">
                    {t('organizationPage.countOfClinician')}
                  </th>
                  <th scope="col" className="hidden-mobile font-medium py-3 px-4">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleHeaderSort('isActive', sortByType, sortBy, setSortBy, setSortByType);
                      }}
                    >
                      <div className=" font-medium items-center mr-1 ">{t('organizationPage.status')}</div>
                      <Sort check={sortBy === 'isActive'} sortByType={sortByType} />
                    </div>
                  </th>
                  <th scope="col" className="font-medium py-3 w-6" />
                </tr>
              </thead>
              <tbody>
                {organizations.map((item: orgTypes) => {
                  return (
                    <tr
                      key={`${item.id}-list-organization`}
                      className="border-b dark:bg-gray-800 hover:bg-hover-5 dark:border-gray-700 dark:hover:bg-gray-600"
                    >
                      <td onClick={() => onRowClickHandler(item)} className="py-2 px-4 font-medium text-gray-900 dark:text-white cursor-pointer">
                        <div className="flex text-on-primary-container flex-row items-center">
                          <div className="organization-name">
                            <Tooltip content={item?.name} placement="top" className="w-full">
                              <div className="truncate ">{item.name}</div>
                            </Tooltip>
                          </div>
                          <div className="copy-icon pl-3 cursor-pointer">
                            <HiOutlineDuplicate size={20} onClick={e => handleCopyToClipboard(e, item)} />
                          </div>
                        </div>
                      </td>
                      <td className="hidden-mobile-tablet py-2 px-4 font-medium text-gray-900 dark:text-white cursor-pointer">
                        <GroupAvatar items={item?.userClinicians?.map((uo: any) => ({ ...uo.user, url: uo.user?.image?.url }))} />
                      </td>
                      <td className="hidden-mobile-tablet text-on-primary-container py-2 px-4">{item?.cliniciansCount}</td>
                      <td className="hidden-mobile py-2 px-4">
                        {item.isActive ? (
                          <aside className="flex flex-row items-center">
                            <span className="status-active w-3 h-3 flex  rounded-lg mr-2 " />
                            <p className="status-active-text">{t('organizationPage.active')}</p>
                          </aside>
                        ) : (
                          <aside className="flex flex-row items-center">
                            <span className="status-inactive w-3 h-3 flex bg-red-500 rounded-lg mr-2 " />
                            <p className="status-inactive-text">{t('organizationPage.inactive')}</p>
                          </aside>
                        )}
                      </td>
                      <td className="py-2">
                        {checkPermission(userInfo, props.type, [WRITE_ORG], organizationId) && (
                          <Actions>
                            <Actions.Item action={() => onRowClickHandler(item)} label={t('organizationPage.overview')} />
                            <Actions.Item action={() => handleClickEditOrganization(item)} label={t('userManagementPage.edit')} />
                            <Actions.Item
                              action={() => handleActive(item)}
                              label={item.isActive ? t('organizationPage.deactivate') : t('organizationPage.activate')}
                            />
                          </Actions>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {isLoading && !isEqual(organizations[0], initOrganization) && organizations?.length > 0 && <SpinnerComponent />}
      </div>
      {totalEntities !== 0 && (
        <div className="">
          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            exportHandler={handleOnClickExport}
          />
        </div>
      )}
      {openModal && (
        <OrganizationModal
          headerTitle={isEdit ? t('organizationPage.editOrganization') : t('organizationPage.addOrganization')}
          openModal={openModal}
          targetData={targetOrganization}
          isEdit={isEdit}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default OrganizationList;
