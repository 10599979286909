import { useTranslation } from 'react-i18next';
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'utils/constants';
import { resendEmailVerification } from 'api/auth0Api';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const EmailVerification = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);

  const resendVertificationEmail = () => {
    resendEmailVerification().then(
      res => {
        if (res) {
          const mesage: string = t('emailVerificationPage.successMessage');
          toast.success(mesage);
        }
      },
      error => {
        toast.error(error);
      },
    );
  };

  useEffect(() => {
    if (userInfo.emailVerified === true) navigate(routePaths.DASHBOARD_PAGE);
    // eslint-disable-next-line
  }, [userInfo]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="flex flex-col self-center items-center justify-center p-50 w-full dark:bg-gray-800">
        <h1 className="mt-30 text-4xl text-blue-600 font-semibold text-center mt-4 mb-3 tracking-tight dark:text-white">
          {t('emailVerificationPage.title')}
        </h1>
        <Button color="info" type="button" onClick={() => resendVertificationEmail()}>
          {t('emailVerificationPage.buttonResend')}
        </Button>
        <div className="mt-2">
          <Button color="info" type="button" onClick={() => navigate(routePaths.LOGOUT_PAGE)}>
            {t('logout')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default EmailVerification;
