/* eslint-disable react/jsx-props-no-spreading */
import CustomModalHeader from 'components/modal/customModalHeader';
import { Modal } from 'flowbite-react';
import Button from 'components/button/button';
import Svg from 'components/svg';

const NoLearning = (props: any) => {
  const { openModal, setOpenModal, tabsRef } = props;
  const toggleHandler = () => {
    setOpenModal(!openModal);
  };
  return (
    <div className="relative">
      <Modal className="modal-add-assignment" show={openModal} size="lg" popup={true} onClose={toggleHandler} dismissible={true}>
        <CustomModalHeader title="" toggle={toggleHandler} />
        <Modal.Body className="modal-body">
          <div className="bg-white rounded-sm flex flex-col items-center text-center p-6">
            <Svg name="prompt-empty" className="h-[56px] w-auto text-outline-variant" />
            <p className="text-on-primary-container font-semibold text-xl mt-7">Currently, there are no appropriate learning plans.</p>
            <p className="text-outline mt-2 text-base">You need to create a learning plan before adding an assignment</p>
            <div className="mt-6 flex justify-center">
              <Button
                color="primary"
                label="Create Learning Plan"
                onClick={() => {
                  toggleHandler();
                  tabsRef.current?.setActiveTab(0)
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default NoLearning;
