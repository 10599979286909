import { userType } from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import { pageCount, apiStatus, permissionKeys, roleName, routePaths } from 'utils/constants';
import { isEmpty } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { checkPermission, copyToClipboard, handleHeaderSort, invalidateQueriesUser } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import { Tooltip } from 'flowbite-react';
import { deleteOrganizationClinician, getOrgUsers } from 'api/userApi';
import '../../../styles/styles.scss';
import OrganizationClinicianModal from './organizationClinicianModal';
import { getOrgRoles } from 'api/rolesApi';
import './organizationClinician.scss';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useUser from 'hooks/useUser';
import Actions from 'components/actions';
import LabelUser from 'components/labelUser';
import CliniciansEmpty from 'components/empty/cliniciansEmpty';
import Sort from 'components/commonComponent/sort';

const OrganizationClinician = (props: any) => {
  const queryClient = useQueryClient();
  const { WRITE_USER } = permissionKeys;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useSelector(orgSelector);
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [targetOrganization, setTartgetOrganization] = useState(initOrganization);
  const [organizationClinician, setOrganizationClinician] = useState([]);
  const { fetchCurrentUser } = useUser();
  const isWrite = checkPermission(userInfo, props.type, [WRITE_USER], organizationId);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const handleAddOrganization = () => {
    setTartgetOrganization(initOrganization);
    setOpenModal(true);
    setIsEdit(false);
  };

  const { data, isLoading, isRefetching } = useQuery(
    ['getOrganizationClinician', currentPage, searchValue, organizationId, sortByType, sortBy],
    () =>
      getOrgUsers({
        page: currentPage,
        limit: pageCount,
        searchQuery: searchValue,
        roleNames: `${roleName.SLP},${roleName.ORGANIZATION_ADMIN}`,
        sortByType,
        sortBy,
        isOrgAdmin: true
      }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data.totalEntities);
      },
      onError: () => setOrganizationClinician([]),
      staleTime: Infinity,
    },
  );

  const { data: roles } = useQuery(['getOrganizationRoles'], () => getOrgRoles(), {
    staleTime: Infinity,
  });

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleRemoveClinician = async (id: string) => {
    try {
      const data = await deleteOrganizationClinician(id);
      if (data.status === apiStatus.NO_CONTENT) {
        const message: string = t('organizationClinicians.deletedSuccess');
        toast.success(message);
        invalidateQueriesUser(queryClient);
        fetchCurrentUser();
      }
    } catch (e) {
      const message: string = t('generalErrors');
      toast.error(message);
    }
  };

  const handleCopyToClipboard = (e: any, item: any, message: any) => {
    copyToClipboard(item, message);
    e.stopPropagation();
  };

  const handleViewDetail = (clinicianId: string) => {
    navigate(`${routePaths.ORG_CLINICIANS}/${clinicianId}`);
  };

  useEffect(() => {
    setIsFetching(true);
    if (data !== undefined) {
      setOrganizationClinician(data?.data?.entities);
      setTotalEntities(data?.data.totalEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setCurrentPage(0)
  }, [organizationId])

  const AddModal: any = () => {
    return (
      openModal && (
        <OrganizationClinicianModal
          headerTitle={isEdit ? t('organizationClinicians.editOrganizationClinician') : t('organizationClinicians.addOrganizationClinician')}
          openModal={openModal}
          targetData={targetOrganization}
          isEdit={isEdit}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          roles={roles}
        />
      )
    );
  };

  if (!isLoading && isEmpty(searchValue) && totalEntities === 0) {
    return (
      <div className="w-full sm:p-8 p-4">
        <CliniciansEmpty buttonName={t('organizationClinicians.addOrganizationClinician')} handleAddClick={handleAddOrganization} />
        <AddModal />
      </div>
    );
  }

  return (
    <div className="w-full sm:px-8 px-4">
      <div>
        <ActionTable
          placeholderSearch="Search"
          buttonName={t('organizationClinicians.addOrganizationClinician')}
          handleAddClick={isWrite && handleAddOrganization}
          handleSearch={handleSearch}
        />
      </div>
      {!isLoading && isEmpty(organizationClinician) && <NoResult />}
      {!isLoading && !isEmpty(organizationClinician) && (
        <>
          <div className="overflow-x-auto mb-2 list-table">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
              <thead className="text-xs border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                <tr className="w-full">
                  <th scope="col" className="font-medium py-3 px-4 external-id">
                    {t('organizationClinicians.userId')}
                  </th>
                  <th scope="col" className="font-medium py-3 px-4">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleHeaderSort('firstName', sortByType, sortBy, setSortBy, setSortByType);
                      }}
                    >
                      <div className="font-medium items-center mr-1 ">
                        {t('organizationClinicians.fullName')}
                      </div>
                      <Sort check={sortBy === 'firstName'} sortByType={sortByType} />
                    </div>
                  </th>
                  <th scope="col" className="hidden-mobile-tablet font-medium	 py-3 px-4">
                    {t('organizationClinicians.role')}
                  </th>
                  <th scope="col" className="hidden-patients-mobile font-medium py-3 px-4 ">
                    {t('organizationClinicians.patients')}
                  </th>
                  <th scope="col" className="font-medium py-3 w-6" />
                </tr>
              </thead>
              <tbody>
                {organizationClinician.map((item: userType) => {
                  const patientList = item?.organizations?.find((org) => org?.role?.displayName === roleName.SLP) ? item?.patients?.map((u: any) => ({ ...u, url: u.image?.url })) :
                    item?.allOrgPatients?.map((u: any) => ({ ...u, url: u.image?.url }))
                  return (
                    <tr
                      onClick={() => handleViewDetail(item?.id)}
                      key={`${item.id}-list-organization`}
                      className="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-hover-5 dark:hover:bg-gray-600 cursor-pointer"
                    >
                      <td className="py-2 px-4 font-medium text-gray-900 dark:text-white cursor-pointer external-id">
                        <div className="flex text-on-primary-container flex-row items-center w-full max-w-full clinician-externalId">
                          <div>
                            <Tooltip content={item?.externalId} placement="top">
                              <p className="truncate clinician-id">{item?.externalId}</p>
                            </Tooltip>
                          </div>
                          <div className="copy-icon  pl-1 cursor-pointer ">
                            <HiOutlineDuplicate
                              size={20}
                              onClick={e => handleCopyToClipboard(e, item?.externalId, t('organizationClinicians.copiedId'))}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="py-2 px-4 flex flex-row itemx-center text-primary-name font-medium text-gray-900 dark:text-white cursor-pointer">
                        <LabelUser item={item} />
                      </td>
                      <td className="hidden-mobile-tablet text-on-primary-container py-2 px-4">
                        {item?.organizations?.map(org => org?.role?.displayName)}
                      </td>
                      <td className="hidden-patients-mobile text-on-primary-container py-2 px-4">
                        <GroupAvatar items={patientList} />
                      </td>
                      <td className="py-2 ">
                        {isWrite && (
                          <Actions>
                            <Actions.Item action={() => handleViewDetail(item?.id)} label={t('organizationClinicians.overview')} />
                            <Actions.Item
                              action={() => handleRemoveClinician(item?.id)}
                              label={t('organizationClinicians.removeMember')}
                            />
                          </Actions>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
        </>
      )}
      <AddModal />
    </div>
  );
};

export default OrganizationClinician;
