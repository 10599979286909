// eslint-disable-next-line
import { assignmentType, orgTypes, userType } from './proptypes';

const initUser: userType = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  roles: [],
  isActive: true,
  created: new Date(),
  externalId: '',
  patients: []
};

const initOrganization: orgTypes = {
  id: '',
  name: '',
  userOrganizations: [],
  isActive: true,
};

const initAssignment: assignmentType = {
  id: '',
  prompt: '',
  skill: [],
  date: '',
  status: ''
}
export { initUser, initOrganization, initAssignment };
