import { tokenKey } from '../utils/constants';
import { getCookie, setCookie } from 'utils/cookie';

export const setToken = (token: string) => {
  setCookie(tokenKey, token);
};

export const getToken = async () => {
  let token = getCookie(tokenKey);

  return token;
};
