import { HiOutlineUserAdd } from 'react-icons/hi';
import PaginateTable from 'components/table/paginate';
import { apiStatus, exportFileName, pageCount, pageType, permissionKeys, routePaths } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAdminUsers, getOrgUsers, updateActiveUserById } from 'api/userApi';
import { initUser } from 'utils/initData';
import { useTranslation } from 'react-i18next';
import { roleType, userType } from 'utils/proptypes';
import UserModal from 'components/modal/userModal';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import SpinnerComponent from 'components/spinner';
import { isEmpty, isEqual } from 'lodash';
import '../../styles/styles.scss';
import { getAdminRoles, getOrgRoles } from 'api/rolesApi';
import { checkPermission, exportToFile, handleHeaderSort, invalidateQueriesUser, messageErrors } from '../../utils/utils';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import './userManagement.scss';
import Actions from 'components/actions';
import { useNavigate } from 'react-router-dom';
import LabelUser from 'components/labelUser';
import Sort from 'components/commonComponent/sort';

const UserManagementPage = (props: any) => {
  const queryClient = useQueryClient();
  const { WRITE_USER } = permissionKeys;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useSelector(orgSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [users, setUsers] = useState([initUser]);
  const [allUsers, setAllUsers] = useState([]);
  const { userInfo } = useSelector(userSelector);
  const [targetUser, setTargetUser] = useState(initUser);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultRoles: [roleType] = [{ displayName: '', id: '', type: '' }];
  const [roleOptions, setRoleOptions] = useState(defaultRoles);
  const [enableToDownload, setEnableToDownload] = useState(false);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const handleClickAddUser = () => {
    setTargetUser(initUser);
    setOpenModal(true);
    setIsEdit(false);
  };

  const getUserByRole = (currentPage: number, pageCount: number, searchValue: string, sortBy: any, sortByType: any) => {
    return (props.type === pageType.SITE ? getAdminUsers : getOrgUsers)({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType });
  };

  const getUsers = useQuery(['getUsers', currentPage, searchValue, props.type, sortBy, sortByType], () => getUserByRole(currentPage, pageCount, searchValue, sortBy, sortByType), {
    onError: () => setUsers([initUser]),
    staleTime: Infinity,
  });

  const getAllUsers = useMutation('getAllUsersBySite', {
    mutationFn: props.type === pageType.SITE ? getAdminUsers : getOrgUsers,
    onSuccess: ({ data }) => setAllUsers(data.entities),
    onError: error => {
      setAllUsers([]);
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const getRoles = useMutation('getRoles', {
    mutationFn: props.type === pageType.SITE ? getAdminRoles : getOrgRoles,
    onSuccess: ({ data }) => setRoleOptions(data),
    onError: () => setRoleOptions(defaultRoles),
  });

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (checkPermission(userInfo, props.type, [WRITE_USER], organizationId)) getRoles.mutate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotalEntities(getUsers.data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    queryClient.invalidateQueries('getUsers');
    // eslint-disable-next-line
  }, [organizationId]);

  useEffect(() => {
    if (getUsers.data !== undefined) {
      setUsers(getUsers.data?.data?.entities);
      setTotalEntities(getUsers.data?.data?.totalEntities);
    }
  }, [getUsers.data]);

  const handleViewDetail = (userId: string, tab?: string) => {
    navigate(`${routePaths.ADMIN_USERS_PAGE}/${userId}`, { state: { tab } });
  };

  const handleActiveUser = async (data: userType) => {
    const response = await updateActiveUserById(data.id, { isActive: !data.isActive });
    if (response.status === apiStatus.NO_CONTENT) {
      invalidateQueriesUser(queryClient);
    }
  };

  const exportUserHandler = () => {
    if (users[0] !== initUser && users?.length > 0) {
      getAllUsers.mutate({ page: 0, limit: totalEntities, searchQuery: searchValue });
    }
    setEnableToDownload(true);
  };

  useEffect(() => {
    if (!isEmpty(allUsers)) {
      const exportedData = allUsers.map((user: userType) => {
        return {
          'First name': user.firstName,
          'Last name': user.lastName,
          Email: user.emailAddress,
          Status: user.isActive,
        };
      });
      if (enableToDownload) {
        exportToFile(exportedData, exportFileName.USER);
      }
      setEnableToDownload(false);
    }
  }, [allUsers, enableToDownload]);

  useEffect(() => {
    setCurrentPage(0);
    queryClient.invalidateQueries('getUsers', { refetchActive: false }, { cancelRefetch: true });
    getRoles.mutate();
    setSearchValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  useEffect(() => {
    getAllUsers.mutate({ page: 0, limit: totalEntities, searchQuery: searchValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, props.type]);

  const renderHeader = () => {
    if (props.type === pageType.SITE) {
      return isEdit ? t('userManagementPage.editUser') : t('userManagementPage.addUser');
    } else return isEdit ? t('userManagementPage.editUserInOrg') : t('userManagementPage.addUserToOrg');
  };

  return (
    <div className="w-full user-management flex flex-col relative h-full  px-5">
      <div className="grow">
        <div>
          <ActionTable
            placeholderSearch="Search"
            buttonName={props.type === pageType.SITE ? t('userManagementPage.addUser') : t('userManagementPage.addUserToOrg')}
            handleAddClick={checkPermission(userInfo, props.type, [WRITE_USER], organizationId) && handleClickAddUser}
            handleSearch={handleSearch}
            exportHandler={exportUserHandler}
          />
        </div>
        {getUsers.isLoading && <SpinnerComponent />}
        {allUsers.length === 0 && props.type === pageType.ORGANIZATION && !getAllUsers.isLoading ? (
          <div className="pt-16">
            <div className="mb-4 flex justify-center">
              <HiOutlineUserAdd size={80} strokeWidth={1} color="#D1D5DB" fontWeight={100} />
            </div>
            <p className="text-2xl font-semibold text-gray-900 text-center mb-4">{t('organizationPage.notification')}</p>
            <p className="text-sm font-normal text-gray-900 text-center	">{t('organizationPage.note')}</p>
          </div>
        ) : (
          <>
            {!isEqual(users[0], initUser) && totalEntities === 0 && !getAllUsers.isLoading && <NoResult />}
            {!isEqual(users[0], initUser) && users?.length > 0 && (
              <div className="relative">
                <table className="w-full text-sm text-left dark:text-gray-400">
                  <thead className=" border-b-2 text-on-primary-container dark:bg-gray-700 dark:text-gray-400">
                    <tr className="text-xs font-medium ">
                      <th scope="col" className="py-2 px-4 !font-medium flex items-center">
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            handleHeaderSort('firstName', sortByType, sortBy, setSortBy, setSortByType);
                          }}
                        >
                          <div className="font-medium items-center mr-1 ">
                            {t('userManagementPage.fullName')}
                          </div>
                          <Sort check={sortBy === 'firstName'} sortByType={sortByType} />
                        </div>
                      </th>
                      <th scope="col" className="py-2 px-4 hidden-mobile-tablet !font-medium">
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            handleHeaderSort('isActive', sortByType, sortBy, setSortBy, setSortByType);
                          }}
                        >
                          <div className="font-medium items-center mr-1 ">
                            {t('userManagementPage.status')}
                          </div>
                          <Sort check={sortBy === 'isActive'} sortByType={sortByType} />
                        </div>
                      </th>
                      {checkPermission(userInfo, props.type, [WRITE_USER], organizationId) && <th scope="col" className="py-2 w-6" />}
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((item: userType, index) => {
                      return (
                        <tr
                          onClick={() => handleViewDetail(item.id)}
                          key={`${index + 1}-list-user`}
                          className="font-medium text-gray-900 bg-white hover:bg-hover border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-hover-5 dark:hover:bg-gray-600"
                        >
                          <td className="py-2 px-4 flex flex-row items-center">
                            <LabelUser item={item} />
                          </td>

                          <td className="hidden-mobile-tablet py-2 px-4">
                            <div className="flex flex-row items-center">
                              {item.isActive ? (
                                <aside className="flex flex-row items-center">
                                  <span className="status-active w-2.5 h-2.5 flex rounded-lg mr-2 " />
                                  <p className="status-active-text">{t('organizationPage.active')}</p>
                                </aside>
                              ) : (
                                <aside className="flex flex-row items-center">
                                  <span className="status-inactive w-2.5 h-2.5 flex bg-red-500 rounded-lg mr-2 " />
                                  <p className="status-inactive-text">{t('organizationPage.inactive')}</p>
                                </aside>
                              )}
                            </div>
                          </td>
                          <td className="py-2 ">
                            <Actions>
                              <Actions.Item action={() => handleViewDetail(item.id)} label={t('action.overview')} />
                              <Actions.Item action={() => handleActiveUser(item)} label={item.isActive ? t('deactivate') : t('activate')} />
                            </Actions>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {getUsers.isLoading && <SpinnerComponent />}
              </div>
            )}
          </>
        )}
      </div>
      {
        totalEntities !== 0 && (
          <div>
            <PaginateTable
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalEntities={totalEntities}
              isLoadingTable={getUsers.isLoading}
              exportHandler={exportUserHandler}
            />
          </div>
        )
      }
      {
        openModal && (
          <UserModal
            headerTitle={renderHeader}
            isEdit={isEdit}
            openModal={openModal}
            setOpenModal={setOpenModal}
            targetData={targetUser}
            successFunc={getUsers.refetch}
            roleOptions={roleOptions}
            type={props.type}
            queryClient={queryClient}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsEdit={setIsEdit}
          />
        )
      }
    </div >
  );
};
export default UserManagementPage;
