import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './global.css';
import './styles/color.scss';
import App from 'App';
import configureAppStore from './redux/store'
import { Provider } from 'react-redux'

const {
  REACT_APP_SENTRY_ENVIRONMENT: sentryEnvironment,
  REACT_APP_PACKAGE_VERSION,
  REACT_APP_PACKAGE_NAME,
} = process.env;

const sentryReleaseVersion = `${REACT_APP_PACKAGE_NAME}@${REACT_APP_PACKAGE_VERSION}`;

if (sentryReleaseVersion && sentryEnvironment && sentryEnvironment !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: sentryEnvironment,
    release: sentryReleaseVersion,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Provider store={configureAppStore()}><App /></Provider>);
