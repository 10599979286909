import { HiOutlineDuplicate } from 'react-icons/hi';
import PaginateTable from 'components/table/paginate';
import { pageCount, pageType, permissionKeys } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import { checkPermission, copyToClipboard, handleHeaderSort, messageErrors } from '../../utils/utils';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import './promptManagement.scss';
import { deletePrompt, getPrompts } from 'api/promptApi';
import Actions from 'components/actions';
import PromptModal from 'components/modal/promptModal';
import SpinnerComponent from 'components/spinner';
import Table from 'components/table/table';
import Sort from 'components/commonComponent/sort';

const PromptManagementPage = (props: any) => {
  const queryClient = useQueryClient();
  const { WRITE_PROMPT } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const { userInfo } = useSelector(userSelector);
  const [targetPrompt, setTargetPrompt] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const isWrite = checkPermission(userInfo, pageType.SITE, [WRITE_PROMPT], organizationId);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [totalEntities, setTotalEntities] = useState(0)
  const [dataPrompts, setDataPrompts] = useState([])

  const handleClickAddUser = () => {
    setOpenModal(true);
    setIsEdit(false);
  };

  const mutDeletePrompt = useMutation('deletePrompt', {
    mutationFn: deletePrompt,
    onSuccess: () => {
      const message: string = t('prompt.deleteSuccess');
      toast.success(message);
      queryClient.invalidateQueries('getPrompts');
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const { isLoading, data } = useQuery(
    ['getPrompts', currentPage, searchValue, sortBy, sortByType],
    () => getPrompts({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    { staleTime: Infinity },
  );
  const getAllPrompts = useQuery(['getAllPrompts', currentPage, searchValue], () => getPrompts({ limit: 0, searchQuery: searchValue, sortBy, sortByType }), {
    staleTime: Infinity,
    enabled: false,
  });

  // const dataPrompts: any = data?.data?.entities || [];
  // const totalEntities: any = data?.data?.totalEntities || 0;

  useEffect(() => {
    if (data !== undefined) {
      setDataPrompts(data?.data?.entities);
      setTotalEntities(data?.data?.totalEntities);
    }
  }, [data]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleCopyToClipboard = (e: any, item: any) => {
    copyToClipboard(item.text, t('prompt.copiedName'));
    e.stopPropagation();
  };

  const clickEditItem = (item: any) => {
    setTargetPrompt(item);
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleOnClickExport = () => getAllPrompts.refetch();

  return (
    <>
      <div className="w-full prompt-page sm:px-8 px-4">
        <div>
          <ActionTable
            placeholderSearch={t('search')}
            buttonName={t('prompt.addPrompt')}
            handleAddClick={isWrite && handleClickAddUser}
            handleSearch={handleSearch}
          />
        </div>
        {isLoading && <SpinnerComponent />}
        {!isLoading && totalEntities === 0 && <NoResult />}
        {totalEntities > 0 && dataPrompts.length > 0 && (
          <>
            <div className="overflow-x-auto mb-2 list-table">
              <Table>
                <Table.Head>
                  <Table.HeadCell>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleHeaderSort('text', sortByType, sortBy, setSortBy, setSortByType);
                      }}
                    >
                      <div className="font-medium items-center mr-1 ">
                        {t('prompt.prompt')}
                      </div>
                      <Sort check={sortBy === 'text'} sortByType={sortByType} />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell className='hidden-mobile'>
                    <div className="flex items-center">{t('prompt.associatedSkills')}</div>
                  </Table.HeadCell>
                  <Table.HeadCell className="w-6">{''}</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {dataPrompts.map((item: any) => (
                    <Table.Row key={`row-${item.id}`} onClick={() => clickEditItem(item)}>
                      <Table.Cell className="font-medium">
                        <div className="flex flex-row items-center cursor-pointer text-black">
                          <div>{item.text}</div>
                          <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                            <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, item)} />
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="text-gray-900 hidden-mobile flex flex-wrap gap-y-1">
                        {item.skills?.map((skill: any) => (
                          <p className="bg-hover-20 py-1.5 px-3 text-on-primary-container mr-4 rounded text-xs">{skill.name}</p>
                        ))}
                      </Table.Cell>
                      <Table.Cell className="">
                        {isWrite && (
                          <Actions>
                            <Actions.Item action={() => clickEditItem(item)} label={t('edit')} />
                            <Actions.Item action={() => mutDeletePrompt.mutate(item.id)} label={t('delete')} />
                          </Actions>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                {isLoading && <SpinnerComponent />}
              </Table>
            </div>
            <PaginateTable
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalEntities={totalEntities}
              exportHandler={handleOnClickExport}
            />
          </>
        )}
      </div>
      {
        openModal && (
          <PromptModal
            headerTitle={isEdit ? t('prompt.addPrompt') : t('prompt.editPrompt')}
            openModal={openModal}
            isEdit={isEdit}
            setOpenModal={setOpenModal}
            queryClient={queryClient}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            targetData={targetPrompt}
          />
        )
      }
    </>
  );
};
export default PromptManagementPage;
