import dayjs from 'dayjs';
import { Slide, toast, TypeOptions } from 'react-toastify';
import { concat, find, flattenDeep, intersection, isArray, isEmpty, map, uniq } from 'lodash';
import * as XLSX from 'xlsx';
import { ORG_COOKIE_KEY, pageType, permissionKeys, routePaths, sortByTypeType } from './constants';
import { getCookie } from './cookie';
import { userType } from './proptypes';
import moment from 'moment';
import copy from 'copy-to-clipboard';

const formatDate = (date: string) => {
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$D > 9 ? $D : '0' + $D}/${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$y} ${
    parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
  }:${splitTime[1]}${parseInt(splitTime[0]) > 12 ? 'pm' : 'am'}`;
};

const checkLowerUpper = (value: string) => {
  return /(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
};

const checkNumberDymbol = (value: string) => {
  return /(?=.*\d)(?=.*\W+).*$/.test(value);
};

const checkLeast8Char = (value: string) => {
  return value.length >= 8;
};

const checkNoSpaces = (value: string) => {
  return /^\S+$/.test(value);
};

const validatiePassword = (value: string) => {
  return checkLowerUpper(value) && checkNumberDymbol(value) && checkLeast8Char(value) && checkNoSpaces(value);
};

const showToast = (key: TypeOptions, message: any) => {
  if (key === 'success' || key === 'error' || key === 'warning') {
    return toast[key](message, { transition: Slide, autoClose: 1500, icon: false });
  }
};

const messageErrors = (error: any, translation: any): string => {
  if ([500, 404].includes(error?.response?.status)) {
    return translation('generalErrors');
  } else {
    const message = error?.response?.data?.errors[0]?.detail;
    return (isArray(message) ? message[0] : message) || translation('generalErrors');
  }
};

const exportToFile = (exportedData: any, fileName: string) => {
  const wb = XLSX.utils.json_to_sheet(exportedData);
  const wbout = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wbout, wb);
  XLSX.writeFile(wbout, fileName);
};

const checkPermission = (user: any, type: any, permNeed: any = [], organizationId = getCookie(ORG_COOKIE_KEY), exception?: any): any => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];

  if (permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) return true;
  if (exception) return false;
  let permOfUser = concat(permissionsSite, permissionsOrg);
  if (type === pageType.ORGANIZATION) permOfUser = permissionsOrg;
  if (type === pageType.SITE) permOfUser = permissionsSite;
  return permOfUser && (isEmpty(permNeed) || !isEmpty(intersection(permOfUser, permNeed)));
};

const checkRole = (user: any, roleNames: any[], organizationId = getCookie(ORG_COOKIE_KEY)): any => {
  const displayName = find(user?.organizations, o => o.id === organizationId)?.role?.displayName || [];
  return roleNames.includes(displayName);
};

const getNavbarUrl = (user: any, type: string, organizationId = getCookie(ORG_COOKIE_KEY)) => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const { READ_USER, WRITE_USER, PLATFORM_ADMIN, READ_ORG, WRITE_ORG, READ_AUTHEN, WRITE_AUTHEN } = permissionKeys;
  const { READ_PROMPT, READ_SKILL, WRITE_PROMPT, WRITE_SKILL, READ_ROLE, WRITE_ROLE } = permissionKeys;
  if (type === pageType.SITE) {
    if (!isEmpty(intersection(permissionsSite, [READ_USER, WRITE_USER, PLATFORM_ADMIN]))) return routePaths.ADMIN_USERS_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ROLE, WRITE_ROLE]))) return routePaths.ROLES_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ORG, WRITE_ORG]))) return routePaths.ORGANIZATION_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_PROMPT, WRITE_PROMPT]))) return routePaths.PROMPTS_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_SKILL, WRITE_SKILL]))) return routePaths.SKILLS_PAGE;
  } else {
    const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];
    if (!isEmpty(intersection(permissionsSite, [PLATFORM_ADMIN]))) return routePaths.ORG_OVERVIEW_PAGE;
    if (!isEmpty(intersection(permissionsOrg, [READ_USER, WRITE_USER]))) return routePaths.ORG_OVERVIEW_PAGE;
  }
  return routePaths.DASHBOARD_PAGE;
};

const getAvatarColor = (user: userType) => {
  const colors = ['#006783', '#5F1416', '#BA1A1A', '#FFB785', '#944A01', '#31C48D', '#16BDCA', '#FACA15'];
  const sumOfDateNumbers = moment(user.created)
    .utc()
    .format('MM DD YYYY hh mm ss')
    .split('')
    .map(Number)
    .reduce((a, b) => {
      return a + b;
    }, 0);
  const pick = sumOfDateNumbers % colors.length;
  return colors[pick];
};

const copyToClipboard = (value: string, notification: string) => {
  copy(value);
  toast.success(notification);
};

const formatAgoDate = (date: any) => {
  const durationWeeks = moment().diff(date,'weeks');
  if (durationWeeks < 1) {
    return moment(date).fromNow();
  } else {
    return moment(date).format('MMM DD, YYYY');
  }
};

const handleTrimString = (values: any) => {
   Object.keys(values).forEach(k => {
      if (typeof values[k] === 'string') {
        values[k] = values[k].trim()
      }
    })
    return values
}

const invalidateQueriesUser = (queryClient: any) => {
  queryClient.invalidateQueries('getUsers');
  queryClient.invalidateQueries('getUserDetail');
  queryClient.invalidateQueries('getOrganizationDetail');
  queryClient.invalidateQueries('getOrganizations');
  queryClient.invalidateQueries('getPatients');
  queryClient.invalidateQueries('getPatientProfile');
  queryClient.invalidateQueries('getOrganizationClinicianDetail');
  queryClient.invalidateQueries('getOrganizationClinician');
  queryClient.invalidateQueries('getOrganizationPatientDetail');
  queryClient.invalidateQueries('getOrganizationPatients');
  queryClient.invalidateQueries('getPatientsOfClinician');
};

  const handleHeaderSort = (field: string, sortByType:string, sortBy:string ,setSortBy:any, setSortByType:any,   ) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC;
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      type = '';
      setSortBy('');
    }
    setSortByType(type);
  };

export {
  formatDate,
  showToast,
  checkLowerUpper,
  checkNumberDymbol,
  checkLeast8Char,
  checkNoSpaces,
  validatiePassword,
  messageErrors,
  exportToFile,
  checkPermission,
  checkRole,
  getNavbarUrl,
  getAvatarColor,
  copyToClipboard,
  formatAgoDate,
  invalidateQueriesUser,
  handleTrimString,
  handleHeaderSort
};
