
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from '../../../utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './addLearningPlanModal.scss';
import { filter, flattenDeep, get, map } from 'lodash';
import DatePicker from 'components/datePicker';
import moment from 'moment';
import { createLearning, updateLearning } from "api/learningApi";
import { useParams } from 'react-router-dom';
import { getSkills } from 'api/skillApi';
import Select from 'components/select';
import InputText from 'components/inputText';

const AddLearningPlanModal = (props: any) => {
    const { openModal, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage, selectedLearning, isEdit, setIsEdit } = props;
    const { fetchCurrentUser } = useUser();
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);
    const [startDate, setStartDate] = useState<any>("")
    const [goalDate, setGoalDate] = useState<any>("")
    const [selectedOption, setSelectedOption] = useState([]);
    const [skillOptions, setSkillOptions] = useState<any>([])
    const params = useParams();


    const { data: skills } = useQuery(['getSkills'], () => getSkills({ limit: 0 }));

    const handleAddLearning = async (values: any) => {
        const convertValues = {
            ...values,
            targetRating: Number(values.targetRating)
        }
        if (isEdit) {
            await updateLearning(convertValues, selectedLearning.id);
        } else await createLearning(convertValues);
    };

    const ValidateSchema = Yup.object().shape({
        startDate: Yup.string().required(t('userManagementPage.requiredField')),
        skillId: Yup.string().required(t('userManagementPage.requiredField')),
        targetRating: Yup.number().min(0, t('learning.validateTargetRatingMin')).max(100, t('learning.validateTargetRatingMax')).transform((value) => Number.isNaN(value) ? null : value)
            .nullable().required(t('userManagementPage.requiredField')),
        goalDate: Yup.string().required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(ValidateSchema),
    });

    const {
        formState: { errors },
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        register,
        setError,
    } = hookForm;

    const handleChange = (options: any) => {
        const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
        setSelectedOption(noDefaultOptions);
        setValue('skillId', options.value);
        if (options.value !== '') {
            clearErrors('skillId');
        } else {
            setError('skillId', {
                type: 'required',
                message: t('userManagementPage.requiredField'),
            });
        }
    };

    const mutation = useMutation('createLearning', {
        mutationFn: handleAddLearning,
        onSuccess: () => {
            toggleHandler();
            if (currentPage !== 0) {
                queryClient.invalidateQueries('getLearningPlans', { refetchActive: false }, { cancelRefetch: true });
                setCurrentPage(0);
            } else queryClient.invalidateQueries('getLearningPlans', { refetchActive: true }, { cancelRefetch: true });
            const message: string = isEdit ? t('learning.editLearningMessage') : t('learning.addLearningMessage');
            toast.success(message);
            queryClient.invalidateQueries('getAssigments');
            queryClient.invalidateQueries('getPrompts');
            fetchCurrentUser();
        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const onSubmit = (value: any, props: any) => {
        const payload = {
            ...value,
            userId: params?.id,
        };

        mutation.mutate(payload);
    };

    const toggleHandler = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
        reset();
    };
    ;
    const handleSelectDate = (date: Date, type: string) => {
        const formatDate: any = moment(date).format();
        const tempDate: any = moment(date).format('MMMM DD, YYYY');

        if (type === 'startDate') {
            setValue('startDate', formatDate);
            setStartDate(tempDate);
            clearErrors('startDate');

        } else {
            setValue('goalDate', formatDate)
            setGoalDate(tempDate);
            clearErrors('goalDate');
        }
    };

    useEffect(() => {
        if (isEdit) {
            reset({
                skillId: selectedLearning?.skill?.id,
                targetRating: selectedLearning?.targetRating,

            });
            const skill = skillOptions?.filter((item: any) => item.value === selectedLearning?.skill?.id);
            setSelectedOption(skill);
            if (selectedLearning?.startDate) {
                const tempDate: any = moment(selectedLearning.startDate).format('MMMM DD, YYYY');
                setValue('startDate', selectedLearning.startDate);
                setStartDate(tempDate);
            }
            if (selectedLearning?.goalDate) {
                const tempDate: any = moment(selectedLearning.goalDate).format('MMMM DD, YYYY');
                setValue('goalDate', selectedLearning.goalDate);
                setGoalDate(tempDate);
            }
        } else reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLearning, isEdit, skillOptions, openModal]);

    useEffect(() => {
        const options = map(get(skills, 'data.entities', []), (s: any) => ({ value: s.id, label: `"${s.name}"` }))
        setSkillOptions(options)
    }, [skills])

    return (
        <div ref={rootRef} className="relative">
            <Modal
                className="modal-add-learning"
                show={openModal}
                size="xl"
                popup={true}
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
                <Modal.Body className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="custom-multi-select mt-2.5 px-5">
                            {((isEdit && selectedOption?.length > 0) || !isEdit) && (
                                <Select
                                    options={skillOptions}
                                    onChange={(option: any) => handleChange(option)}
                                    placeholder="Skill *"
                                    isHolderLabel={true}
                                    value={selectedOption}
                                    errors={errors}
                                    name="skillId"
                                    isMulti={false}
                                    isCustomOption
                                    closeMenuOnSelect
                                />
                            )}
                        </div>
                        <div className="pt-4 px-5">
                            <DatePicker
                                hookForm={hookForm}
                                placeholder="Start Date *"
                                name="startDate"
                                dateValue={startDate}
                                handleSelectDate={(date: Date) => handleSelectDate(date, 'startDate')}
                                isHolderLabel={true}
                            />
                        </div>
                        <div className="pt-4 px-5">
                            <DatePicker
                                hookForm={hookForm}
                                placeholder="Goal Date *"
                                name="goalDate" 
                                dateValue={goalDate}
                                handleSelectDate={(date: Date) => handleSelectDate(date, 'goalDate')}
                                isHolderLabel={true}
                            />
                        </div>
                        <div className="pt-4 px-5">
                            <InputText type="number" hookForm={hookForm} placeholder={'Target Rating *'} name="targetRating" isHolderLabel={true} />
                        </div>

                        <GroupButton
                            className="items-center justify-center pt-5 pb-2"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.save'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default AddLearningPlanModal;
