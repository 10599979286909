/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import FileUpload from 'components/commonComponent/fileUpload';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from '../../utils/utils';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { createPrompt, updatePrompt, updatePromptImage } from 'api/promptApi';
import { IMAGE_TYPE_REGEX } from 'utils/constants';
import { get, isEmpty, map, toLower } from 'lodash';
import InputText from 'components/inputText';
import Select from 'components/select';
import { getSkills } from 'api/skillApi';
import { themModal } from 'utils/theme';
import Button from 'components/button/button';

const PromptModal = (props: any) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, queryClient, setCurrentPage } = props;
  const [imgUrl, setImgUrl] = useState(targetData?.media?.url || '');
  const [file, setFile] = useState<any>();
  const [t] = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState([]);

  const handleAddAndUpdate = async (values: any) => {
    const prompt: any = isEdit ? await updatePrompt({ id: targetData.id, ...values }) : await createPrompt({ ...values });
    if (file?.name) {
      const currentFileName = toLower(file.name);
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      try {
        await updatePromptImage({ id: isEdit ? targetData?.id : prompt?.data?.id, file: formData });
      } catch (error) {}
    }
  };

  const handleError = (error: any) => {
    const message: string = messageErrors(error, t);
    toast.error(message);
  };

  const mutPrompt = useMutation('updatePrompt', { mutationFn: handleAddAndUpdate });
  const { data: skills } = useQuery(['getSkills'], () => getSkills({ limit: 0 }));

  const ValidateSchema = Yup.object().shape({
    text: Yup.string().required(t('requiredField')),
    skillIds: Yup.array().min(1, t('requiredMinSkills')).required(t('requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    clearErrors,
  } = hookForm;

  const onSubmit = (values: any, props: any) => {
    mutPrompt.mutate(values, {
      onSuccess: () => {
        toggleHandler();
        setCurrentPage(0);
        const message: string = isEdit ? t('prompt.editSuccess') : t('prompt.saveSuccess');
        toast.success(message);
        queryClient.invalidateQueries('getPrompts');
        queryClient.invalidateQueries('getSkills');
      },
      onError: handleError,
    });
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  useEffect(() => {
    if (isEdit) {
      const skills: any = map(targetData.skills, (s: any) => ({ value: s.id, label: s.name }));
      const initData = { text: targetData.text, skillIds: map(skills, 'value') };
      reset(initData);
      setSelectedOption(skills);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (option: any) => {
    const skillIds: any = map(option, 'value');
    setValue('skillIds', skillIds);
    if (!isEmpty(skillIds)) clearErrors('skillIds');
    setSelectedOption(option);
  };

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          return;
        }
        if (currentFile.size > 8000000) {
          return;
        }
      }
      setImgUrl(URL.createObjectURL(currentFile));
      currentFile.isUploaded = true;
      setFile(currentFile);
    }
  };
  const handleRemoveImage = (option: any) => {
    setFile('');
    setImgUrl('');
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-prompt"
        show={openModal}
        root={rootRef.current ?? undefined}
        size="4xl"
        onClose={toggleHandler}
        theme={themModal}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={themModal.body}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center">
              <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} />
            </div>
            <div className="mt-4 relative">
              <InputText hookForm={hookForm} placeholder="Prompt Text" name="text" isHolderLabel={true} />
            </div>
            <div className="mt-4 relative">
              <Select
                options={map(get(skills, 'data.entities', []), (s: any) => ({ value: s.id, label: s.name }))}
                onChange={(option: any) => handleChange(option)}
                placeholder="Associated Skills"
                isHolderLabel={true}
                value={selectedOption}
                errors={errors}
                name="skillIds"
                isMulti
              />
            </div>
            <div className="flex justify-center pt-8">
              <Button color="outline" outline onClick={() => toggleHandler()}>
                {t('modal.cancel')}
              </Button>
              <Button className="ml-4" type="submit" color="primary" disabled={mutPrompt.isLoading}>
                {t('modal.save')}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PromptModal;
