import { queryFilter } from 'utils/proptypes';
import base from './baseApi';

const { urls, methods, execute } = base();
const getReviews = (queryFilter: queryFilter) => {
  const { page = 0, limit, searchQuery = '' } = queryFilter;
  const method = methods.GET;

  const url = `${urls.review.review}/?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createReview = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.review.review}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateReview = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.review.review}/${payload.id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteReview = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.review.review}/${id}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

export { getReviews, createReview, updateReview, deleteReview };
