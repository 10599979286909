import {
  HiOutlineOfficeBuilding,
  HiPaperAirplane,
  HiOutlineX,
  HiOutlineUser,
  HiOutlinePhone,
  HiOutlineLocationMarker,
  HiOutlinePencil,
} from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOrganizationById, updateOrganizationImage, updateOptionalOrganizationById } from 'api/organizationApi';
import { useTranslation } from 'react-i18next';
import { permissionKeys } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkPermission, handleTrimString, invalidateQueriesUser, messageErrors } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import './organizationDetail.scss';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputText from 'components/inputText';
import ButtonCustom from 'components/button/button';
import Banner from 'components/banner/banner';
import Button from 'components/button/button';
import Svg from 'components/svg';

const OrganizationDetail = (props: any) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { WRITE_ORG } = permissionKeys;
  const { organizationId } = useSelector(orgSelector);
  const [organization, setOrganization]: any = useState({});
  const { userInfo } = useSelector(userSelector);
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ORG], organizationId);
  const [enableEditName, setEnableEditName] = useState(false);
  const [enableEditAddress, setEnableEditAddress] = useState(false);
  const [enableEditPhone, setEnableEditPhone] = useState(false);

  const { data, refetch } = useQuery(['getOrganizations', organizationId], () => getOrganizationById(organizationId), {
    retry: false,
    staleTime: Infinity,
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const mutaUpdateOrg = useMutation('updateStatusOrganization', {
    mutationFn: updateOptionalOrganizationById,
    onSuccess: () => {
      const message: string = t('organizationPage.editSuccess');
      toast.success(message);
      invalidateQueriesUser(queryClient);
      setEnableEditName(false);
      setEnableEditAddress(false);
      setEnableEditPhone(false);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });
  const mutaOrganizationImage = useMutation('organizationImage', {
    mutationFn: updateOrganizationImage,
  });
  const ValidateSchemaName = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(60, t('organizationModal.maxLength60')),
  });
  const ValidateSchemaAddress = Yup.object().shape({
    address: Yup.string().max(100, t('organizationModal.maxLength100')).nullable(),
  });
  const ValidateSchemaPhone = Yup.object().shape({
    phone: Yup.string().max(10, t('organizationModal.maxLength10')).nullable(),
  });
  const hookFormName: any = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaName) });
  const hookFormAddress: any = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaAddress) });
  const hookFormPhone: any = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaPhone) });

  const onSubmit = (values: any, props: any) => {
    handleTrimString(values)
    mutaUpdateOrg.mutate({ id: data?.data?.id, ...values });
  };

  const handleUploadImage = async (formData: any) => {
    mutaOrganizationImage.mutate({ id: organizationId, file: formData });
  };

  useEffect(() => {
    if (data?.data) {
      setOrganization(data?.data);
      hookFormName.reset({ name: data?.data.name });
      hookFormAddress.reset({ address: data?.data.address });
      hookFormPhone.reset({ phone: data?.data.phone });
    }
    // eslint-disable-next-line
  }, [data, enableEditName, enableEditAddress, enableEditPhone]);

  const ActionEdit: any = ({ change, className = '' }: any) => (
    <Button onClick={change} className={`h-8 w-8 ${className}`} color="gray-100">
      <HiOutlinePencil className="w-4 h-4 text-on-primary-container" />
    </Button>
  );

  const ActionSave: any = ({ change, className = '' }: any) => (
    <div className="flex">
      <Button type="submit" className={`mr-2 h-8 w-8 ${className}`} color="primary">
        <HiPaperAirplane className="w-5 h-5 rotate-90" />
      </Button>
      <Button onClick={change} className={`h-8 w-8 ${className}`} color="outline" outline>
        <HiOutlineX className="w-5 h-5 text-outline" />
      </Button>
    </div>
  );

  const Card: any = ({ children }: any) => <div className="min-h-[48px] pb-4 md:flex">{children}</div>;
  const CardTitle: any = ({ children }: any) => <div className="w-48 h-5 text-outline flex items-center mt-3.5">{children}</div>;
  const CardContent: any = ({ children }: any) => (
    <div className="text-on-primary-container w-full flex flex-1 max-w-[500px] ml-7 md:ml-0">{children}</div>
  );

  useEffect(() => {
    setEnableEditName(false)
    setEnableEditAddress(false)
    setEnableEditPhone(false)
  }, [organizationId])

  return (
    <div className="org-detail w-full">
      <Banner
        targetData={organization}
        isWrite={isWriteOrg}
        handleUpdate={handleUploadImage}
        labelId="Organization Id"
        avatarDefault={<Svg name="building-line" />}
      />
      <div className="md:p-8 p-4">
        <div className="md:p-8 p-4 border border-gray-200 rounded lg:flex justify-between">
          <div className="w-full max-w-2xl lg:mr-8">
            <Card>
              <CardTitle>
                <HiOutlineOfficeBuilding className="w-5 h-5 mr-2" />
                Organizations Name
              </CardTitle>
              <CardContent>
                {!enableEditName && <p className="mt-3 mr-2 font-semibold">{organization.name}</p>}
                {!enableEditName && isWriteOrg && <ActionEdit change={() => setEnableEditName(true)} className="mt-2" />}
                {enableEditName && (
                  <form onSubmit={hookFormName.handleSubmit(onSubmit)} className="md:flex flex-1 mt-1 md:mt-0">
                    <InputText name="name" className="mr-2" hookForm={hookFormName} />
                    <ActionSave change={() => setEnableEditName(false)} className="md:mt-2" />
                  </form>
                )}
              </CardContent>
            </Card>
            <Card>
              <CardTitle>
                <HiOutlineLocationMarker className="w-5 h-5 mr-2" />
                Address
              </CardTitle>
              <CardContent>
                {!enableEditAddress && <p className="mt-3 mr-2 font-semibold">{organization.address}</p>}
                {!enableEditAddress && isWriteOrg && <ActionEdit change={() => setEnableEditAddress(true)} className="mt-2" />}
                {enableEditAddress && (
                  <form onSubmit={hookFormAddress.handleSubmit(onSubmit)} className="md:flex flex-1 mt-1 md:mt-0">
                    <InputText name="address" className="mr-2" hookForm={hookFormAddress} />
                    <ActionSave change={() => setEnableEditAddress(false)} className="md:mt-2" />
                  </form>
                )}
              </CardContent>
            </Card>
          </div>
          <div className="w-full max-w-sm lg:mr-8">
            <Card>
              <CardTitle>
                <HiOutlinePhone className="w-5 h-5 mr-2" />
                Phone
              </CardTitle>
              <CardContent>
                {!enableEditPhone && <p className="mt-3 mr-2">{organization.phone}</p>}
                {!enableEditPhone && isWriteOrg && <ActionEdit change={() => setEnableEditPhone(true)} className="mt-2" />}
                {enableEditPhone && (
                  <form onSubmit={hookFormPhone.handleSubmit(onSubmit)} className="md:flex flex-1 mt-1 md:mt-0">
                    <InputText name="phone" className="mr-2" hookForm={hookFormPhone} />
                    <ActionSave change={() => setEnableEditPhone(false)} className="md:mt-2" />
                  </form>
                )}
              </CardContent>
            </Card>
            <Card>
              <CardTitle>
                <HiOutlineUser className="w-5 h-5 mr-2" />
                {organization.countUser} {t('members')}
              </CardTitle>
              <CardContent>
                <div className='md:mt-1.5 mt-1'><GroupAvatar items={organization?.users?.map((u: any) => ({ ...u, url: u.image?.url }))} /></div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="bg-white w-full p-8 my-8 border border-gray-200 rounded-sm relative">
          <div className="text-xl font-semibold text-gray-900">{t('organizationPage.organization')}</div>
          <div className="sm:flex">
            <div className="flex p-4 bg-hover-10 mr-8 w-72 mt-7">
              <div className="flex items-center justify-center w-15 h-15 bg-outline-variant rounded-full">
                <Svg name='clinicians-icon' className="text-on-surface-variant h-6 w-6" />
              </div>
              <div className="flex justify-center flex-col ml-6">
                <div className="text-lg font-semibold uppercase text-on-primary-container">{t('clinicians')}</div>
                <div className="flex items-center">
                  <HiOutlineUser className="text-gray-500 h-4 w-4 text-outline" />
                  <div className="ml-1 text-gray-500 text-sm text-outline">
                    {organization.countClinicians} {t('members')}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex p-4 bg-hover-10 w-72 mt-7">
              <div className="flex items-center justify-center w-15 h-15 bg-outline-variant rounded-full">
                <Svg name='patients-icon' className="text-on-surface-variant h-6 w-6" />
              </div>

              <div className="flex justify-center flex-col ml-6">
                <div className="text-lg font-semibold uppercase text-on-primary-container">{t('patients')}</div>
                <div className="flex items-center">
                  <HiOutlineUser className="text-gray-500 h-4 w-4 text-outline" />
                  <div className="ml-1 text-gray-500 text-sm text-outline">
                    {organization.countPatients} {t('members')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isWriteOrg && organization.isActive === true && (
          <div className="bg-red-50 w-full mt-8 p-8 rounded-lg flex justify-between">
            <div>
              <div className="text-xl font-semibold text-on-error-container pb-3">Deactivate this organization</div>
              <div className="text-on-error-container">
                All user memberships of this organization will be removed. Once confirmed, this action cannot be undone!
              </div>
            </div>
            <div className="flex items-center justify-end">
              <ButtonCustom color="error" onClick={() => mutaUpdateOrg.mutate({ id: organizationId, isActive: !organization.isActive })}>
                {t('deactivate')}
              </ButtonCustom>
            </div>
          </div>
        )}
        {isWriteOrg && organization.isActive === false && (
          <div className="bg-green-50 w-full mt-8 p-8 rounded-lg flex justify-between">
            <div>
              <div className="text-xl font-semibold text-green-500 pb-3">Activate this organization</div>
              <div className="text-green-500">
                All user memberships to this organization will be active again. Once confirmed, this action can't be undone!
              </div>
            </div>
            <div className="flex items-center justify-end">
              <ButtonCustom color="green-500" onClick={() => mutaUpdateOrg.mutate({ id: organizationId, isActive: !organization.isActive })}>
                {t('activate')}
              </ButtonCustom>
            </div>
          </div>
        )}
      </div>

      {/* <div className="p-8">
        <div className="bg-white w-full p-8 mb-8 border border-gray-200 rounded-sm relative">
          {isWriteOrg && (
            <HiOutlinePencil className="bg-gray-100 h-8 w-8 p-1.5 rounded absolute right-0 top-0 cursor-pointer" onClick={changeStatusPage} />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="lg:grid gap-x-32 lg:grid-cols-5">
              <div className="flex items-center py-1 h-16 col-span-3">
                <HiOutlineOfficeBuilding className="text-gray-500 h-5 w-5 text-outline" />
                <div className="text-gray-500 mx-2 w-40 text-outline">Organizations Name</div>
                {isEdit && <InputText hookForm={hookForm} placeholder="Jese Leos" name="name" />}
                {!isEdit && <div className="mr-2 text-on-primary-container text-base font-semibold">{organization.name}</div>}
              </div>
              <div className="flex items-center py-1 h-16 col-span-2">
                <HiOutlinePhone className="text-gray-500 h-5 w-5 text-outline" />
                <div className="text-gray-500 mx-2 w-40 text-outline">Phone</div>
                {isEdit && <InputText hookForm={hookForm} placeholder="Phone number" name="phone" />}
                {!isEdit && <div className="mr-2 text-on-primary-container text-base font-semibold">{organization.phone}</div>}
              </div>
              <div className="flex items-center py-1 h-16 col-span-3">
                <HiOutlineLocationMarker className="text-gray-500 h-5 w-5 text-outline" />
                <div className="text-gray-500 mx-2 w-40 text-outline">Address</div>
                {isEdit && <InputText hookForm={hookForm} placeholder="Address" name="address" />}
                {!isEdit && <div className="mr-2 text-on-primary-container text-base font-semibold">{organization.address}</div>}
              </div>
              <div className="flex items-center py-1 h-16 col-span-2">
                <HiOutlineUser className="text-gray-500 h-5 w-5 text-outline  " />
                <div className="text-gray-500 mx-2 w-40 text-outline">
                  {organization.countUser} {t('members')}
                </div>
                <div>
                  <GroupAvatar items={organization?.users?.map((u: any) => ({ ...u, url: u.image?.url }))} />
                </div>
              </div>
            </div>
            {isEdit && (
              <div className="mt-2 flex">
                <ButtonCustom className="mr-6" color="primary" type="submit">
                  {t('saveChanges')}
                </ButtonCustom>
                <ButtonCustom color="outline" outline onClick={() => setIsEdit(false)}>
                  {t('modal.cancel')}
                </ButtonCustom>
              </div>
            )}
          </form>
        </div>
        <div className="bg-white w-full p-8 mb-8 border border-gray-200 rounded-sm relative">
          <div className="text-xl font-semibold text-gray-900">{t('organizationPage.organization')}</div>
          <div className="sm:flex">
            <div className="flex p-4 bg-hover-10 mr-8 w-72 mt-7">
              <div className="flex items-center justify-center w-15 h-15 bg-outline-variant rounded-full">
                <img alt="clinicians Icon" src={cliniciansIcon} />
              </div>
              <div className="flex justify-center flex-col ml-6">
                <div className="text-lg font-semibold uppercase text-on-primary-container">{t('clinicians')}</div>
                <div className="flex items-center">
                  <HiOutlineUser className="text-gray-500 h-4 w-4 text-outline" />
                  <div className="ml-1 text-gray-500 text-sm text-outline">
                    {organization.countClinicians} {t('members')}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex p-4 bg-hover-10 w-72 mt-7">
              <div className="flex items-center justify-center w-15 h-15 bg-outline-variant rounded-full">
                <img alt="patients Icon" src={patientsIcon} />
              </div>

              <div className="flex justify-center flex-col ml-6">
                <div className="text-lg font-semibold uppercase text-on-primary-container">{t('patients')}</div>
                <div className="flex items-center">
                  <HiOutlineUser className="text-gray-500 h-4 w-4 text-outline" />
                  <div className="ml-1 text-gray-500 text-sm text-outline">
                    {organization.countPatients} {t('members')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isWriteOrg && organization.isActive === true && (
          <div className="bg-red-50 w-full mt-8 p-8 rounded-lg flex justify-between">
            <div>
              <div className="text-xl font-semibold text-on-error-container pb-3">Deactive this organization</div>
              <div className="text-on-error-container">
                All user memberships to this organization will be active again. Once confirmed, this action can't be undone!
              </div>
            </div>
            <div className="flex items-center justify-end">
              <ButtonCustom color="error" onClick={() => updateStatus.mutate({ id: organizationId, isActive: !organization.isActive })}>
                {t('deactive')}
              </ButtonCustom>
            </div>
          </div>
        )}
        {isWriteOrg && organization.isActive === false && (
          <div className="bg-green-50 w-full mt-8 p-8 rounded-lg flex justify-between">
            <div>
              <div className="text-xl font-semibold text-green-500 pb-3">Inactive this organization</div>
              <div className="text-green-500">
                All user memberships to this organization will be active again. Once confirmed, this action can't be undone!
              </div>
            </div>
            <div className="flex items-center justify-end">
              <ButtonCustom color="green-500" onClick={() => updateStatus.mutate({ id: organizationId, isActive: !organization.isActive })}>
                {t('inactive')}
              </ButtonCustom>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default OrganizationDetail;
