import {
  HiOutlineLogout,
  HiMenuAlt2,
  HiX,
  HiUsers,
  HiOutlineOfficeBuilding,
  HiBadgeCheck,
  HiOutlineBadgeCheck,
  HiOutlineUsers,
  HiOfficeBuilding,
  HiUser,
  HiOutlineUser,
  HiChevronRight,
  HiChat,
  HiOutlineChat,
  HiChatAlt2,
  HiOutlineChatAlt2,
  HiAnnotation,
  HiOutlineAnnotation,
  HiHome,
  HiOutlineHome,
  HiChartPie,
  HiOutlineChartPie,
  HiClipboardCheck,
  HiOutlineClipboardCheck
} from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths, permissionKeys, pageType, permissionsSite, permissionsOrg, roleName } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { orgSelector, userSelector } from '../../redux/selectors';
import useUser from 'hooks/useUser';
import './styles.scss';
import { useState } from 'react';
import { checkPermission, checkRole, getNavbarUrl } from 'utils/utils';
import { userAction } from 'redux/actions';
import { find, isString } from 'lodash';
import LabelUser from 'components/labelUser';
import Svg from 'components/svg';

const logo = require('../../assets/image/svg/logo.svg').default;

const ClinicianIcon = () => <Svg name="clinician-icon" className="text-primary w-6 h-6" />;
const ClinicianOutlineIcon = () => <Svg name="clinician-icon" className="text-on-primary-container w-6 h-6" />;
const PatientsIcon = () => <Svg name="patients-icon" className="text-primary w-6 h-6" />;
const PatientsIconOutline = () => <Svg name="patients-icon" className="text-on-primary-container w-6 h-6" />;

const SidebarComponent = (props?: any) => {
  const { READ_USER, WRITE_USER, READ_ORG, WRITE_ORG, READ_PROMPT, WRITE_PROMPT } = permissionKeys;
  const { READ_ROLE, WRITE_ROLE, READ_SKILL, WRITE_SKILL } = permissionKeys;
  const { isDashboard, type } = props || {};
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  const { handleLogout } = useUser();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const url = location.pathname;
  const checkClinicianRole = checkRole(userInfo, [roleName.SLP, roleName.ORGANIZATION_ADMIN], organizationId);
  const checkPatientRole = checkRole(userInfo, [roleName.PATIENTS], organizationId);

  const checkActivePage = (page: string | string[]): any => {
    if (page === '/') {
      return url === '/';
    } else if (isString(page)) {
      return url.indexOf(page) === 0;
    } else {
      return !!find(page, (p: any) => url.startsWith(p));
    }
  };

  const toggleMenu = () => {
    setIsShowMenu(isShowMenu => !isShowMenu);
  };

  const handleOpenModalProfile = () => {
    const isOpenModal: any = true;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };

  const renderSidebarItem = (isSubSidebar: any, route: string, IconActive: any, IconInactive: any, label: any) => {
    return (
      <li className={` ${isSubSidebar ? '!m-0' : '!mb-1'} cursor-pointer ${!isSubSidebar && checkActivePage(route) && 'bg-inverse-on-surface'}`}>
        <button className="flex items-center py-2 rounded-sm w-full" onClick={() => (isSubSidebar ? nav(route) : navigate(route))}>
          {!isSubSidebar && <div className={`h-5 w-1 rounded-r-sm ${checkActivePage(route) && 'bg-primary'}`} />}
          <div className="ml-2.5 mr-3">
            {isSubSidebar && <HiChevronRight className={`w-5 h-5 ${checkActivePage(route) && 'text-primary'}`} />}
            {!isSubSidebar && checkActivePage(route) && <IconActive className="w-6 h-6 text-primary" />}
            {!isSubSidebar && !checkActivePage(route) && <IconInactive className="w-6 h-6 text-on-primary-container" />}
          </div>
          <p className={`text-sm block sm:hidden lg:block ${checkActivePage(route) ? 'text-primary' : 'text-on-primary-container'}`}>{t(label)}</p>
        </button>
      </li>
    );
  };

  const getMenuSite = (isSubSidebar = false): any => {
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>
          {checkPermission(userInfo, pageType.SITE, [READ_ORG, WRITE_ORG], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORGANIZATION_PAGE, HiOfficeBuilding, HiOutlineOfficeBuilding, 'sidebar.organizations')}
          {checkPermission(userInfo, pageType.SITE, [READ_USER, WRITE_USER], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ADMIN_USERS_PAGE, HiUsers, HiOutlineUsers, 'sidebar.userManagement')}
          {checkPermission(userInfo, pageType.SITE, [READ_ROLE, WRITE_ROLE], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ROLES_PAGE, HiBadgeCheck, HiOutlineBadgeCheck, 'sidebar.roles')}
          {checkPermission(userInfo, pageType.SITE, [READ_PROMPT, WRITE_PROMPT], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.PROMPTS_PAGE, HiChat, HiOutlineChat, 'sidebar.prompts')}
          {checkPermission(userInfo, pageType.SITE, [READ_SKILL, WRITE_SKILL], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.SKILLS_PAGE, HiChatAlt2, HiOutlineChatAlt2, 'sidebar.skills')}
        </ul>
      </div>
    );
  };

  const getMenuOrg = (isSubSidebar = false): any => {
    const checkOrg = checkPermission(userInfo, pageType.ORGANIZATION, [READ_USER, WRITE_USER], organizationId);
    if (!checkOrg) return;
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>
          {renderSidebarItem(isSubSidebar, routePaths.ORG_OVERVIEW_PAGE, HiOfficeBuilding, HiOutlineOfficeBuilding, 'sidebar.overview')}
          {renderSidebarItem(isSubSidebar, routePaths.ORG_CLINICIANS, ClinicianIcon, ClinicianOutlineIcon, 'sidebar.organizationClinicians')}
          {renderSidebarItem(isSubSidebar, routePaths.ORG_PATIENTS, PatientsIcon, PatientsIconOutline, 'sidebar.organizationPatients')}
        </ul>
      </div>
    );
  };

  const getMenuClinicians = (isSubSidebar = false): any => {
    if (!checkClinicianRole) return;
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>
          {renderSidebarItem(isSubSidebar, routePaths.DASHBOARD_PAGE, HiHome, HiOutlineHome, 'sidebar.dashboard')}
          {renderSidebarItem(isSubSidebar, routePaths.SLP_ASSIGNMENTS, HiAnnotation, HiOutlineAnnotation, 'sidebar.assignments')}
          {renderSidebarItem(isSubSidebar, routePaths.SLP_PATIENTS, PatientsIcon, PatientsIconOutline, 'sidebar.patients')}
        </ul>
      </div>
    );
  };

  const getMenuPatient = (isSubSidebar = false): any => {
    if (!checkPatientRole) return;
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>
          {renderSidebarItem(isSubSidebar, routePaths.DASHBOARD_PAGE, HiHome, HiOutlineHome, 'sidebar.dashboard')}
          {renderSidebarItem(isSubSidebar, routePaths.PATIENT_ASSIGNMENTS, HiAnnotation, HiOutlineAnnotation, 'sidebar.assignments')}
          {renderSidebarItem(isSubSidebar, routePaths.PATIENT_LEARNING_PLAN, HiClipboardCheck, HiOutlineClipboardCheck, 'sidebar.learningPlan')}
        </ul>
      </div>
    );
  };

  const nav = (path: string) => {
    toggleMenu();
    navigate(path);
  };

  return (
    <>
      {/* for desktop */}
      <div className="sidebar fixed h-screen flex flex-col justify-between bg-white overflow-y-hidden border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 text-left">
        <nav className="lg:w-64 w-full p-2.5">
          {!isDashboard && type === pageType.SITE && getMenuSite()}
          {!isDashboard && type === pageType.ORGANIZATION && getMenuOrg()}
          {(isDashboard || type === pageType.CLINICIANS) && getMenuClinicians()}
          {(isDashboard || type === pageType.PATIENTS) && getMenuPatient()}
        </nav>
      </div>

      {/*  for mobile */}
      <div className="flex sm:hidden nav-menu-mobile">
        <a aria-labelledby="flowbite-sidebar-logo-:r7:" className="flex items-center" href="/">
          <img alt="MileMarker logo" className="mr-3 h-9 sm:h-9" src={logo} />
          <span className="ml-1 text-xl text-white font-semibold dark:text-white">TRT - Hello Audrey</span>
        </a>
        <button className="inline-flex items-center p-2" onClick={toggleMenu}>
          <HiMenuAlt2 className="h-7 w-7 text-white" />
        </button>
      </div>

      {/* menu for mobile */}
      <div className={`fixed z-10 w-full h-full ${!isShowMenu && 'hidden'}`}>
        <nav className="p-2.5 bg-[#FFFBFF] h-full w-full">
          <div className="flex justify-end">
            <button className="inline-flex items-center p-3" onClick={toggleMenu}>
              <HiX className="h-8 w-8 text-gray-900" />
            </button>
          </div>
          <div className="px-5 text-on-primary-container text-sm">
            <div className="flex px-2 pb-3.5 items-center">
              <LabelUser item={userInfo} theme={{ name: 'text-gray-900', email: 'text-gray-500' }} isCoppy={false} />
            </div>
            <div className="flex sm:hidden border border-hover-10 border-t" />
            <ul>
              <li className="!m-0">
                <button className="flex items-center py-3.5 cursor-pointer" onClick={() => handleOpenModalProfile()}>
                  {checkActivePage(routePaths.PROFILE_PAGE) ? <HiUser className="w-6 h-6" /> : <HiOutlineUser className="w-6 h-6" />}
                  <p className="ml-2.5">{t('sidebar.profile')}</p>
                </button>
                <div className="flex sm:hidden border border-hover-10 border-t" />
              </li>
              {checkClinicianRole && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav('/')}>
                    <ClinicianOutlineIcon />
                    <p className="ml-2.5">{t('sidebar.clinician')}</p>
                  </button>
                  {(isDashboard || type === pageType.CLINICIANS) && <div className="mb-2">{getMenuClinicians(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
              {checkPatientRole && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav('/')}>
                    <PatientsIconOutline />
                    <p className="ml-2.5">{t('sidebar.patient')}</p>
                  </button>
                  {(isDashboard || type === pageType.PATIENTS) && <div className="mb-2">{getMenuPatient(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
              {checkPermission(userInfo, pageType.SITE, permissionsSite, organizationId) && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav(getNavbarUrl(userInfo, pageType.SITE))}>
                    {checkActivePage('/admin') ? <HiUsers className="w-6 h-6" /> : <HiOutlineUsers className="w-6 h-6" />}
                    <p className="ml-2.5">{t('sidebar.admin')}</p>
                  </button>
                  {type === pageType.SITE && <div className="mb-2">{getMenuSite(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
              {checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId) && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav(routePaths.ORG_OVERVIEW_PAGE)}>
                    {checkActivePage('/organization') ? <HiOfficeBuilding className="w-6 h-6" /> : <HiOutlineOfficeBuilding className="w-6 h-6" />}
                    <p className="ml-2.5">{t('sidebar.organizationAdmin')}</p>
                  </button>
                  {type === pageType.ORGANIZATION && <div className="mb-2">{getMenuOrg(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
            </ul>
            <button onClick={() => handleLogout()} className="flex items-center py-3.5 cursor-pointer">
              <HiOutlineLogout className="h-6 w-6 text-red-500" />
              <span className="ml-2.5 text-red-500">{t('logout')}</span>
            </button>
          </div>
        </nav>
      </div>
    </>
  );
};
export default SidebarComponent;
