import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';

export default function configureAppStore(preloadedState: any = {}) {
  const store = configureStore({
    reducer: reducers,
    preloadedState,
  });
  return store;
}
