import { useEffect, useState } from 'react';

const Progress = (props: any) => {
  const { name, value, min = 0, max = 100, hookForm, disabled } = props;
  const [state, setState] = useState(value || hookForm?.getValues(name) || 0);

  const handleOnChange = (event: any) => {
    if (!disabled) setState(parseInt(event.target.value));
  };
  useEffect(() => {
    hookForm?.setValue(name, state);
    // eslint-disable-next-line
  }, [state]);

  return (
    <div className="bg-hover-20 w-full h-1 rounded-sm relative pr-10">
      <div
        className={`absolute top-0 left-0 bg-${
          disabled ? 'hover-20' : 'inverse-primary'
        } h-full rounded-sm transition-all delay-10 ease-out relative`}
        style={{ width: `${(state / max) * 100}%` }}
      >
        <div className="absolute w-10 rounded-md bg-white right-0 overflow-hidden" style={{ top: -8, right: -40 }}>
          <div className={`w-full h-full text-center py-0.5 font-semibold bg-${disabled ? 'hover-20' : 'inverse-primary'}`}>{state}</div>
        </div>
      </div>
      <input
        onMouseDown={event => {}}
        onChange={handleOnChange}
        onMouseUp={event => {}}
        onPointerDown={event => {}}
        onPointerUp={event => {}}
        type="range"
        className="w-full absolute top-0 left-0 h-2 cursor-pointer opacity-0"
        min={min}
        max={max}
        step="any"
        value="0"
      />
    </div>
  );
};
export default Progress;
