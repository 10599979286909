import { HiOutlineDuplicate } from 'react-icons/hi';
import PaginateTable from 'components/table/paginate';
import { pageCount, pageType, permissionKeys } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import '../../styles/styles.scss';
import { checkPermission, copyToClipboard, handleHeaderSort, messageErrors } from '../../utils/utils';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import './skillManagement.scss';
import { deleteSkill, getSkills } from 'api/skillApi';
import Actions from 'components/actions';
import SkillModal from 'components/modal/skillModal';
import SpinnerComponent from 'components/spinner';
import { size } from 'lodash';
import Table from 'components/table/table';
import Sort from 'components/commonComponent/sort';

const SkillManagementPage = (props: any) => {
  const queryClient = useQueryClient();
  const { WRITE_SKILL } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const { userInfo } = useSelector(userSelector);
  const [targetSkill, setTargetSkill] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const isWrite = checkPermission(userInfo, pageType.SITE, [WRITE_SKILL], organizationId);
  const [sortByType, setSortByType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [totalEntities, setTotalEntities] = useState(0)
  const [dataSkills, setDataSkills] = useState([])

  const handleClickAddUser = () => {
    setOpenModal(true);
    setIsEdit(false);
  };

  const mutDeleteSkill = useMutation('deleteSkill', {
    mutationFn: deleteSkill,
    onSuccess: () => {
      const message: string = t('Skill.deleteSuccess');
      toast.success(message);
      queryClient.invalidateQueries('getSkills');
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const { isLoading, data } = useQuery(
    ['getSkills', currentPage, searchValue, sortBy, sortByType],
    () => getSkills({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    { staleTime: Infinity },
  );
  const getAllSkills = useQuery(['getAllSkills', searchValue], () => getSkills({ limit: 0, searchQuery: searchValue }), {
    staleTime: Infinity,
    enabled: false,
  });

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleCopyToClipboard = (e: any, item: any) => {
    copyToClipboard(item.name, t('organizationPage.copiedName'));
    e.stopPropagation();
  };

  const clickEditItem = (item: any) => {
    setTargetSkill(item);
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleOnClickExport = () => getAllSkills.refetch();

  useEffect(() => {
    if (data !== undefined) {
      setDataSkills(data?.data?.entities);
      setTotalEntities(data?.data?.totalEntities);
    }
  }, [data]);

  return (
    <>
      <div className="w-full skills-page sm:px-8 px-4">
        <div>
          <ActionTable
            placeholderSearch={t('search')}
            buttonName={t('skill.addSkill')}
            handleAddClick={isWrite && handleClickAddUser}
            handleSearch={handleSearch}
          />
        </div>
        {isLoading && <SpinnerComponent />}
        {!isLoading && totalEntities === 0 && <NoResult />}
        {totalEntities > 0 && (
          <>
            <div className="overflow-x-auto mb-2 list-table">
              <Table>
                <Table.Head>
                  <Table.HeadCell className="cursor-pointer">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleHeaderSort('name', sortByType, sortBy, setSortBy, setSortByType);
                      }}
                    >
                      <div className="font-medium items-center mr-1 ">
                        {t('skill.skill')}
                      </div>
                      <Sort check={sortBy === 'name'} sortByType={sortByType} />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile">
                    <div className="flex items-center">{t('skill.associatedPrompts')}</div>
                  </Table.HeadCell>
                  <Table.HeadCell className="w-6">{''}</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {dataSkills.map((item: any) => (
                    <Table.Row key={`row-${item.id}`} onClick={() => clickEditItem(item)}>
                      <Table.Cell className="font-medium py-2.5">
                        <div className="flex flex-row items-center cursor-pointer text-black">
                          <div>
                            <p className="bg-hover-20 py-1.5 px-3 text-on-primary-container mr-4 rounded text-xs">{item.name}</p>
                          </div>
                          <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                            <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, item)} />
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="hidden-mobile">{size(item.prompts)}</Table.Cell>
                      <Table.Cell>
                        {isWrite && (
                          <Actions>
                            <Actions.Item action={() => clickEditItem(item)} label={t('edit')} />
                            <Actions.Item action={() => mutDeleteSkill.mutate(item.id)} label={t('delete')} />
                          </Actions>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                {isLoading && <SpinnerComponent />}
              </Table>
            </div>
            <PaginateTable
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalEntities={totalEntities}
              exportHandler={handleOnClickExport}
            />
          </>
        )}
      </div>
      {openModal && (
        <SkillModal
          headerTitle={isEdit ? t('skill.addSkill') : t('skill.editSkill')}
          openModal={openModal}
          isEdit={isEdit}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          targetData={targetSkill}
        />
      )}
    </>
  );
};
export default SkillManagementPage;
