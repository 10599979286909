/* eslint-disable camelcase */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import languagePackageEng from './en.json';

const loadI18next = async () => {
  await i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
      en: {
        translation: languagePackageEng,
      },
    },
    debug: true,
    fallbackLng: 'en',
  });
};

loadI18next().catch(error => {
  console.log('initReactI18next', error);
});

export default i18next;
