import { HiArrowNarrowLeft, HiArrowNarrowRight, HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
// eslint-disable-next-line
import { paginateType } from 'utils/proptypes';
import { useEffect, useState } from 'react';
import { pageCount as pageCountDefault } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import './paginate.scss';

const PaginateTable = (props: paginateType) => {
  const { setCurrentPage, currentPage, totalEntities, isLoadingTable, pageCount = pageCountDefault } = props;
  const [t] = useTranslation();
  const totalPage = Math.ceil(totalEntities / pageCount)
  const [inputPage, setInputPage] = useState(currentPage + 1)

  const handleNextPage = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);

    }
  };
  const changePage = (event: any) => {
    setInputPage(event.target.value)
  }

  const handlecChangePage = (event: any) => {
    if (event.key === 'Enter' && event.target.value <= totalPage && event.target.value > 0) {
      setCurrentPage(event.target.value - 1)
    }
  }

  useEffect(() => {
    setInputPage(currentPage + 1)
  }, [currentPage])

  return (
    <>
      <div className='flex paginate w-full overflow-hidden bottom-5'>
        <div className='w-1/2 flex mr-2 justify-end paginate-button h-34'>
          <div className="w-auto flex-row gap-2 ml-3 bg-gray-400 rounded-sm previous-button-container">
            <button
              disabled={isLoadingTable}
              onClick={() => handlePreviousPage()}
              className="previous-button w-auto border text-primary rounded-sm border-gray-300 bg-white px-4 h-34 text-white text-sm font-normal cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10"
            >
              <HiArrowNarrowLeft className='w-5 h-5 mr-2' />
              {t('paginate.previousPage')}
            </button>
          </div>
          <div className="w-auto  flex-row gap-2 ml-3 bg-gray-400 rounded-sm">
            <button
              disabled={isLoadingTable}
              onClick={() => handleNextPage()}
              className="next-button w-auto px-4 h-34 text-white  text-sm font-normal cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10"
            >
              {t('paginate.nextPage')}
              <HiArrowNarrowRight className='w-5 h-5 ml-2' />
            </button>
          </div>
        </div>
        <div className='w-1/2 flex justify-end pr-5 second-paginate' >
          <form onSubmit={(e) => e.preventDefault()}>
            <input type="number" max={totalPage} min="1" className='h-34 border paginate-input border-gray-300 w-14 mr-2 text-primary text-center' value={inputPage} onChange={changePage} onKeyPress={handlecChangePage} />
          </form>

          <p className='flex items-center text-center text-on-primary-containerpt-1'>of {totalPage}</p>
          <div className='flex ml-2'>
            <div className='w-8 h-34 paginate-icon flex justify-center items-center cursor-pointer'
              onClick={() => handlePreviousPage()}>
              <HiOutlineChevronLeft className='w-5 h-5 text-on-primary-container' />
            </div>
            <div className='w-8 h-34 paginate-icon flex justify-center items-center cursor-pointer'
              onClick={() => handleNextPage()}>
              <HiOutlineChevronRight className='w-5 h-5 text-on-primary-container' />
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
export default PaginateTable;
