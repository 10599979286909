import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';
const { urls, methods, execute } = base();

const getAdminUsers = async (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);
  const url = `${urls.user.adminUsers}?${params.toString()}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getOrgUsers = async (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);
  const url = `${urls.user.organizationUsers}?${params.toString()}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getMyPatients = async (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);
  const url = `${urls.user.myPatients}?${params.toString()}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getMyPatientById = async (id: string) => {
  const method = methods.GET;
  const url = `${urls.user.myPatients}/${id}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getUserCurrentUser = () => {
  const method = methods.GET;
  const url = `${urls.user.getCurrentUser}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const updateUserProfile = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.userProfile}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateAdminUser = ({ id, payload }: any) => {
  const method = methods.PUT;
  const url = `${urls.user.adminUser}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateOrgUser = ({ id, payload }: any) => {
  const method = methods.PUT;
  const url = `${urls.user.organizationUser}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const createAdminUser = ({ payload }: any) => {
  const method = methods.POST;
  const url = urls.user.adminUser;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const createOrgUser = ({ payload }: any) => {
  const method = methods.POST;
  const url = urls.user.organizationUser;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const changeEmailAddress = (id: string, emailAddress: string) => {
  const method = methods.GET;
  const url = `${urls.user.setRoles}/?id=${id}&emailAddress=${emailAddress}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const updateActiveUserById = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateActiveOrganizationUserById = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PUT;
  const url = `${urls.user.organizationUser}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const setRolesForUser = (payload: { userId: string, roleIds: string[] }) => {
  const method = methods.GET;
  const url = urls.user.setRoles;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateUserImage = (payload: { id: string, file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.user.users}/${id}/uploadimage`;
  const response = execute(method, url, { authorization: true }, file);
  return response;
};

const resetPassword = async (payload: any) => {
  const method = methods.PATCH;
  const url = urls.user.resetPassword;
  const response = await execute(method, url, { authorization: true }, payload);
  return response;
};

const getUserByVerifiedCode = async (code: any) => {
  const method = methods.GET;
  const url = urls.user.getUserByVerifiedCode;
  const response = await execute(method, url + '/' + code, { authorization: false });
  return response;
};

const getSubscriptionsByUserId = async (userId: string) => {
  const method = methods.GET;
  const url = `${urls.user.users}/${userId}/subscriptions?page=0&limit=1&withDeleted=false`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getUsersByEmail = async (emailAddress: string) => {
  const method = methods.GET;
  const url = `${urls.user.userEmail}?emailAddress=${encodeURIComponent(emailAddress)}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getUserById = async (id: string) => {
  const method = methods.GET;
  const url = `${urls.user.users}/${id}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const deleteOrganizationClinician = async (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.user.organizationUser}/${id}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const deleteOrganizationPatient = async (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.user.organizationUser}/${id}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const deletePatientFromClinician = async (clinicianId: string, patientId: string) => {
  const method = methods.DELETE;
  const url = `${urls.user.organizationUser}/${clinicianId}/removePatient/${patientId}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const assignClinician = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.assignClinician}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const invitationEmail = (emailAddress: any) => {
  const method = methods.GET;
  const url = `${urls.user.invitationEmail}?emailAddress=${encodeURIComponent(emailAddress)}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

const unassignClinician = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.unassignClinician}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

export {
  createAdminUser,
  createOrgUser,
  getAdminUsers,
  getOrgUsers,
  getMyPatients,
  getMyPatientById,
  getUserCurrentUser,
  updateUserProfile,
  updateAdminUser,
  updateOrgUser,
  changeEmailAddress,
  setRolesForUser,
  updateActiveUserById,
  resetPassword,
  getUserByVerifiedCode,
  updateUserImage,
  getSubscriptionsByUserId,
  getUsersByEmail,
  getUserById,
  deleteOrganizationClinician,
  updateActiveOrganizationUserById,
  deleteOrganizationPatient,
  deletePatientFromClinician,
  assignClinician,
  invitationEmail,
  unassignClinician,
};
