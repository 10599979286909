import { useEffect, useState } from 'react';
import './selectCustom.scss';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import SkillItem from 'components/commonComponent/skillItem';
import '../../../styles/styles.scss'

const SelectCustom = (props: any) => {
    const { name, placeholder, errors, isHolderLabel, onChange, noOptionsMessage, options, value, isMulti = true } = props;
    const [focus, setFocus] = useState(!isEmpty(value));
    const formatOptionLabel = ({ label, value, skills }: any) => {
        return (
            <div className="select-label flex items-center w-full">

                <div className='mr-3 prompt-name'>
                    <p className='font-normal text-name'>
                        {label}
                    </p>
                </div>
                <div className='flex flex-wrap'>
                    {skills.map((skill: any) => (
                        <div className='py-1'><SkillItem name={skill.name} />
                        </div>))}
                </div>

            </div>
        )

    }

    useEffect(() => {
        if (value?.length !== 0) {
            setFocus(true)
        }
    }, [value])

    return (
        <div className='flex-1 select-custom relative'>
            {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
            <Select
                closeMenuOnSelect={true}
                // components={animatedComponents}
                isMulti={isMulti}
                value={value}
                options={options}
                onChange={onChange && onChange}
                className={`react-select-custom ${errors[name] ? 'error bg-red-50' : ''}`}
                classNamePrefix="react-select-custom"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(!isEmpty(value))}
                placeholder={!isHolderLabel ? placeholder : ""}
                formatOptionLabel={formatOptionLabel}

            />
            {errors[name] && <div className={`text-red-600 text-xs font-normal mt-2 veri-modal height-16`}>{errors[name]?.message}</div>}
        </div>
    );
};

export default SelectCustom;
