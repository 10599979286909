/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from '../../../utils/utils';
import useUser from 'hooks/useUser';
import { Label, Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './addAssignmentModal.scss';
import { promptTyoe } from 'utils/proptypes';
import { filter, flattenDeep } from 'lodash';
import DatePicker from 'components/datePicker';
import moment from 'moment';
import SelectCustom from './SelectCustom';
import { createAssignment, updateAssignment } from 'api/assignmentApi';
import { useParams } from 'react-router-dom';
import InputText from 'components/inputText';
import InputTextarea from 'components/textarea';

type organizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
  isEdit?: boolean,
  selectedAssignment: any,
  setIsEdit: any,
  prompts: any,
};
const AddAssignmentModal = (props: organizationModalProps) => {
  const { openModal, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage, selectedAssignment, isEdit, setIsEdit, prompts } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [dateValue, setDateValue] = useState<any>("")
  const [selectedOption, setSelectedOption] = useState([]);
  const params = useParams();

  const promptSelectOptions: any = prompts?.data?.entities?.map((p: promptTyoe) => ({ value: p.id, label: p.text, skills: p.skills }));

  const handleAddAssignment = async (values: any) => {
    if (isEdit) {
      const assignmentId = selectedAssignment?.id;
      await updateAssignment(values, assignmentId);
    } else await createAssignment(values);
  };

  const ValidateSchema = Yup.object().shape({
    date: Yup.string().required(t('userManagementPage.requiredField')),
    promptId: Yup.string().required(t('userManagementPage.requiredField')),
    specialInstructions: Yup.string().nullable().max(1000, t('userManagementPage.specialInstructionsLengthMax')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    setError,
  } = hookForm;

  const handleChange = (options: any) => {
    const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
    setSelectedOption(noDefaultOptions);
    setValue('promptId', options.value);
    if (options.value !== '') {
      clearErrors('promptId');
    } else {
      setError('promptId', {
        type: 'required',
        message: t('userManagementPage.requiredField'),
      });
    }
  };

  const handleSetSpecialInstructions = (event: any) => {
    setValue('specialInstructions', event.target.value);
  }

  const mutation = useMutation('createAssignment', {
    mutationFn: handleAddAssignment,
    onSuccess: () => {
      toggleHandler();
      if (currentPage !== 0) {
        queryClient.invalidateQueries('getPatientAssignments', { refetchActive: false }, { cancelRefetch: true });
        setCurrentPage(0);
      } else queryClient.invalidateQueries('getPatientAssignments', { refetchActive: true }, { cancelRefetch: true });
      const message: string = isEdit ? t('assignment.editAssignmentMessage') : t('assignment.addAssignmentMessage');
      toast.success(message);
      queryClient.invalidateQueries('getAssigments');
      queryClient.invalidateQueries('getPatients');
      fetchCurrentUser();
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const onSubmit = (value: any, props: any) => {
    const payload = {
      ...value,
      userId: params?.id,
    };

    mutation.mutate(payload);
  };

  const toggleHandler = () => {
    setIsEdit(false);
    setOpenModal(!openModal);
    reset();
  };

  const handleSelectDate = (date: Date) => {
    const formatDate: any = moment(date).format();
    setValue('date', formatDate);

    const tempDate: any = moment(date).format('MMMM DD, YYYY');

    setDateValue(tempDate);
    clearErrors('date');
  };

  useEffect(() => {
    if (isEdit) {
      reset({
        promptId: selectedAssignment.promptId,
        dateOfBirth: selectedAssignment?.dateOfBirth,
      });
      const prompt = promptSelectOptions?.find((item: any) => item.value === selectedAssignment.promptId);
      setSelectedOption(prompt);
      
      if (selectedAssignment.date) {
        const tempDate: any = moment(selectedAssignment.date).format('MMMM DD, YYYY');
        setValue('date', selectedAssignment.date);
        setDateValue(tempDate);
      }

      if (selectedAssignment.specialInstructions) {
        setValue('specialInstructions', selectedAssignment.specialInstructions);
      }
    } else reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssignment, prompts, isEdit]);

  return (
    <div ref={rootRef} className="relative">
      <Modal
        className="modal-add-assignment"
        show={openModal}
        size="xl"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="custom-multi-select mt-2.5 px-5">
              <div>
                <SelectCustom
                  options={promptSelectOptions}
                  onChange={(option: any) => handleChange(option)}
                  placeholder={`${t('assignment.prompt')} *`}
                  isHolderLabel={true}
                  value={selectedOption}
                  errors={errors}
                  name="promptId"
                  isMulti={false}
                />
              </div>
            </div>
            <div className="pt-4 px-5">
              <DatePicker
                hookForm={hookForm}
                placeholder="Due Date *"
                name="date"
                dateValue={dateValue}
                handleSelectDate={handleSelectDate}
                isHolderLabel={true}
              />
            </div>
            <div className="pt-4 px-5">
              <Label htmlFor="specialInstructions" value={`${t('assignment.specialInstructions')}`} className="text-sm text-gray-600" />
              <InputTextarea
                id="specialInstructions"
                hookForm={hookForm}
                placeholder={`${t('assignment.specialInstructions')}`}
                name="specialInstructions"
                onChange={handleSetSpecialInstructions}
              />
              {/* <textarea
                className="w-full h-24 border border-gray-300 rounded-md p-3"
                placeholder={t('assignment.specialInstructions')}
                name="specialInstructions"
                onChange={handleSetSpecialInstructions}
              /> */}
            </div>
            <GroupButton
              className="items-center justify-center pt-5 pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutation.isLoading,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AddAssignmentModal;
