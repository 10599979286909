/* eslint-disable react/jsx-props-no-spreading */
import CustomModalHeader from '../customModalHeader';
import * as Yup from 'yup';
import GroupButton from 'components/button/groupButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { createRole, updateRole } from 'api/rolesApi';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { pageType, permissionKeys, roleTypeOptions } from 'utils/constants';
import './roleModal.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Tooltip, Modal, Checkbox } from 'flowbite-react';
import '../../form/styles.scss';
import { cloneDeep, concat, difference, filter, find, map } from 'lodash';
import InputText from 'components/inputText';
import { themCheckbox, themModal } from 'utils/theme';
import Select from 'components/select';
import InputTextarea from 'components/textarea';

const RoleModal = (props: any) => {
  const queryClient = useQueryClient();
  const { PLATFORM_ADMIN } = permissionKeys;
  const { openModal, targetData, headerTitle, isEdit, handleCloseModal, setCurrentPage, currentPage, permissions } = props;
  const greatestPermission = find(permissions, (perm: any) => perm === PLATFORM_ADMIN);
  const normalPermissions = filter(permissions, (perm: any) => perm !== PLATFORM_ADMIN);
  const rootRef = useRef(null);
  const [t] = useTranslation();
  const [permissioSelects, setPermissioSelects] = useState(targetData.permissions);

  const ValidateSchema = Yup.object().shape({
    displayName: Yup.string().required(t('requiredField')),
    type: Yup.string().required(t('requiredField')),
  });

  const hookForm = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchema) });
  const { register, handleSubmit, setValue, reset, getValues } = hookForm;
  const cancelHandler = () => {
    handleCloseModal(!openModal);
  };

  const handleAddAndUpdateRole = async (userPayload: any) => {
    if (isEdit) {
      await updateRole(targetData.id, userPayload);
    } else {
      await createRole(userPayload);
    }
  };

  const mutation = useMutation('create-update-role', { mutationFn: handleAddAndUpdateRole });

  const onSubmit = (value: any) => {
    mutation.mutate(value, {
      onSuccess: () => {
        cancelHandler();
        const message: string = isEdit
          ? t('roleManagementPage.RoleModal.editSuccessMessage')
          : t('roleManagementPage.RoleModal.createSuccessMessage');
        toast.success(message);
        queryClient.invalidateQueries('getRoles');
        if (currentPage !== 0 && !isEdit) setCurrentPage(0);
      },
      onError: async (error: any) => {
        const message: string = `${error.response.data.errors?.[0].detail}`;
        toast.error(message);
      },
    });
  };

  const renderPermission = (item: any) => {
    let type: any = getValues('type');
    const checkDisabled =
      (find(permissioSelects, perm => perm === PLATFORM_ADMIN) && item !== PLATFORM_ADMIN) ||
      (item === PLATFORM_ADMIN && type === pageType.ORGANIZATION);
    return (
      <div className="flex items-center gap-1" key={item}>
        <Checkbox
          theme={themCheckbox}
          {...register('permissions')}
          defaultChecked={targetData.permissions.includes(item)}
          value={item}
          disabled={checkDisabled}
          onChange={({ target: { value, checked } }) => {
            let dataPermissions: any = getValues('permissions');
            dataPermissions = checked ? concat(cloneDeep(dataPermissions), value) : difference(cloneDeep(dataPermissions), [value]);
            setPermissioSelects(dataPermissions);
            setValue('permissions', dataPermissions);
          }}
        />
        <div className="ml-0 post-fix-width">
          <Tooltip content={item} placement="top">
            <Label className="block truncate text-on-primary-container" value={item} htmlFor={item} />
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleOnChange = (option: any) => {
    let dataPermissions: any = getValues('permissions');
    if (option.value === pageType.ORGANIZATION) dataPermissions = difference(dataPermissions, [PLATFORM_ADMIN]);
    setPermissioSelects(dataPermissions);
    setValue('permissions', dataPermissions);
  };

  useEffect(() => {
    if (isEdit) {
      setPermissioSelects(targetData.permissions);
      const selectedType: any = roleTypeOptions.find(role => role.value === targetData.type);
      selectedType.type = selectedType?.value;
      reset(targetData);
    } else {
      setPermissioSelects([]);
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <div ref={rootRef} className="role-modal-container">
      <Modal
        show={openModal}
        size="4xl"
        root={rootRef.current ?? undefined}
        popup={true}
        className="role-modal"
        onClose={cancelHandler}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={cancelHandler} />
        <Modal.Body theme={{ base: 'flex-1 px-12 !py-8 overflow-auto' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="relative">
              <InputText hookForm={hookForm} placeholder="Name *" name="displayName" isHolderLabel={true} />
            </div>
            <div className="mt-4 relative">
              <Select
                onChange={handleOnChange}
                options={roleTypeOptions}
                hookForm={hookForm}
                name="type"
                closeMenuOnSelect
                isHolderLabel={true}
                placeholder="Type *"
              />
            </div>
            <div className="mt-4 relative">
              <InputTextarea hookForm={hookForm} placeholder="Description" name="description" isHolderLabel={true} />
            </div>
            <div className="mt-4 grid grid-cols-4 gap-4">{greatestPermission && renderPermission(greatestPermission)}</div>
            <div className="mt-4 grid grid-cols-custom gap-custom">{map(normalPermissions, (perm: any) => renderPermission(perm))}</div>
            <GroupButton
              className="items-center justify-center pt-3 pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => cancelHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: false,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoleModal;
