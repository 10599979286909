/* eslint-disable react/jsx-props-no-spreading */
import { HiOutlineDuplicate, HiOutlineMail } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { pageType, permissionKeys } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkPermission, messageErrors, copyToClipboard, invalidateQueriesUser } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import './organizationPatientDetail.scss';
import useUser from 'hooks/useUser';
import { getUserById, updateUserImage } from 'api/userApi';
import { useParams } from 'react-router-dom';
import { Tabs } from 'flowbite-react';
import moment from 'moment';
import { find, flattenDeep, map, uniq } from 'lodash';
import ButtonBack from 'components/button/buttonBack';
import '../../../styles/styles.scss';
import AddClinicianToPatient from 'components/modal/addClinicianToPatient';
import Banner from 'components/banner/banner';
import DetailTab from 'components/userInfo';
import Avatar from 'components/GroupAvatar/Avatar';
import CliniciansEmpty from 'components/empty/cliniciansEmpty';

const OrganizationPatientDetail = (props: any) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { WRITE_USER, PLATFORM_ADMIN } = permissionKeys;
  const { organizationId } = useSelector(orgSelector);
  const [patient, setPatient]: any = useState();
  const { userInfo } = useSelector(userSelector);
  const [isEdit, setIsEdit] = useState(false);
  const { fetchCurrentUser } = useUser();
  const params: any = useParams();
  const [clinician, setClinician]: any = useState();
  const [openModal, setOpenModal] = useState(false);

  const { data } = useQuery(['getOrganizationPatientDetail', params?.id], () => getUserById(params?.id), {
    retry: false,
    staleTime: Infinity,
    onError: error => toast.error(messageErrors(error, t)),
  });

  const platformAdmin = find(uniq(flattenDeep(map(data?.data?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
  const isWrite = checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin);
  const mutaOrganizationImage = useMutation('patientImage', {
    mutationFn: updateUserImage,
    onSuccess: () => {
      const message: string = t('organizationPatients.editSuccessMessage');
      toast.success(message);
      invalidateQueriesUser(queryClient);
      setIsEdit(false);
      if (params?.id === userInfo.id) fetchCurrentUser();
    },
  });

  const handleUploadImage = async (formData: any) => {
    mutaOrganizationImage.mutate({ id: params.id, file: formData });
  };

  useEffect(() => {
    if (data?.data) {
      setPatient(data?.data);
      const tempClinician = data?.data.organizations?.find((org: any) => org.id === organizationId)?.slpUser;
      setClinician(tempClinician);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleCopyToClipboard = (e: any, item: any, message: string) => {
    copyToClipboard(item, message);
    e.stopPropagation();
  };

  const handleAddClinician = () => {
    setOpenModal(true);
  };

  return (
    <div className="flex flex-col org-patient-detail w-full">
      {patient && (
        <>
          <div className="flex items-center p-4 bg-gray-50">
            <ButtonBack buttonName={t('sidebar.organizationPatients')} />
          </div>
          <Banner targetData={patient} isWrite={isWrite} handleUpdate={handleUploadImage} labelId="Patient ID" />
          <div className="patient-infor">
            {/* eslint-disable-next-line  */}
            <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-button">
              <Tabs.Item active title="DETAILS">
                <DetailTab type={pageType.ORGANIZATION} targetData={patient} isWrite={isWrite} />
              </Tabs.Item>
              <Tabs.Item title="CLINICIAN">
                {!clinician && (
                  <CliniciansEmpty
                    title={t('organizationPatients.noClinician')}
                    note={t('organizationPatients.addClinicianNote')}
                    handleAddClick={() => handleAddClinician()}
                    buttonName={t('organizationPatients.assignClinician')}
                    className="md:mx-8 mx-4"
                  />
                )}
                {clinician && (
                  <div className="py-4 px-8 patient-clinician-infor">
                    <div className="clinician bg-white w-full p-8 mb-8 border border-gray-200 rounded-sm relative flex items-center justify-between">
                      <div className="flex items-center">
                        <Avatar item={clinician} className="h-[100px] w-[100px] text-3xl" />
                        <div className="ml-3">
                          <p className="text-lg text-on-primary-container uppercase font-semibold	">{`${clinician?.firstName} ${clinician?.lastName}`}</p>
                          <div className="flex items-center">
                            <HiOutlineMail className="text-outline h-4 w-4 text-outline" />
                            <div className="ml-1 text-sm patient-value mr-2 text-outline text-base break-all">{clinician?.emailAddress}</div>
                          </div>
                        </div>
                      </div>
                      <div className="clinician-id flex text-on-primary-container items-center">
                        <div className="mr-2">Clinician ID:</div>
                        <div className="flex items-center">
                          <p className="px-2 mr-2 rounded text-on-primary-container bg-hover-10 truncate">{clinician?.id}</p>
                          <div className="copy-clinician-id">
                            <HiOutlineDuplicate
                              className="cursor-pointer"
                              size={20}
                              onClick={e => handleCopyToClipboard(e, clinician?.id, t('organizationPatients.copiedClinicianId'))}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Tabs.Item>
            </Tabs.Group>
          </div>
        </>
      )}

      <AddClinicianToPatient
        headerTitle={t('organizationPatients.assignClinician')}
        openModal={openModal}
        isEdit={isEdit}
        setOpenModal={setOpenModal}
        queryClient={queryClient}
        patientId={params?.id}
      />
    </div>
  );
};

export default OrganizationPatientDetail;
