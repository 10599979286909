import { Tabs } from "flowbite-react"
import { useState } from "react";
import './assignmentsPage.scss'
import { useLocation } from "react-router-dom";
import CurrentAssignmentList from "./currentAssignmentList";
import PastAssignmentList from "./pastAssignmentList";

const Assignments = () => {
    const location = useLocation();
    const [tab, setTab] = useState(location?.state?.tab || 'current');

    return (
        <div className='patient-assignments h-full flex'>
            {/* eslint-disable-next-line  */}
            <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-button w-full">
                <Tabs.Item active={tab === 'current'} title="CURRENT">
                    <CurrentAssignmentList />
                </Tabs.Item>
                <Tabs.Item active={tab === 'past'} title="PAST">
                    <PastAssignmentList />
                </Tabs.Item>
            </Tabs.Group>
        </div >
    )
}

export default Assignments