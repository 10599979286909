import { useNavigate } from 'react-router-dom';
import { HiOutlineClock, HiStar, HiOutlineEye, HiDotsHorizontal, HiChevronRight } from 'react-icons/hi';
import { pageCount, routePaths } from 'utils/constants';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import PlayButton from 'components/button/playButton';
import Avatar from 'components/GroupAvatar/Avatar';
import { useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import { getMyPatients } from 'api/userApi';
import { concat, isEmpty, orderBy, size } from 'lodash';
import moment from 'moment';
import { getAssigments } from 'api/assignmentApi';
import ReviewsOfAssignmentModal from 'components/modal/reviewsOfAssignmentModal';
import ReviewModal from 'components/modal/reviewModal';
import './clinicianDashboard.scss';
import Scrollbar from 'components/scrollbar/scrollbar';
import { Spinner } from 'flowbite-react';
import PatientsEmpty from 'components/empty/patientsEmpty';
import { useTranslation } from 'react-i18next';
import RecordingsEmpty from 'components/empty/recordingsEmpty';
import Svg from 'components/svg';

const ClinicianDashboard = (props: any) => {
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { organizationId } = useSelector(orgSelector);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openListReviewModal, setOpenListReviewModal] = useState(false);
  const [page, setPage] = useState(0);
  const [targetAssignment, setTargetAssignment] = useState();
  const [userReviewId, setUserReviewId] = useState();
  const [recordingsToReview, setRecordingsToReview] = useState([]);  

  const { data: dataPatients } = useQuery(['getPatients', organizationId], () => getMyPatients({}), { staleTime: Infinity });
  const { data: dataRecordingsToReview, isLoading } = useQuery(
    ['getRecordingsToReview', page],
    () => getAssigments({ page, limit: pageCount, isRecording: true, isNotMyReview: true }),
    {
      staleTime: Infinity,
      onSuccess: data => {
        const newRecordingsToReview: any = concat(page ? recordingsToReview : [], data?.data?.entities);
        setRecordingsToReview(newRecordingsToReview);
      },
    },
  );
  useQuery(['resetGetRecordingsToReview', organizationId], () => resetGetRecordingsToReview());
  const resetGetRecordingsToReview = () => {
    queryClient.invalidateQueries('getRecordingsToReview', { refetchActive: !page }, { cancelRefetch: true });
    setPage(0);
  };

  const getDataPatients: any = dataPatients?.data?.entities || [];
  const totalRecordingsToReview: any = dataRecordingsToReview?.data?.totalEntities || 0;

  const getFullName = (item: any) => (item.firstName ? `${item.firstName} ${item.lastName}` : <HiDotsHorizontal className="w-8 mt-2" />);

  const clickOpenReview = (item: any, userReviewId: any) => {
    setUserReviewId(userReviewId);
    setTargetAssignment(item);
    setOpenReviewModal(true);
  };

  const clickOpenReviewListModal = (item: any) => {
    setTargetAssignment(item);
    setOpenListReviewModal(true);
  };

  return (
    <>
      <div className="p-8 lg:flex justify-between clinician-dashboard h-full">
        <div className="lg:w-1/2 w-full">
          <div className="text-xl	font-semibold text-on-primary-container pb-1">Recordings To Review</div>
          <Scrollbar className="recordings" endScroll={() => !isLoading && totalRecordingsToReview > (page + 1) * pageCount && setPage(page + 1)}>
            {isEmpty(recordingsToReview) && <RecordingsEmpty className="mt-4" />}
            {!isEmpty(recordingsToReview) && recordingsToReview.map((item: any) => {
              const slpUserId = item?.user?.organizations?.[0]?.slpUserId;
              const duration = moment().startOf('dates').add(item?.recording?.file?.duration, 'seconds').format('HH:mm:ss') || '';
              return (
                <div key={`rc-${item.id}`} className="mt-4 flex items-center w-full">
                  <p
                    className={`w-1.5 ${userInfo.id === slpUserId ? 'bg-inverse-primary' : 'border border-hoder-20 border-r-0 rounded-l-sm'} h-24`}
                  />
                  <div className="border border-hoder-20 border-l-0 rounded-r-sm p-4 pl-2.5 flex items-center justify-between w-full h-24">
                    <PlayButton action={() => clickOpenReview(item, userInfo.id)} className="cursor-pointer p-3.5 mr-3.5" />
                    <div className="w-1 flex-1">
                      <div className="text-base font-semibold text-on-primary-container pl-0.5">
                        <p className="truncate">{item.prompt.text}</p>
                      </div>
                      <div className="text-xs text-outline flex items-center mt-1 pl-0.5">
                        <HiOutlineClock className="mr-1 w-3.5 h-3.5" />
                        <span>{item.date ? moment(item.date).format('MMM DD, YYYY') : ''}</span>
                      </div>
                      {!isEmpty(item.reviewers) && (
                        <div className="flex items-center mt-1">
                          <HiStar className="text-yellow-300 mr-1 w-4 h-4" />
                          <span className="text-xs text-outline">Rate “Skill”</span>
                          <p className="text-[10px] px-1.5 py-0.5 bg-secondary-container rounded-sm ml-1.5 text-on-primary-container">
                            {item.rating}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="ml-4 text-on-primary-container">
                      <div className="text-right text-sm text-outline">{duration}</div>
                      <div className="flex items-center mt-1.5">
                        <button onClick={() => clickOpenReviewListModal(item)} className="mr-1">
                          <HiOutlineEye className="w-5 h-5" />
                        </button>
                        <p className="mr-2">{size(item?.reviewers)}</p>
                        <Avatar item={item.user} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {isLoading && (
              <div className="text-center my-4">
                <Spinner className="w-12 h-12 fill-primary" />
              </div>
            )}
          </Scrollbar>
        </div>
        <div className="p-4">{''}</div>
        <div className="lg:w-1/2 w-full bg-[#BCE9FF] bg-opacity-20 p-8">
          <div className="flex items-center justify-between pb-6">
            <p className="text-xl font-semibold text-on-primary-container ">Patient List</p>
            <button className="text-sm text-tertiary flex items-center cursor-pointer" onClick={() => navigate(routePaths.SLP_PATIENTS)}>
              <p>See all</p> <HiChevronRight className="w-4 h-4" />
            </button>
          </div>
          <Scrollbar className="patients">
            {isEmpty(getDataPatients) && <PatientsEmpty note={t('noteContactAdmin')} title={t('noPatientsAssigned')} className="!border-0"/>}
            {!isEmpty(getDataPatients) && (
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                {getDataPatients.map((item: any) => {
                  const [_current, next] = orderBy(
                    item?.assignments?.filter((ass: any) => moment(ass.date) > moment()),
                    (ass: any) => new Date(ass.date),
                  );
                  return (
                    <div key={`patient-${item.id}`} className="bg-white rounded-sm w-full flex flex-col items-center p-4">
                      <div className="relative mb-2">
                        {!item.authId && <Svg name="sent-invite-icon" className="absolute bottom-0 right-0" />}
                        <Avatar className="w-16 h-16" item={item} />
                      </div>
                      <div className="overflow-hidden w-full flex justify-center">
                        <p className="text-base text-on-primary-container font-semibold truncate">{getFullName(item)}</p>
                      </div>
                      <p className="text-[10px] text-outline mt-1">
                        Next Due Date <b>{next ? moment(next?.date).format('MMM DD, YYYY') : ''}</b>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </Scrollbar>
        </div>
      </div>
      <ReviewsOfAssignmentModal openModal={openListReviewModal} setOpenModal={setOpenListReviewModal} targetData={targetAssignment} />
      <ReviewModal userReviewId={userReviewId} openModal={openReviewModal} setOpenModal={setOpenReviewModal} targetData={targetAssignment} />
    </>
  );
};

export default ClinicianDashboard;
