import './banner.scss';
import { HiOutlinePencil } from 'react-icons/hi';
import { IMAGE_TYPE_REGEX, acceptImageTypeFile } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/GroupAvatar/Avatar';
import { useEffect, useState } from 'react';
import { toLower } from 'lodash';
const intro = require('../../assets/image/svg/bg-intro-org.svg').default;

const Banner = (props: any) => {
  const {  avatarDefault, targetData, labelId, isWrite, handleUpdate } = props;
  const [file, setFile] = useState<any>();
  const [t] = useTranslation();
  const name = targetData?.name || `${targetData?.firstName} ${targetData?.lastName}`;

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          return;
        }
        if (currentFile.size > 8000000) {
          return;
        }
      }
      setFile(currentFile);
      currentFile.isUploaded = true;
    }
  };
  

  useEffect(() => {
    if (file?.name) {
      const currentFileName = toLower(file.name);
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      handleUpdate && handleUpdate(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);
  
  return (
    <div className="banner w-full flex items-center relative top-0 left-0 px-8 overflow-hidden">
      <img alt="MileMarker logo" className="absolute left-0 top-0 max-w-none min-w-full min-h-full" src={intro} />
      <div className="avatar border border-gray-200 rounded-full h-32 w-32 flex items-center justify-center bg-white relative overflow-hidden">
        <Avatar item={targetData} className="avatar h-32 w-32 text-5xl" avatarDefault={avatarDefault} />
        {isWrite && (
          <label
            className="hover:opacity-1 opacity-0 bg-opacity-80 bg-gray-900 absolute h-full w-full top-0 right-0 left-0 flex items-center justify-center"
            htmlFor="dropzone-file"
          >
            <HiOutlinePencil className="text-white w-8 h-8" />
            <input id="dropzone-file" type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
          </label>
        )}
      </div>
      <div className="pl-8 flex-1 banner-context overflow-hidden">
        <div className="flex items-center context mb-3.5">
          <div>
            <span className="text-3xl font-semibold text-on-primary-container pb-1 title mr-4 break-word">{name} </span>
          </div>
          <div className='text-xs font-normal uppercase text-white'>
            {targetData?.isActive && <span className="bg-tertiary px-2.5 py-1.5 rounded">{t('active')}</span>}
            {!targetData?.isActive && <span className="bg-error px-2.5 py-1.5 rounded">{t('inactive')}</span>}
          </div>
        </div>
        <div className="text-gray-800 flex items-center">
          <div className="mr-4 py-1">{`${labelId}`}</div>
          <div className="bg-hover-20 px-2 py-1 py-0.5 rounded relative">{targetData?.id}</div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
