import './style.scss';
import {
  HiOutlineClipboard,
  HiOutlineMail,
  HiOutlineCalendar,
  HiOutlinePencil,
  HiOutlineX,
  HiPaperAirplane,
  HiOutlineBadgeCheck,
  HiOutlineCake,
  HiOutlineUserCircle,
} from 'react-icons/hi';
import { pageType } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import moment from 'moment';
import { isArray, map, pick } from 'lodash';
import { updateAdminUser, updateOrgUser } from 'api/userApi';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import InputText from 'components/inputText';
import { Badge } from 'flowbite-react';
import Select from 'components/select';
import { handleTrimString, invalidateQueriesUser } from 'utils/utils';
import DatePicker from 'components/datePicker';

const DetailTab = (props: any) => {
  const [enableEditFullName, setEnableEditFullName] = useState(false);
  const [enableEditRole, setEnableEditRole] = useState(false);
  const [enableEditDob, setEnableEditDob] = useState(false);
  const [dateValue, setDateValue] = useState('');
  const queryClient = useQueryClient();
  const params: any = useParams();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const { fetchCurrentUser } = useUser();
  const { type, targetData, isWrite, roleData } = props;
  const [roles, setRoles] = useState([]);
  const [t] = useTranslation();

  const getRoleOption = (role: any): any => ({ value: role?.id, label: role?.displayName });

  const getRolesInData = (user: any) => {
    const siteRoles = user?.roles;
    const orgRoles = map(
      user?.organizations?.filter((o: any) => o.id === organizationId && !!o.role),
      'role',
    );
    return type === pageType.SITE ? siteRoles : orgRoles;
  };

  const ValidateSchemaFullName = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
  });
  const ValidateSchemaRoles = Yup.object().shape({
    roleIds:
      type === pageType.SITE
        ? Yup.array().min(1, t('userManagementPage.roleMin')).required(t('userManagementPage.requiredField'))
        : Yup.string().required(t('userManagementPage.requiredField')),
  });
  const hookFormFullName: any = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaFullName) });
  const hookFormRoles: any = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaRoles) });
  const hookFormDob: any = useForm({ mode: 'onChange' });

  const handleSuccess = (message: string) => {
    toast.success(message);
    invalidateQueriesUser(queryClient);
    if (params?.id === userInfo.id) fetchCurrentUser();
  };

  const handleSubmitUser = (data: any) => {
    return type === pageType.SITE ? updateAdminUser(data) : updateOrgUser(data);
  };

  const muUpdateUser = useMutation('update-usesOrg', {
    mutationFn: handleSubmitUser,
    onSuccess: () => {
      handleSuccess(t('userManagementPage.editSuccessMessage'));
      setEnableEditFullName(false);
      setEnableEditRole(false);
      setEnableEditDob(false);
    },
    onError: async (error: any) => {
      const message: string = `${error.response.data.errors?.[0].detail}`;
      toast.error(message);
    },
  });
  const onSubmit = (payload: any) => {
    if (payload.roleIds && !isArray(payload.roleIds)) payload.roleIds = [payload.roleIds];
    handleTrimString(payload)
    muUpdateUser.mutate({ payload, id: params?.id });
  };

  useEffect(() => {
    if (targetData) {
      const roles = getRolesInData(targetData);
      setRoles(roles);
      if (targetData.dateOfBirth) setDateValue(moment(targetData.dateOfBirth).format('MMMM DD, YYYY'));
      hookFormFullName.reset(pick(targetData, ['firstName', 'lastName']));
      hookFormRoles.reset({ roleIds: type === pageType.SITE ? map(roles, 'id') : roles?.[0]?.id });
      hookFormDob.reset({ dateOfBirth: targetData.dateOfBirth });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData, enableEditRole, enableEditFullName, enableEditDob]);

  const handleSelectDate = (date: Date) => {
    const formatDate: any = moment(date).format('YYYY-MM-DD');
    hookFormDob.setValue('dateOfBirth', formatDate);
    const tempDate: any = moment(date).format('MMMM DD, YYYY');
    setDateValue(tempDate);
  };

  const ActionEdit: any = ({ change, className = '' }: any) => (
    <Button onClick={change} className={`h-8 w-8 ${className}`} color="gray-100">
      <HiOutlinePencil className="w-4 h-4 text-on-primary-container" />
    </Button>
  );

  const ActionSave: any = ({ change, className = '' }: any) => (
    <div className="flex">
      <Button type="submit" className={`mr-2 h-8 w-8 ${className}`} color="primary">
        <HiPaperAirplane className="w-5 h-5 rotate-90" />
      </Button>
      <Button onClick={change} className={`h-8 w-8 ${className}`} color="outline" outline>
        <HiOutlineX className="w-5 h-5 text-outline" />
      </Button>
    </div>
  );

  const Card: any = ({ children, className = '' }: any) => <div className={`min-h-[48px] pb-4 md:flex ${className}`}>{children}</div>;
  const CardTitle: any = ({ children }: any) => <div className="w-40 h-5 text-outline flex items-center mt-3.5">{children}</div>;
  const CardContent: any = ({ children }: any) => (
    <div className="text-on-primary-container w-full flex flex-1 max-w-[500px] ml-7 md:ml-0">{children}</div>
  );

  return (
    <div className="detail-tab md:p-8 p-4 border border-gray-200 rounded md:mx-8 mx-4 xl:flex justify-between">
      <div className="w-full max-w-2xl lg:mr-8">
        <Card>
          <CardTitle>
            <HiOutlineClipboard className="w-5 h-5 mr-2" />
            {t('userManagementPage.fullName')}
          </CardTitle>
          <CardContent>
            {!enableEditFullName && <p className="mt-3 mr-2 font-semibold">{`${targetData?.firstName} ${targetData?.lastName}`}</p>}
            {!enableEditFullName && isWrite && <ActionEdit change={() => setEnableEditFullName(true)} className="mt-2" />}
            {enableEditFullName && (
              <form onSubmit={hookFormFullName.handleSubmit(onSubmit)} className="md:flex flex-1 mt-1 md:mt-0">
                <InputText name="firstName" className="mr-2" hookForm={hookFormFullName} />
                <InputText name="lastName" className="mr-2" hookForm={hookFormFullName} />
                <ActionSave change={() => setEnableEditFullName(false)} className="md:mt-2" />
              </form>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardTitle>
            <HiOutlineMail className="w-5 h-5 mr-2" />
            {t('userManagementPage.email')}
          </CardTitle>
          <CardContent>
            <p className="mt-3 mr-2">{`${targetData?.emailAddress}`}</p>
          </CardContent>
        </Card>
        <Card className="last:p-0">
          <CardTitle>
            <HiOutlineCake className="w-5 h-5 mr-2" />
            {t('userManagementPage.dateOfBirth')}
          </CardTitle>
          <CardContent>
            {!enableEditDob && <p className="mt-3 mr-2">{targetData?.dateOfBirth ? moment(targetData?.dateOfBirth).format('MMMM DD, YYYY') : ''}</p>}
            {!enableEditDob && isWrite && <ActionEdit change={() => setEnableEditDob(true)} className="mt-2" />}
            {enableEditDob && (
              <form onSubmit={hookFormDob.handleSubmit(onSubmit)} className="md:flex flex-1">
                <DatePicker
                  className="mr-2 mt-1 md:mt-0"
                  hookForm={hookFormDob}
                  name="dateOfBirth"
                  dateValue={dateValue}
                  handleSelectDate={handleSelectDate}
                  isHolderLabel={true}
                />
                <ActionSave change={() => setEnableEditDob(false)} className="mt-2" />
              </form>
            )}
          </CardContent>
        </Card>
        {roleData && (
          <Card className="last:p-0">
            <CardTitle>
              <HiOutlineBadgeCheck className="w-5 h-5 mr-2" />
              {t('userManagementPage.roles')}
            </CardTitle>
            <CardContent>
              {!enableEditRole && (
                <div className="flex flex-wrap mt-2">
                  {roles.map((role: any) => (
                    <Badge size="sm" color="gray" className="font-normal py-1 mr-2.5 my-1 text-on-primary-container bg-hover-20" key={role.id}>
                      {role?.displayName}
                    </Badge>
                  ))}
                  {isWrite && <ActionEdit change={() => setEnableEditRole(true)} className="mt-0.5" />}
                </div>
              )}
              {enableEditRole && (
                <form onSubmit={hookFormRoles.handleSubmit(onSubmit)} className="md:flex flex-1">
                  <Select
                    options={map(roleData, role => getRoleOption(role))}
                    hookForm={hookFormRoles}
                    name="roleIds"
                    isMulti={type === pageType.SITE}
                    className="mr-2 mt-1 md:mt-0"
                  />
                  <ActionSave change={() => setEnableEditRole(false)} className="mt-2" />
                </form>
              )}
            </CardContent>
          </Card>
        )}
      </div>
      <div>
        <Card>
          <CardTitle>
            <HiOutlineCalendar className="w-5 h-5 mr-2" />
            {t('userManagementPage.createdAt')}
          </CardTitle>
          <CardContent>
            <p className="mt-3 mr-2 min-w-[250px]">{targetData.created ? moment(targetData.created).format('MMMM Do YYYY, h:mm:ss A') : ''}</p>
          </CardContent>
        </Card>
        <Card>
          <CardTitle>
            <HiOutlineCalendar className="w-5 h-5 mr-2" />
            {t('userManagementPage.lastestLogin')}
          </CardTitle>
          <CardContent>
            <p className="mt-3 mr-2 min-w-[250px]">{targetData.lastestLogin ? moment(targetData?.lastestLogin).format('MMMM Do YYYY, h:mm:ss A') : ''}</p>
          </CardContent>
        </Card>
        <Card>
          <CardTitle>
            <HiOutlineUserCircle className="w-5 h-5 mr-2" />
            {t('userManagementPage.externalId')}
          </CardTitle>
          <CardContent>
          <p className="mt-3 mr-2">{`${targetData?.externalId}`}</p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default DetailTab;
