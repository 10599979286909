import React, { createContext, useContext, useMemo } from "react";
import type { CSSProperties, PropsWithChildren } from "react";
import type {
    DraggableSyntheticListeners,
    UniqueIdentifier
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./SortableItem.scss";
import SkillItem from "components/commonComponent/skillItem";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
    id: UniqueIdentifier;
}

interface Context {
    attributes: Record<string, any>;
    listeners: DraggableSyntheticListeners;
    ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
    attributes: {},
    listeners: undefined,
    ref() { }
});

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition
    } = useSortable({ id });
    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef
        }),
        [attributes, listeners, setActivatorNodeRef]
    );
    const style: CSSProperties = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition
    };

    return (
        <SortableItemContext.Provider value={context}>
            <tbody className="SortableItem" ref={setNodeRef} style={style}>
                {children}
            </tbody>
        </SortableItemContext.Provider>
    );
}

export function DragHandle({ children }: any) {
    const { attributes, listeners, ref } = useContext(SortableItemContext);
    const [t] = useTranslation()
    return (
        // eslint-disable-next-line
        <tr className="DragHandle hover:bg-hover-5 border w-full"  {...attributes} {...listeners} ref={ref} >
            {children}
        </tr>
    );
}
